import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Fade, Toolbar, Typography, AppBar, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import withApiCaller from 'src/components/common/hocs/withApiCaller';
import withAlertAndLoader from 'src/components/common/hocs/withAlertAndLoader';

import utils from 'src/utils/utils';
import SmartjourneyItemList from './SmartjourneyItemList';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import smjSelectors from 'src/selectors/smartjourney';
import { useLocation } from 'wouter';
import { useAlert } from 'src/hooks';

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  uppercase: { textTransform: 'uppercase' },
}));

const SMJList = ({ campaignGroups, smjModelById, deleteSmj }) => {
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography color="inherit" className={classes.uppercase}>
            {utils.getLang(`smartmessaging.campaigns.grouped.list.title`)}
          </Typography>
          <div
            style={{
              flex: 1,
              marginLeft: '16px',
            }}
          />
          <Tooltip title={utils.getLang('smartmessaging.tooltip.campaigns.grouped.newGroup')}>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                navigate(`/smartjourney/create`);
              }}
              style={{ color: 'inherit' }}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Fade in timeout={500}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            overflow: 'hidden',
            textAlign: 'left',
            margin: '8px',
          }}
        >
          <SmartjourneyItemList
            editSmj={smj => {
              navigate(`/smartjourney/${smj.id}/campaigns/list`);
            }}
            deleteSmj={pSmj => {
              deleteSmj(pSmj);
            }}
            smjList={campaignGroups}
            smjModelById={smjModelById}
          />
        </div>
      </Fade>
    </div>
  );
};

SMJList.propTypes = {
  campaignGroups: PropTypes.array.isRequired,
  smjModelById: PropTypes.object.isRequired,
  deleteSmj: PropTypes.func.isRequired,
};

const Handler = ({ callApi, setLoader, notify, updateSmjList, campaignGroups, ...otherProps }) => {
  const { showAlert } = useAlert();
  const deleteSmj = useCallback(
    smj => {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign.title'),
        msg: `[${smj.name}]: ${utils.getLang(
          'smartmessaging.smartjourney.confirmDeleteSmartjourney'
        )}`,
        async onConfirm() {
          showAlert(null);
          setLoader(true);
          const response = await callApi(smartjourneyApi.deleteSmj, [smj.id]).finally(() =>
            setLoader(false)
          );
          if (response.isSuccess) {
            updateSmjList(campaignGroups.filter(c => c.id !== smj.id));
            notify({
              message: `[${smj.name}] ${utils.getLang(
                'smartmessaging.notifications.deletedSmartjourney'
              )}`,
              options: {
                variant: 'warning',
              },
            });
          } else {
            showAlert({
              title: `${utils.getLang(`smartmessaging.errorMessages.warning.title`)}`,
              msg: utils.getLang(`smartmessaging.errorMessages.smartjourney.${response.error}`),
              type: 'warning',
            });
          }
        },
        onDismiss() {
          showAlert(null);
        },
      });
    },
    [callApi, setLoader, showAlert, notify, updateSmjList, campaignGroups]
  );

  return <SMJList {...otherProps} deleteSmj={deleteSmj} campaignGroups={campaignGroups} />;
};

const mapStateToProps = state => ({
  campaignGroups: smjSelectors.getSMJListWithInfos(state.smartjourney),
});

const actionCreators = {
  updateSmjList: list => ({ type: 'RECEIVE_SMJ_LIST', value: list }),
};

Handler.propTypes = {
  callApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  closeAlert: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  updateSmjList: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, actionCreators)(withAlertAndLoader(withApiCaller(Handler)));
