const getSMJList = state => state.smjListsByModel;
const getSMJListWithInfos = state =>
  state.smjListsByModel.map(s => {
    const infos = state.smjInfos[s.id];
    return { ...s, ...infos };
  });

const getSMJModelList = state => state.smjModelList;

const getSMJById = (state, smjId) => getSMJList(state).filter(smj => smj.id === smjId);

export default {
  getSMJList,
  getSMJModelList,
  getSMJListWithInfos,
  getSMJById,
};
