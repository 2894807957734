import { createReducer } from '@reduxjs/toolkit';
import apiClient from '../utils/apiClient';
import getApiCaller from '../utils/apiClientCaller';

import { loadRequestModels } from './requestModelList';

import { loadRecipes } from './recipeList';

import { enqueueSnackbar } from './notify';

import utils from '../utils/utils';
import { loaderActions } from './loader';

const initialState = {
  campaignListById: {},
  campaignIdList: [], // TODO remove
};

const loadCampaigns = showAlert => async (dispatch, getState) => {
  const { config } = getState().app;
  const cmpList = await getApiCaller(showAlert)(dispatch, getState)(apiClient.loadCampaigns, [
    [
      (config['smartm-private:filter'] && 'privatefilter') || false,
      (config['smartm-planned:filter'] && 'plannedfilter') || false,
    ],
  ]);

  dispatch({
    type: 'RECEIVE_CAMPAIGNS',
    value: cmpList.map(c => ({ ...c, duplicates: JSON.parse(c.duplicates) })),
  });
};

const onDataLoaded = (state, { value }) => ({
  ...state,
  dataLoaded: value,
});

const receiveCampaigns = (state, { value }) => ({
  ...state,
  campaignListById: value.reduce(
    (campaignMapById, campaign) => ({
      ...campaignMapById,
      [campaign.id]: campaign,
    }),
    {}
  ),
});

const removeCampaign = (state, action) => {
  const campaignListById = { ...state.campaignListById };
  delete campaignListById[action.value];
  return {
    ...state,
    campaignListById: {
      ...campaignListById,
    },
  };
};

// TODO : check use and rename(==> updateCampaignView)
const updateCampaignView = (state, action) => ({
  ...state,
  campaignListById: {
    ...state.campaignListById,
    [action.value.id]: action.value,
  },
});

export const activateCampaign = (campaign, callback, showAlert) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const isWritable = await callApi(apiClient.campaignIsWritable, [campaign.id]);
  const asyncFn = async () => {
    setLoader(true);
    let upToDateCampaign;
    const activationResponse = await callApi(apiClient.activateCampaign, [
      campaign.id,
      !campaign.enabled,
    ]);

    if (activationResponse.isSuccess) {
      upToDateCampaign = await callApi(apiClient.getCampaign, [campaign.id]);
      upToDateCampaign.requestModelTypeId = campaign.requestModelTypeId;
      dispatch(
        enqueueSnackbar({
          message: `${upToDateCampaign.name}: ${
            upToDateCampaign.enabled
              ? utils.getLang('smartmessaging.notifications.enabledCampaign')
              : utils.getLang('smartmessaging.notifications.disabledCampaign')
          }`,

          options: {
            variant: 'info',
          },
        })
      );
      if (callback)
        callback(upToDateCampaign, () =>
          dispatch({
            type: 'UPDATE_CAMPAIGN',
            value: { ...upToDateCampaign, duplicates: JSON.parse(upToDateCampaign.duplicates) },
          })
        );
      else
        dispatch({
          type: 'UPDATE_CAMPAIGN',
          value: { ...upToDateCampaign, duplicates: JSON.parse(upToDateCampaign.duplicates) },
        });
    } else {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.errorMessages.campaign.notActivable.title'),
        msg: utils.getLang(
          (activationResponse.error &&
            `smartmessaging.errorMessages.campaign.notActivable.${activationResponse.error}`) ||
            'smartmessaging.errorMessages.campaign.notActivable.message'
        ),
      });
    }
  };
  if (isWritable)
    await asyncFn().finally(() => {
      setLoader(false);
    });
  else
    showAlert({
      type: 'warning',
      title: utils.getLang('smartmessaging.campaigns.missingRightAlertTitle'),
      msg: utils.getLang('smartmessaging.campaigns.changeNotAllowed'),
    });
};

export const deleteCampaign = (campaign, callbacks, showAlert) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const isWritable = await callApi(apiClient.campaignIsWritable, [campaign.id]);
  if (!isWritable)
    showAlert({
      type: 'warning',
      title: utils.getLang('smartmessaging.campaigns.missingRightAlertTitle'),
      msg: utils.getLang('smartmessaging.campaigns.deleteNotAllowed'),
    });
  else
    showAlert({
      type: 'warning',
      title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign.title'),
      msg: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaign'),
      async onConfirm() {
        const setLoader = visible => {
          dispatch(loaderActions.setLoader(visible));
        };
        setLoader(true);
        if (campaign.id) {
          if (callbacks && callbacks.beforeDelete) {
            await callbacks.beforeDelete();
          }
          await getApiCaller(showAlert)(dispatch, getState)(apiClient.deleteCampaign, [
            campaign.id,
          ]).finally(() => setLoader(false));
          if (callbacks && callbacks.afterDelete) {
            callbacks.afterDelete(() => {
              dispatch({ type: 'REMOVE_CAMPAIGN', value: campaign.id });
            });
          } else dispatch({ type: 'REMOVE_CAMPAIGN', value: campaign.id });
          dispatch(
            enqueueSnackbar({
              message: `${campaign.name}: ${utils.getLang(
                'smartmessaging.notifications.deletedCampaign'
              )}`,
              options: {
                variant: 'warning',
              },
            })
          );
        }
        dispatch({ type: 'CLEAR_EDITOR_DATA' });
        showAlert(false);
      },
      onDismiss() {
        showAlert(false);
      },
    });
};

export const getCsv = (campaign, showAlert) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const exportFileId = await callApi(apiClient.csvExport, [campaign.recipeId]);

  if (exportFileId && exportFileId !== -1) {
    utils.downloadPrivateStoredFile(exportFileId, callApi);
  } else {
    showAlert({
      msg: utils.getLang('smartmessaging.noData'),
    });
  }
};

export const getCampaignById = (state, campaignId) =>
  state.campaignList.campaignListById[campaignId];

export const loadInitialDatas = showAlert => async dispatch => {
  await dispatch(loadRequestModels(showAlert));
  await dispatch(loadRecipes(showAlert));
  await dispatch(loadCampaigns(showAlert));
};

export default createReducer(initialState, {
  RECEIVE_CAMPAIGNS: receiveCampaigns,
  UPDATE_CAMPAIGN: updateCampaignView,
  REMOVE_CAMPAIGN: removeCampaign,
  ON_DATA_LOADED: onDataLoaded,
});
