import React from 'react';
import { connect } from 'react-redux';
import getApiCaller from 'src/utils/apiClientCaller';
import { useAlert } from 'src/hooks';

const ApiCallerWrapper = ({ doGetApiCaller, children }) => {
  const { showAlert } = useAlert();
  return children({ callApi: doGetApiCaller(showAlert) });
};

const actionCreators = {
  doGetApiCaller: getApiCaller,
};
const Connected = connect(null, actionCreators)(ApiCallerWrapper);

export default Cmp => props => (
  <Connected>{({ callApi }) => <Cmp {...props} callApi={callApi} />}</Connected>
);
