import React from 'react';
import { useSelector, connect } from 'react-redux';
import HistoryPage from 'src/components/common/history/HistoryPage';
import { massActionSelector } from 'src/selectors/massAction/massAction';
import { useParams } from 'wouter';

const MassActionHistory = props => {
  const params = useParams();
  const campaignToEdit = useSelector(massActionSelector(Number(params.id)));
  return (
    <HistoryPage
      {...props}
      campaign={campaignToEdit}
      excludedProperties={['targetWithOptin', 'targetTotal', 'isTest']}
      summaryExcludedProperties={['launchedBy', 'status']}
      rowSpanCols={['clubName', 'date', 'targetNumber', 'optinType']}
      sortable={['clubName', 'date', 'targetNumber', 'optinType']}
      receiveHistory={history =>
        Object.entries(history.globalStatsList).reduce(
          (m, [k, e]) => [
            ...m,
            {
              id: k,
              items: e.map(i => ({
                ...i,
                setupValue: JSON.parse(i.setupValue),
                setupColumns: JSON.parse(i.setupColumns),
              })),
            },
          ],
          []
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  filtersByKey: state.massAction.criteriaByKey,
});

export default connect(mapStateToProps)(MassActionHistory);
