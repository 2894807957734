import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import {
  Switch,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Fade,
  Fab,
  Tooltip,
  alpha,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CampaignListHead from './CampaignListHead';
import CampaignItemMenu from './CampaignItemMenu';
import Search from 'src/components/common/filters/Search';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import { getSortedCampaigns } from 'src/reducers/campaignListView';
import { deleteCampaign, activateCampaign, getCsv } from 'src/reducers/campaignList';
import { editCampaign } from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import {
  filterFn,
  getColumnData,
  quickFilterFn,
} from 'src/components/common/filters/campaignFiltersUtils';

import filtrable from 'src/components/common/hocs/filtrable';
import { useLocation } from 'wouter';
import { useAlert } from 'src/hooks';

const PendingEl = () => <i>{utils.getLang('smartmessaging.campaignList.pendingElement')}</i>;

const SpanedTableCell = ({ children, style }) => (
  <TableCell style={style}>
    <span className="cellspan">{children}</span>
  </TableCell>
);

SpanedTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
};

SpanedTableCell.defaultProps = {
  style: {},
};

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  campaignContainer: {
    overflow: 'auto',
  },
  editorBtn: {
    zIndex: 2,
    position: 'absolute',
    right: '10px',
    top: '40px',
  },
  tableRowRoot: {
    [`&.${classes.tableRowSelected}, &.${classes.tableRowSelected}:hover`]: {
      backgroundColor: alpha(theme.palette.grey[700], theme.palette.action.selectedOpacity),
    },
  },
  tableRowSelected: {
    backgroundColor: alpha(theme.palette.grey[700], theme.palette.action.selectedOpacity),
  },
}));

const CampaignItem = connect(null, {
  doDeleteCampaign: deleteCampaign,
  doEditCampaign: editCampaign,
  doActivateCampaign: activateCampaign,
  doGetCsv: getCsv,
})(
  ({
    campaign,
    doDeleteCampaign,
    doGetCsv,
    requestModelByRMTypeId,
    recipesById,
    doActivateCampaign,
    selected,
    selectRow,
    isNodeMapped,
  }) => {
    const { classes } = useStyles();
    const [, navigate] = useLocation();
    const { showAlert } = useAlert();

    const requestModelisMissing = useMemo(
      () =>
        !(
          Boolean(campaign.recipeId) &&
          Boolean(requestModelByRMTypeId[recipesById[campaign.recipeId]?.requestModelTypeId])
        ),
      [campaign, recipesById, requestModelByRMTypeId]
    );

    const triggerLabel = useMemo(() => {
      if (requestModelisMissing) {
        return <PendingEl />;
      }
      return campaign.eventDriven === false
        ? utils.getLang(
            `smartmessaging.campaignTrigger.time.${
              requestModelByRMTypeId[recipesById[campaign.recipeId].requestModelTypeId].periodicity
            }`
          )
        : campaign.eventDriven === true && utils.getLang('smartmessaging.campaignTrigger.action');
    }, [campaign, recipesById, requestModelByRMTypeId, requestModelisMissing]);

    const requestModelName = useMemo(() => {
      if (requestModelisMissing) {
        return <PendingEl />;
      }
      return utils.getLang(
        `smartmessaging.requestmodel.label.${
          requestModelByRMTypeId[recipesById[campaign.recipeId].requestModelTypeId].name
        }`
      );
    }, [campaign, recipesById, requestModelByRMTypeId, requestModelisMissing]);

    return (
      <TableRow
        hover
        classes={{ selected: classes.tableRowSelected, root: classes.tableRowRoot }}
        selected={selected}
        onClick={e => {
          e.preventDefault();
          selectRow(campaign.id);
        }}
        onDoubleClick={e => {
          e.preventDefault();
          navigate(`/campaigns/${campaign.id}/edit`);
        }}
      >
        <TableCell>
          <CampaignItemMenu
            options={[
              {
                name: utils.getLang('smartmessaging.campaignList.action.edit'),
                action: () => {
                  navigate(`/campaigns/${campaign.id}/edit`);
                },
              },
              {
                name: utils.getLang('smartmessaging.campaignList.action.csv'),
                action: () => doGetCsv(campaign, showAlert),
                disabled: !campaign.recipeId,
                hidden: campaign.eventDriven,
              },
              {
                name: utils.getLang('smartmessaging.campaignList.action.delete'),
                action: () => {
                  doDeleteCampaign(campaign, null, showAlert);
                },
              },
              {
                name: utils.getLang('smartmessaging.campaignList.action.showHistory'),
                hidden: !(
                  campaign.requestModelTypeId &&
                  requestModelByRMTypeId[campaign.requestModelTypeId] &&
                  requestModelByRMTypeId[campaign.requestModelTypeId].id
                ),
                action: () => {
                  navigate(`/campaigns/${campaign.id}/history`);
                },
              },
            ]}
            selectRow={selectRow}
            campaign={campaign}
          />
        </TableCell>
        <TableCell scope="row" style={{ fontWeight: 'bold' }}>
          <div style={{ display: 'flex' }}>
            {!!campaign.duplicates.length && (
              <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                  <WarningIcon color="error" />
                </Tooltip>
              </div>
            )}
            <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
              {campaign.name}
            </div>
          </div>
        </TableCell>
        <SpanedTableCell>{requestModelName}</SpanedTableCell>
        <SpanedTableCell>{triggerLabel}</SpanedTableCell>
        <SpanedTableCell>
          {campaign.actionType ? campaign.actionType : <PendingEl />}
        </SpanedTableCell>
        <SpanedTableCell>{new Date(campaign.creationDate).toLocaleDateString()}</SpanedTableCell>
        {isNodeMapped && <SpanedTableCell>{campaign.networkNodeName || '--'}</SpanedTableCell>}
        <SpanedTableCell>{campaign.creationUserName || '--'}</SpanedTableCell>
        <SpanedTableCell>
          {campaign.isPlanned
            ? utils.getLang('smartmessaging.campaignList.item.isPlanned')
            : utils.getLang('smartmessaging.campaignList.item.isNotPlanned')}
        </SpanedTableCell>
        <SpanedTableCell padding="checkbox">
          <Switch
            value={campaign.enabled ? '1' : '0'}
            checked={campaign.enabled}
            onClick={() => {
              doActivateCampaign(campaign, null, showAlert);
            }}
          />
        </SpanedTableCell>
      </TableRow>
    );
  }
);

CampaignItem.propTypes = {
  campaign: PropTypes.object.isRequired,
  requestModelByRMTypeId: PropTypes.object,
  recipesById: PropTypes.object,
  selected: PropTypes.bool.isRequired,
  selectRow: PropTypes.func.isRequired,
};

function CampaignList({
  order,
  orderBy,
  filteredData,
  doFilter,
  addFilter,
  requestModelByRMTypeId,
  recipesById,
  removeFilter,
  clearFilters,
  selectedFilters,
}) {
  const [selected, select] = useState(null);
  const [quickFilterValue, updateQuickFilter] = useState('');
  const [, navigate] = useLocation();
  const dispatch = useDispatch();
  const sortCampaigns = useCallback(
    (newOrderBy, sortFns) => {
      dispatch({ type: 'SORT_CAMPAIGNS', value: { orderBy: newOrderBy, sortFns } }); // FIX ME function passed to redux state
    },
    [dispatch]
  );

  function quickFiltered() {
    if (quickFilterValue) {
      const quickFilters = [
        { key: 'name', value: quickFilterValue },
        {
          key: 'recipeId',
          value: quickFilterValue,
          type: 'TEXT',
          convertValue: recipeId =>
            (recipeId &&
              requestModelByRMTypeId[recipesById[recipeId].requestModelTypeId] &&
              utils.getLang(
                `smartmessaging.requestmodel.label.${
                  requestModelByRMTypeId[recipesById[recipeId].requestModelTypeId].name
                }`
              )) ||
            utils.getLang('smartmessaging.campaignList.pendingElement'),
        },
      ];
      return filteredData.filter(quickFilterFn(quickFilters));
    }
    return filteredData;
  }

  const { classes } = useStyles();
  return (
    <>
      <Fab
        onClick={e => {
          e.preventDefault();
          navigate(`/campaigns/create`);
        }}
        className={classes.editorBtn}
        color="primary"
        aria-label="add"
      >
        <AddIcon />
      </Fab>
      <Fade in timeout={1000}>
        <div style={{ textAlign: 'left', margin: '8px' }}>
          <Search
            updateQuickFilter={updateQuickFilter}
            filterProps={{
              filteredData,
              doFilter,
              addFilter,
              removeFilter,
              clearFilters,
              selectedFilters,
            }}
          />
        </div>
      </Fade>
      <Fade in timeout={1000}>
        <div className={`${classes.campaignContainer}`}>
          <Paper className={classes.root} elevation={0}>
            <div className={classes.tableWrapper}>
              <Table aria-labelledby="tableTitle" stickyHeader>
                <CampaignListHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={sortCampaigns}
                  addFilter={addFilter}
                  isNodeMapped={utils.isNodeMapped()}
                  columsConfigs={getColumnData({ requestModelByRMTypeId, recipesById })}
                />
                <TransitionGroup component={TableBody}>
                  {quickFiltered().map(campaign => (
                    <CSSTransition timeout={300} classNames="fade" key={campaign.id}>
                      <CampaignItem
                        campaign={campaign}
                        selected={selected === campaign.id}
                        selectRow={select}
                        requestModelByRMTypeId={requestModelByRMTypeId}
                        recipesById={recipesById}
                        isNodeMapped={utils.isNodeMapped()}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Table>
            </div>
          </Paper>
        </div>
      </Fade>
    </>
  );
}

CampaignList.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  filteredData: PropTypes.array.isRequired,
  doFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

const FiltrableCampaignList = filtrable(props => ({
  filterFn,
  filtrableData: props.sortedData,
}))(CampaignList);

const mapStateToProps = state => ({
  campaignListById: state.campaignList.campaignListById,
  order: state.campaignListView.order,
  orderBy: state.campaignListView.orderBy,
  sortedData: getSortedCampaigns(state),
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
});

export default connect(mapStateToProps, null)(FiltrableCampaignList);
