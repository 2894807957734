import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Collapse,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';

import FilterInput from 'src/components/common/filters/FilterInput';

import utils from 'src/utils/utils';

const useStyles = makeStyles()((theme, _params, classes) => ({
  active: { color: theme.palette.primary.main },
  root: {
    [`&.${classes.active}`]: {
      color: theme.palette.primary.main,
      '& svg path': { color: theme.palette.primary.main },
    },
  },
}));

function WithFilterSortLabel({
  column,
  addFilter,
  visibleInputs,
  showInput,
  setInputRef,
  input,
  label,
  labelProps,
}) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <TableSortLabel {...labelProps}>{label}</TableSortLabel>
        <Tooltip title={utils.getLang('smartmessaging.tooltip.filters')}>
          <IconButton
            aria-label="filter"
            onClick={() => {
              showInput(!visibleInputs);
              if (!visibleInputs) {
                if (input && input.focus) setTimeout(() => input.focus(), 300);
              }
            }}
            size="large"
          >
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={visibleInputs}>
        <FilterInput column={column} addFilter={addFilter} setInputRef={setInputRef} />
      </Collapse>
    </>
  );
}

WithFilterSortLabel.propTypes = {
  column: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  visibleInputs: PropTypes.bool.isRequired,
  showInput: PropTypes.func.isRequired,
  setInputRef: PropTypes.func.isRequired,
  input: PropTypes.any,
  label: PropTypes.string.isRequired,
  labelProps: PropTypes.object.isRequired,
};

WithFilterSortLabel.defaultProps = { input: null };

function WithRefAndFilterSortLabel(props) {
  const inputRef = useRef(null);
  const setInputRef = useCallback(e => {
    inputRef.current = e;
  }, []);
  return <WithFilterSortLabel {...props} input={inputRef.current} setInputRef={setInputRef} />;
}

function CampaignListHead(props) {
  const { order, orderBy, onRequestSort, addFilter, columsConfigs } = props;
  const isNodeMapped = utils.isNodeMapped();
  const [iVisible, showInputs] = useState(false);
  const { classes } = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {columsConfigs
          .filter(c => (isNodeMapped ? true : ['networkNodeName'].indexOf(c.id) === -1))
          .map(column => (
            <TableCell key={column.id}>
              {!column.filtrable && (
                <TableSortLabel
                  classes={{ root: classes.root, active: classes.active }}
                  active={orderBy === column.id}
                  direction={order}
                  onClick={() => {
                    onRequestSort(column.id, column.sortFns || false);
                  }}
                >
                  {column.label}
                </TableSortLabel>
              )}

              {column.filtrable && (
                <WithRefAndFilterSortLabel
                  labelProps={{
                    classes: { root: classes.root, active: classes.active },
                    active: orderBy === column.id,
                    direction: order,
                    onClick: () => {
                      onRequestSort(column.id, column.sortFns || false);
                    },
                  }}
                  visibleInputs={iVisible}
                  showInput={showInputs}
                  column={column}
                  addFilter={addFilter}
                  label={column.label}
                  classes={classes}
                />
              )}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}
CampaignListHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  columsConfigs: PropTypes.array.isRequired,
};

export default CampaignListHead;
