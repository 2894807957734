import { connect } from 'react-redux';
import { checkContentValidity } from 'src/reducers/campaignEditor';

import EmailObjectLabel from 'src/components/common/contentEditor/EmailObjectLabel';

const mapStateToProps = state => ({
  contentValidity: checkContentValidity(state),
});

export default connect(mapStateToProps)(EmailObjectLabel);
