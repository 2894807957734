import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { TextField, InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import FilterPanel from './FilterPanel';
import utils from 'src/utils/utils';

const useStyles = makeStyles()(() => ({
  input: { fontSize: '1rem', paddingLeft: '8px' },
}));

function Search({ value, filterProps, updateQuickFilter, ...props }) {
  const { classes } = useStyles();

  return (
    <>
      <TextField
        {...props}
        onChange={e => {
          updateQuickFilter(e.target.value);
        }}
        placeholder={utils.getLang('smartmessaging.quickSearch')}
        InputProps={{
          classes: { input: `${classes.input} ` },
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon style={{ fill: '#b6b6b6' }} />
            </InputAdornment>
          ),
        }}
      />
      <FilterPanel {...filterProps} open={!!filterProps.selectedFilters.length} />
    </>
  );
}

Search.propTypes = {
  value: PropTypes.string,
  filterProps: PropTypes.object.isRequired,
  updateQuickFilter: PropTypes.func.isRequired,
};
Search.defaultProps = { value: '' };

export default Search;
