import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Typography, Divider, Fade, Button } from '@mui/material';
import { connect } from 'react-redux';

import withDataResolver from '../common/withDataResolver';
import { initData, loadAvailableResultfields, loadMassActions } from 'src/reducers/massAction';

import MassActionRequestList from './requestList/MassActionRequestList';
import MassActionRequestResult from './requestResultList/MassActionRequestResult';
import ColumnsSelector from './resultfieldsSelector/ColumnsSelector';
import MassActionMessage from './massActionMessage/MassActionMessage';
import MassActionContentSelector from './massActionMessage/MassActionContentSelector';

import MassActionHistory from './history/MassActionHistory';

import utils from 'src/utils/utils';
import { Redirect, Route, Switch, useLocation, useRoute } from 'wouter';
import { useCampaignEditor } from 'src/reducers/massAction/massAction';
import Loader from '../common/Loader';
import MassActionRequestConfig from './requestBuilder/MassActionRequestConfig';
import NotFound from '../common/NotFound';

// Etapes de l'éditeur/créateur de contenu de campagnes ponctuelles
export const [REQUEST_CONFIG, COLUMS_SELECTOR, REQUEST_RESULTS, CONTENT, CONTENT_SELECTOR] = [
  'config',
  'col_select',
  'result',
  'content',
  'content_select',
];

const styles = () => ({
  root: {
    flex: 1,
    padding: '0',
    overflow: 'hidden',
    position: 'relative',
  },
});

function Editor({ campaignId, ...otherProps }) {
  const loaded = useCampaignEditor(Number(campaignId));
  if (loaded) return <MassActionRequestConfig {...otherProps} />;

  return (
    <div>
      <Loader wrapperClass="ghostWrapperClass" text={utils.getLang('smartmessaging.loading')} />
    </div>
  );
}

Editor.propTypes = { campaignId: PropTypes.string.isRequired };

function Creator(props) {
  return <MassActionRequestConfig {...props} />;
}

function Content({ currentStep, ...otherProps }) {
  const [createRouteMatch] = useRoute('/oneoff/create');
  const [partialRoute, partialRouteParams] = useRoute('/oneoff/:id');
  const [editRouteMatch, editRouteParams] = useRoute('/oneoff/:id/edit');

  if (partialRoute && /^\d+$/.test(partialRouteParams.id)) {
    return <Redirect to={`/oneoff/${partialRouteParams.id}/edit`} />;
  }

  if (createRouteMatch || editRouteMatch) {
    switch (currentStep) {
      case REQUEST_CONFIG:
        if (editRouteMatch) return <Editor campaignId={editRouteParams.id} {...otherProps} />;
        if (createRouteMatch) return <Creator {...otherProps} />;
        return <NotFound />;
      case COLUMS_SELECTOR:
        return <ColumnsSelector {...otherProps} />;
      case REQUEST_RESULTS:
        return <MassActionRequestResult {...otherProps} />;
      case CONTENT:
        return <MassActionMessage {...otherProps} />;
      case CONTENT_SELECTOR:
        return <MassActionContentSelector {...otherProps} />;
      default:
        return <NotFound />;
    }
  }

  return (
    <Switch>
      <Route path="/oneoff/list">
        <MassActionRequestList {...otherProps} />;
      </Route>
      <Route path="/oneoff/:id/history">
        <MassActionHistory {...otherProps} />
      </Route>
      <Route path="/oneoff/*?">
        <Redirect to="/oneoff/list" />
      </Route>
      <Route>
        <div>Err</div>
      </Route>
    </Switch>
  );
}

Content.propTypes = {
  currentStep: PropTypes.string.isRequired,
};

const resolvers = {
  resolve: props => async (callApi, showAlert) => {
    await props.doLoadData(showAlert);
    await props.loadResultfields(showAlert);
    await props.doLoadMassActions(showAlert);
  },

  onResolved: () => async () => {},
  getLoaderText: () => utils.getLang('smartmessaging.massAction.mainDataLoading'),
};

const WithData = withDataResolver(resolvers)(Content);

const BackBtn = ({ onBack }) => (
  <Button
    variant="text"
    style={{
      textTransform: 'none',
    }}
    onClick={e => {
      e.preventDefault();
      onBack();
    }}
  >
    <Typography variant="body2" color="textSecondary">
      {utils.getLang('smartmessaging.buttonLabel.backToList')}
    </Typography>
  </Button>
);

BackBtn.propTypes = { onBack: PropTypes.func.isRequired };

function MassActionContent({ doClearData, ...props }) {
  const [currentStep, goToStep] = useState(REQUEST_CONFIG);
  const [extraProps, setExtraProps] = useState({});
  const [, navigate] = useLocation();
  const [matchListRoute] = useRoute('/oneoff/list');
  function enhancedGoToStep(step, newExtraProps) {
    setExtraProps(newExtraProps || {});
    goToStep(step);
  }
  return (
    <>
      <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
        <Typography
          variant="h5"
          color="textSecondary"
          style={{
            margin: 'auto 16px',
            flex: 1,
            textAlign: 'left',
          }}
        >
          {utils.getLang('smartmessaging.massAction.mainTitle')}
        </Typography>
        {!matchListRoute && (
          <BackBtn
            onBack={() => {
              doClearData();
              goToStep(REQUEST_CONFIG); // reinitialiser au premier STEP
              navigate('/oneoff/list');
            }}
          />
        )}
      </div>
      <Divider />
      <Fade in timeout={500}>
        <div className={props.classes.root}>
          <WithData {...props} {...{ currentStep, goToStep: enhancedGoToStep, ...extraProps }} />
        </div>
      </Fade>
    </>
  );
}

MassActionContent.propTypes = {
  classes: PropTypes.object.isRequired,
  doClearData: PropTypes.func.isRequired,
};

const actionCreators = {
  doLoadData: initData,
  loadResultfields: loadAvailableResultfields,
  doLoadMassActions: loadMassActions,
};

const mapStateToProps = state => ({
  isWritable: state.massAction.isWritable,
});

export default connect(mapStateToProps, actionCreators)(withStyles(MassActionContent, styles));
