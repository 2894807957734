import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Button, AppBar, Toolbar, Typography, Fade } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import PreviousIcon from '@mui/icons-material/ChevronLeft';

import apiClient from 'src/utils/apiClient';
import customTemplateApi from 'src/utils/api/customTemplateApi';
import getApiCaller from 'src/utils/apiClientCaller';
import utils from 'src/utils/utils';

import MessageModelItem from 'src/components/common/MessageModelItem';
import MassActionMsgImportTplItem from './MassActionMsgImportTplItem';
import Loader from 'src/components/common/Loader';
import AccordionItem from 'src/components/common/AccordionItem';

import CardList from 'src/components/common/CardList';
import { checkContentFieldsAndSelectedColumns } from 'src/reducers/massAction';
import { CONTENT_EDITOR } from './MassActionMessage';
import { CONTENT_SELECTOR } from '../MassActionContent';
import { useAlert } from 'src/hooks';

const useStyles = makeStyles()(() => ({
  appBar: {
    position: 'relative',
    textAlign: 'left',
  },
  flex: { flex: 1 },
}));

const StyledAppBar = ({
  goToStep,
  currentName,
  actionType,
  forceIsNewContent,
  currentMassActionId,
}) => {
  const { classes } = useStyles();
  return (
    <AppBar className={classes.appBar} color="primary">
      <Toolbar style={{ paddingLeft: '8px' }}>
        <div className={classes.flex}>
          <Typography variant="h5" color="inherit">
            {utils.getLang(`smartmessaging.contentEditor.title.${actionType}`)}
          </Typography>
          <Typography variant="body2" color="inherit">
            {currentName}
            {!!currentMassActionId && ` (${currentMassActionId})`}
          </Typography>
        </div>
        <Button
          style={{ margin: '2px' }}
          variant="text"
          color="primary"
          onClick={e => {
            e.preventDefault();
            forceIsNewContent(false);
            goToStep(CONTENT_SELECTOR);
          }}
        >
          <PreviousIcon color="inherit" />
          {utils.getLang('smartmessaging.buttonLabel.previous')}
        </Button>
      </Toolbar>
    </AppBar>
  );
};

StyledAppBar.propTypes = {
  goToStep: PropTypes.func.isRequired,
  currentName: PropTypes.string.isRequired,
  actionType: PropTypes.number.isRequired,
  currentMassActionId: PropTypes.number.isRequired,
  forceIsNewContent: PropTypes.func.isRequired,
};

const MessageTemplateSelectorRender = ({
  templates,
  customTemplates,
  goToStep,
  currentName,
  actionType,
  forceIsNewContent,
  expanded,
  setExpanded,
  loadStoredFileData,
  currentMassActionId,
  onFileUpload,
}) => (
  <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }}>
    <StyledAppBar
      goToStep={goToStep}
      currentName={currentName}
      actionType={actionType}
      forceIsNewContent={forceIsNewContent}
      currentMassActionId={currentMassActionId}
    />
    <Fade in timeout={500}>
      <div style={{ flex: 1, overflow: 'auto' }}>
        <AccordionItem
          expanded={expanded === 'resagenerics'}
          onChange={(e, value) => {
            setExpanded((value && 'resagenerics') || '');
          }}
          title={utils.getLang('smartmessaging.contentEditor.selectResaGenericTemplate')}
        >
          <div
            style={{
              flex: 1,
              padding: '8px',
              overflow: 'hidden',
            }}
          >
            {templates.map(messageModel => (
              <MessageModelItem
                hideLang
                key={messageModel.id}
                messageModel={messageModel}
                onMessageModelSelect={() => {
                  loadStoredFileData(messageModel.storedFileId, messageModel.object);
                }}
              />
            ))}
          </div>
        </AccordionItem>
        {!!(customTemplates && customTemplates.length) && (
          <AccordionItem
            expanded={expanded === 'custom'}
            onChange={(e, value) => {
              setExpanded((value && 'custom') || '');
            }}
            title={utils.getLang('smartmessaging.contentEditor.selectGenericCustomTemplate')}
          >
            <CardList
              items={customTemplates}
              onItemClick={(e, item) => {
                e.preventDefault();
                loadStoredFileData(item.storedfileId, item.subject || '');
              }}
            />
          </AccordionItem>
        )}
        <AccordionItem
          expanded={expanded === 'imp'}
          onChange={(e, value) => {
            setExpanded((value && 'imp') || '');
          }}
          title={utils.getLang('smartmessaging.contentEditor.uploadTemplate')}
        >
          <MassActionMsgImportTplItem
            onFileLoaded={data => {
              onFileUpload(data);
            }}
          />
        </AccordionItem>
      </div>
    </Fade>
  </div>
);

MessageTemplateSelectorRender.propTypes = {
  templates: PropTypes.array.isRequired,
  customTemplates: PropTypes.array.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  currentName: PropTypes.string.isRequired,
  actionType: PropTypes.number.isRequired,
  currentMassActionId: PropTypes.number.isRequired,
  forceIsNewContent: PropTypes.func.isRequired,
  expanded: PropTypes.string,
  setExpanded: PropTypes.func.isRequired,
  loadStoredFileData: PropTypes.func.isRequired,
};

MessageTemplateSelectorRender.defaultProps = { expanded: null };

function MessageTemplateSelectorResolver({
  actionType,
  doGetApiCaller,
  setStep,
  rmId,
  currentName,
  goToStep,
  forceIsNewContent,
  doCheckContentFieldsAndSelectedColumns,
  currentMassActionId,
  updateCurrentCmpAction,
  currentLanguage,
}) {
  const [templates, setTemplates] = useState(null);

  const { showAlert } = useAlert();

  useEffect(() => {
    let fetchData;
    if (!templates) {
      fetchData = async () => {
        const apiCaller = doGetApiCaller(showAlert);
        const list = await apiCaller(apiClient.getMessageModels, [rmId, actionType]);
        const customList = await apiCaller(customTemplateApi.getGenericTemplates, [actionType]);
        setTemplates({
          templates: list || [],
          customTemplates: (customList || []).filter(ct => !!ct.storedfileId && !!ct.actionTypeId),
        });
      };

      fetchData();
    }
  });
  const [expanded, setExpanded] = useState(null);

  const receiveContent = useCallback(
    (content, object) => {
      doCheckContentFieldsAndSelectedColumns(
        content,
        actionType,
        contentFields => {
          updateCurrentCmpAction({ language: currentLanguage || 'FR' });
          setStep(CONTENT_EDITOR, {
            content: content || '',
            object: object || '',
            fields: contentFields,
          });
        },
        showAlert
      );
    },
    [
      actionType,
      currentLanguage,
      doCheckContentFieldsAndSelectedColumns,
      setStep,
      showAlert,
      updateCurrentCmpAction,
    ]
  );

  const loadStoredFileData = useCallback(
    async (fileId, object) => {
      const content = await doGetApiCaller(showAlert)(apiClient.get, [utils.link(fileId)]);
      receiveContent(content, object);
    },
    [doGetApiCaller, receiveContent, showAlert]
  );

  const onFileUpload = useCallback(
    data => {
      receiveContent(data, '');
    },
    [receiveContent]
  );

  return (
    (!!templates && (
      <MessageTemplateSelectorRender
        templates={templates.templates}
        customTemplates={templates.customTemplates}
        loadStoredFileData={loadStoredFileData}
        onFileUpload={onFileUpload}
        doGetApiCaller={doGetApiCaller}
        setStep={setStep}
        currentName={currentName}
        currentMassActionId={currentMassActionId}
        goToStep={goToStep}
        actionType={actionType}
        forceIsNewContent={forceIsNewContent}
        expanded={expanded}
        setExpanded={setExpanded}
      />
    )) || <Loader wrapperClass="ghostWrapperClass" text={utils.getLang('smartmessaging.loading')} />
  );
}

const mapStateToProps = state => ({
  rmId: state.massAction.requestModel.id,
  currentName: state.massAction.currentName,
  currentMassActionId: state.massAction.currentMassActionId,
  currentLanguage: state.app.currentLanguage.shortName,
});

const actionCreators = {
  doGetApiCaller: getApiCaller,
  doCheckContentFieldsAndSelectedColumns: checkContentFieldsAndSelectedColumns,
  updateCurrentCmpAction: cmpAction => ({ type: 'UPDATE_CURRENT_CMP_ACTION', value: cmpAction }),
  forceIsNewContent: force => ({
    type: 'SET_FORCE_IS_NEW_CONTENT',
    value: force,
  }),
};

MessageTemplateSelectorResolver.propTypes = {
  actionType: PropTypes.number.isRequired,
  doCheckContentFieldsAndSelectedColumns: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  forceIsNewContent: PropTypes.func.isRequired,
  rmId: PropTypes.number.isRequired,
  currentName: PropTypes.string.isRequired,
  currentMassActionId: PropTypes.number.isRequired,
  updateCurrentCmpAction: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

const MessageTemplateSelector = props => <MessageTemplateSelectorResolver {...props} />;

export default connect(mapStateToProps, actionCreators)(MessageTemplateSelector);
