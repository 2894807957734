import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Typography, IconButton, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { resetContentEditorFlowData } from 'src/reducers/massAction';

import utils from 'src/utils/utils';
import CustCard from 'src/components/common/ActionCard';
import { CONTENT } from '../MassActionContent';
import { TEMPLATE_SELECTOR } from './MassActionMessage';

const useStyles = makeStyles()(theme => ({
  addBtn: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.primary.contrastText,
  },
}));

const ActionCreator = ({ actionTypeId, goToStep, doResetContentEditorFlowData, lang }) => {
  const { classes } = useStyles();
  return (
    <CustCard title={utils.getLang(lang.title[actionTypeId])}>
      <Paper style={{ height: '200px', width: '160px', display: 'inline-block' }}>
        <Typography
          color="primary"
          style={{
            wordWrap: 'normal',
            padding: '8px',
          }}
        >
          {utils.getLang(lang.createText[actionTypeId])}
        </Typography>

        <IconButton
          onClick={e => {
            e.preventDefault();
            doResetContentEditorFlowData();
            goToStep(CONTENT, {
              actionType: actionTypeId,
              initialStep: TEMPLATE_SELECTOR,
            });
          }}
          className={classes.addBtn}
          size="small"
        >
          <AddIcon />
        </IconButton>
      </Paper>
    </CustCard>
  );
};

ActionCreator.propTypes = {
  actionTypeId: PropTypes.number.isRequired,
  goToStep: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
};

const actionCreators = {
  doResetContentEditorFlowData: resetContentEditorFlowData,
};

export default connect(null, actionCreators)(ActionCreator);
