import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  Typography,
  IconButton,
  Tooltip,
  Toolbar,
  AppBar,
  Breadcrumbs,
  ButtonBase,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import WarningIcon from '@mui/icons-material/Warning';
import SaveIcon from '@mui/icons-material/Save';

import DeleteIcon from '@mui/icons-material/Delete';

import utils from 'src/utils/utils';
import { useAlert } from 'src/hooks';
import { useLocation } from 'wouter';
import { CONTENT, INFOS, SCHEDULE, TARGET } from '../CampaignEditor';

const useStyles = makeStyles()(theme => ({
  deleteBtn: { margin: '2px', color: theme.palette.primary.contrastText },
  breadcrumbs: { background: theme.palette.grey[50], padding: '8px 24px', flex: 1 },
  appBar: {
    position: 'relative',
    textAlign: 'left',
  },
  flex: { flex: 1 },
  menuItem: {
    '&[class*="disabled"]': { color: '#ababab', opacity: 0.7 },
    color: theme.palette.text.secondary,
    outline: 'none',
    cursor: 'pointer',
    fontSize: '0.9rem',
    display: 'block',
    '&.selected': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      opacity: 0.9,
    },
  },
}));

function EditorAppBar({
  editedCampaign,
  currentStep,
  goToEditorStep,
  recipeCfgLoaded,
  campaignActions,
  periods,
  requestModelByRMTypeId,
  recipesById,
  selectedType,
  visibleBtns,
  deleteCampaign,
  saveCampaign,
  campaignValidity,
}) {
  const { classes } = useStyles();
  const { showAlert } = useAlert();
  const [, navigate] = useLocation();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar style={{ padding: '0px 24px' }}>
        <div className={classes.flex}>
          <Typography variant="h5" color="inherit">
            {editedCampaign.name || utils.getLang('smartmessaging.campaignEditor.emptyNameTitle')}
            {!!editedCampaign.id && ` (${editedCampaign.id})`}
          </Typography>
          {(selectedType || editedCampaign.recipeId) && (
            <Typography variant="body2" color="inherit">
              {(editedCampaign.recipeId &&
                utils.getLang(
                  `smartmessaging.requestmodel.label.${
                    requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId]
                      .name
                  }`
                )) ||
                utils.getLang(`smartmessaging.requestmodel.label.${selectedType.name}`)}
            </Typography>
          )}
        </div>
        {visibleBtns && editedCampaign.isWritable && (
          <>
            <Tooltip title={utils.getLang('smartmessaging.button.campaignEditor.delete')}>
              <IconButton
                className={classes.deleteBtn}
                onClick={e => {
                  e.preventDefault();
                  deleteCampaign(
                    editedCampaign,
                    {
                      beforeDelete: () => {
                        navigate('/campaigns/list');
                      },
                    },
                    showAlert
                  );
                }}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <div
              style={{
                boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
                padding: '0 8px',
              }}
            >
              <Tooltip title={utils.getLang('smartmessaging.button.campaignEditor.save')}>
                <span>
                  <IconButton
                    color="inherit"
                    disabled={!campaignValidity.isValid}
                    style={{ margin: '2px' }}
                    onClick={e => {
                      e.preventDefault();
                      saveCampaign(true, showAlert);
                    }}
                    size="large"
                  >
                    <SaveIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          </>
        )}
      </Toolbar>
      <Breadcrumbs
        className={classes.breadcrumbs}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <ButtonBase
          className={`${classes.menuItem} ${currentStep === INFOS ? 'selected' : ''}`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(INFOS);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.infos')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          disabled={!recipeCfgLoaded}
          className={`${classes.menuItem} ${currentStep === TARGET ? 'selected' : ''}`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(TARGET);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.targetedCustomers')}
          </Typography>
        </ButtonBase>
        <ButtonBase
          disabled={!(campaignActions.length || CONTENT === currentStep)}
          className={`${classes.menuItem} ${currentStep === CONTENT ? 'selected' : ''}`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(CONTENT);
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!!editedCampaign.duplicates.length && (
              <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                <WarningIcon color="error" />
              </Tooltip>
            )}
            <Typography component="span" style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
              {utils.getLang('smartmessaging.campaignEditor.menuItem.content')}
            </Typography>
          </div>
        </ButtonBase>
        <ButtonBase
          disabled={!(SCHEDULE === currentStep || (!!periods && !!periods.length))}
          className={`${classes.menuItem} ${currentStep === SCHEDULE ? 'selected' : ''}`}
          onClick={e => {
            e.preventDefault();
            goToEditorStep(SCHEDULE);
          }}
        >
          <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit' }}>
            {utils.getLang('smartmessaging.campaignEditor.menuItem.schedule')}
          </Typography>
        </ButtonBase>
      </Breadcrumbs>
    </AppBar>
  );
}

EditorAppBar.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  currentStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  recipeCfgLoaded: PropTypes.bool.isRequired,
  campaignActions: PropTypes.array.isRequired,
  periods: PropTypes.array.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  visibleBtns: PropTypes.bool.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  saveCampaign: PropTypes.func.isRequired,
  campaignValidity: PropTypes.object.isRequired,
};

EditorAppBar.defaultProps = {
  selectedType: null,
};

export default EditorAppBar;
