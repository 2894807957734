import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import withDataResolver from '../common/withDataResolver';
import { loadAppStatus } from 'src/reducers/app';

const AppInfos = ({ appStatus }) => (
  <div
    style={{
      padding: '4px 8px',
      color: ' #737373c0',
      fontSize: '13px',
      textAlign: 'right',
    }}
  >
    {appStatus.version}
  </div>
);

const mapStateToProps = state => ({
  appStatus: state.app.status,
});

const actionCreators = {
  doLoadAppStatus: loadAppStatus,
};

const resolvers = {
  resolve: props => async (callApi, showAlert) => {
    await props.doLoadAppStatus(showAlert);
  },

  onResolved: () => async () => {},
  loader: false,
};

AppInfos.propTypes = { appStatus: PropTypes.object.isRequired };

export default connect(mapStateToProps, actionCreators)(withDataResolver(resolvers)(AppInfos));
