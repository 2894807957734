import { createReducer } from '@reduxjs/toolkit';
import apiClient from '../utils/apiClient';
import { enqueueSnackbar } from './notify';
import utils from '../utils/utils';
import { loaderActions } from './loader';
import getApiCaller from '../utils/apiClientCaller';
import { getImageMaxSize } from './app';
import parametersCfg from './utils/parameters';
import paramSelectors from '../selectors/parameters';

const initialState = { clubParams: {}, currentParams: null, invalidities: {} };

export const getSmParams = state => paramSelectors.getCurrentParams(state);

export const logState = () => () => {};

const handleValidityChange = (fieldValidity, currentInvalidities) => {
  const refreshedInvalidities = { ...currentInvalidities };
  if (fieldValidity.isValid && refreshedInvalidities[fieldValidity.key]) {
    delete refreshedInvalidities[fieldValidity.key];
  } else if (!fieldValidity.isValid && !refreshedInvalidities[fieldValidity.key]) {
    refreshedInvalidities[fieldValidity.key] = { ...fieldValidity };
  }
  return refreshedInvalidities;
};

const checkInvalidities = params => {
  const initialInvalidities = [
    ...parametersCfg.textParamKeys,
    ...parametersCfg.timeParamKeys,
  ].reduce((acc, paramKey) => {
    const fieldValidity = parametersCfg.validationsByType[
      parametersCfg.fieldValidationTypesByKey[paramKey]
    ](paramKey, params[paramKey], params);
    if (fieldValidity.isValid) return acc;
    return { ...acc, [paramKey]: fieldValidity };
  }, {});
  return initialInvalidities;
};

const receiveSmParams = (state, { value }) => ({
  ...state,
  clubParams: value,
});

const setCurrentParams = (state, { value }) => ({
  ...state,
  currentParams: value,
  invalidities: checkInvalidities(state.clubParams[value]),
});

const updateCurrentParams = (state, { value }) => ({
  ...state,
  clubParams: {
    ...state.clubParams,
    [state.currentParams]: {
      ...state.clubParams[state.currentParams],
      ...value,
    },
  },
  invalidities: checkInvalidities(value),
});

const onSmCfgFieldChange = (state, { value }) => ({
  ...state,
  clubParams: {
    ...state.clubParams,
    [state.currentParams]: {
      ...state.clubParams[state.currentParams],
      [value.key]: value.value || null,
    },
  },
  invalidities: checkInvalidities({
    ...state.clubParams[state.currentParams],
    [value.key]: value.value || null,
  }),
});

const onFieldValidityChange = (state, { value }) => ({
  ...state,
  invalidities: handleValidityChange(value, state.invalidities),
});

export const loadSmParams = (newParamsId, showAlert) => async (dispatch, getState) => {
  const params = await getApiCaller(showAlert)(
    dispatch,
    getState
  )(apiClient.loadSmParams).then(
    r => r,
    err => Promise.resolve(err)
  );

  const paramsSorter = (p1, p2) => {
    if ((!p2.clubName && !p1.clubName) || p1.clubName === p2.clubName) {
      return 0;
    }
    if (p1.clubName === 'default') return -1;
    if (p2.clubName === 'default') return 1;
    if (!p2.clubName) {
      return -1;
    }
    if (!p1.clubName) {
      return 1;
    }
    return p1.clubName.toLowerCase() < p2.clubName.toLowerCase() ? -1 : 1;
  };

  const paramsMap = params.sort(paramsSorter).reduce(
    (pMap, p) => ({
      ...pMap,
      [p.originalClubId]: { ...p, clubId: p.clubId || p.originalClubId },
    }),
    {}
  );

  const pKeys = Object.keys(paramsMap);
  dispatch({
    type: 'RECEIVE_SM_PARAMS',
    value: paramsMap,
  });
  if (newParamsId) {
    const currenKey = pKeys.filter(k => paramsMap[k].id === newParamsId)[0];
    dispatch({
      type: 'SET_CURRENT_PARAMS',
      value: currenKey,
    });
  } else {
    const clubId = parseInt(sessionStorage.getItem('club'), 10);
    dispatch({
      type: 'SET_CURRENT_PARAMS',
      value:
        pKeys.length === 1
          ? pKeys[0]
          : pKeys.filter(k => paramsMap[k].originalClubId === clubId)[0] ||
            pKeys.filter(k => paramsMap[k].clubName === 'default')[0] ||
            pKeys[0],
    });
  }
};

export const sendSmConfigImage = (paramKey, file, showAlert) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  setLoader(true);
  const handleSendFileError = async (error, doCall, defaultHandler) => {
    if (error.response && error.response.status === 413) {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.errorMessages.warning.title'),
        msg: utils.stringFormat(utils.getLang('smartmessaging.error.fileTooLarge'), [
          getImageMaxSize(getState()),
        ]),
      });
      return Promise.reject(error);
    }
    const res = await defaultHandler();
    return res;
  };
  const storedFileId = await callApi(
    apiClient.uploadFile,
    [file],
    handleSendFileError
  ).finally(() => setLoader(false));

  dispatch({ type: 'ON_SM_CFG_FIELD_CHANGE', value: { key: paramKey, value: storedFileId } });
};

export const saveParams = showAlert => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  setLoader(true);
  const currentParams = paramSelectors.getCurrentParams(getState());
  const params = await getApiCaller(showAlert)(dispatch, getState)(apiClient.saveSmParams, [
    currentParams,
  ]).finally(() => setLoader(false));

  if (!currentParams.id) {
    dispatch(loadSmParams(params.id, showAlert));
  } else {
    dispatch({ type: 'UPDATE_CURRENT_PARAMS', value: params });
    dispatch(
      enqueueSnackbar({
        message: utils.getLang('smartmessaging.notifications.savedParameters'),
        options: {
          variant: 'success',
        },
      })
    );
  }
};

export const deleteParams = (id, showAlert) => async (dispatch, getState) => {
  if (!id && id !== 0) {
    showAlert({
      type: 'warning',
      title: utils.getLang('smartmessaging.config.cannotDeleteParameters.title'),
      msg: utils.getLang('smartmessaging.config.cannottDeleteParameters'),
    });
  } else
    showAlert({
      type: 'warning',
      title: utils.getLang('smartmessaging.config.confirmDeleteParameters.title'),
      msg: utils.getLang('smartmessaging.config.confirmDeleteParameters'),
      async onConfirm() {
        const setLoader = visible => {
          dispatch(loaderActions.setLoader(visible));
        };
        setLoader(true);
        await getApiCaller(showAlert)(dispatch, getState)(apiClient.deleteParams, [id]).finally(
          () => {
            setLoader(false);
            showAlert(null);
          }
        );
        dispatch(loadSmParams(null, showAlert));
        dispatch(
          enqueueSnackbar({
            message: utils.getLang('smartmessaging.notifications.deletedParameters'),
            options: {
              variant: 'warning',
            },
          })
        );
      },
      onDismiss() {
        showAlert(null);
      },
    });
};

export default createReducer(initialState, {
  RECEIVE_SM_PARAMS: receiveSmParams,
  ON_SM_CFG_FIELD_CHANGE: onSmCfgFieldChange,
  ON_FIELD_VALIDITY_CHANGE: onFieldValidityChange,
  SET_CURRENT_PARAMS: setCurrentParams,
  UPDATE_CURRENT_PARAMS: updateCurrentParams,
});
