import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import HistoryPage from 'src/components/common/history/HistoryPage';
import withDataResolver from 'src/components/common/withDataResolver';
import apiClient from 'src/utils/apiClient';
import UsedFilter from './UsedFilter';
import { useSelector } from 'react-redux';
import { getCampaignById } from 'src/reducers/campaignList';
import { useParams } from 'wouter';
import { getRequestModelIdByRequestModelTypeId } from 'src/reducers/requestModelList';

const mapFiltersByKey = criteriaList =>
  criteriaList.reduce(
    (currentResult, criteria) => ({ ...currentResult, [criteria.key]: criteria }),
    {}
  );
const HistoryRender = ({ data, ...props }) => (
  <HistoryPage
    {...props}
    filtersByKey={data}
    excludedProperties={['launchedBy', 'status', 'targetWithOptin', 'targetTotal', 'isTest']}
    rowSpanCols={['clubName', 'date', 'targetNumber', 'optinType']}
    sortable={['clubName', 'date', 'targetNumber', 'optinType', 'sentMessageNumber']}
    components={{ UsedFilter }}
  />
);
const resolvers = {
  resolve: props => async callApi => {
    const filters = await callApi(apiClient.loadRecipeParameters, [props.rmId]);
    return mapFiltersByKey(filters);
  },
  onResolved: () => async () => {},
  loader: false,
};

HistoryRender.propTypes = { data: PropTypes.object.isRequired };

const HistoryDataResolver = withDataResolver(resolvers)(HistoryRender);

const History = props => {
  const params = useParams();
  const campaignFromSelector = useSelector(state => getCampaignById(state, params.id));
  const requestModelIdByRequestModelTypeId = useSelector(getRequestModelIdByRequestModelTypeId);
  const requestModelListById = useSelector(state => state.requestModelList.requestModelListById);

  const requestModelByRMTypeId = useMemo(
    () =>
      Object.keys(requestModelIdByRequestModelTypeId).reduce(
        (acc, rmTypeId) => ({
          ...acc,
          [rmTypeId]: {
            ...requestModelListById[requestModelIdByRequestModelTypeId[rmTypeId]],
          },
        }),
        {}
      ),
    [requestModelIdByRequestModelTypeId, requestModelListById]
  );

  const campaign = props.pCampaign || campaignFromSelector;
  const rmId =
    campaign.requestModelTypeId &&
    requestModelByRMTypeId[campaign.requestModelTypeId] &&
    requestModelByRMTypeId[campaign.requestModelTypeId].id;

  return (
    <HistoryDataResolver
      {...props}
      rmId={rmId}
      campaign={props.pCampaign || campaignFromSelector}
    />
  );
};

History.propTypes = { pCampaign: PropTypes.object };
History.defaultProps = { pCampaign: null };

export default History;
