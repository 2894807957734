import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';
import Alert from 'src/components/common/Alert';

interface Props {
  children: ReactNode;
}

const AlertContext = createContext(null);

export const AlertProvider = ({ children }: Props) => {
  const [alertConfig, setAlertConfig] = useState(null);

  return (
    <AlertContext.Provider value={{ alertConfig, setAlertConfig }}>
      <Alert />
      {children}
    </AlertContext.Provider>
  );
};

const useAlert = () => {
  const { alertConfig, setAlertConfig } = useContext(AlertContext);

  const showAlert = useCallback(
    newAlertConfig => {
      setAlertConfig(newAlertConfig);
    },
    [setAlertConfig]
  );

  return { alertConfig, showAlert };
};

export default useAlert;
