import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  CampaignTarget,
  CampaignInfos,
  CampaignMessage,
  EditorAppBar,
  CampaignSchedule,
} from './editorComponents';
import withSteps from 'src/components/common/hocs/withSteps';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import {
  saveCampaign,
  checkCmpInfosValidity,
  checkCampaignValidity,
} from 'src/reducers/campaignEditor';

import { getSortedCategoryList } from 'src/enums/campaignCategory';
import Loader from 'src/components/common/Loader';
import { useHashLocation } from 'wouter/use-hash-location';
import { useLocation } from 'wouter';

export const [SMARTJ_CMP_INFOS, SMARTJ_CMP_TARGET, SMARTJ_CMP_CONTENT, SMARTJ_CMP_SCHEDULE] = [
  'smartj-cmp-infos',
  'smartj-cmp-target',
  'smartj-cmp-content',
  'smartj-cmp-schedule',
];

const mapStateToProps = state => ({
  editedCampaign: state.campaignEditor.editedCampaign,
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  recipesById: state.recipeList.recipeListById,
  selectedType: state.campaignEditor.selectedType,
  requestModelsByCategoryId: state.requestModelList.requestModelsByCategoryId,
  sortedCategoryList: getSortedCategoryList(),
  recipeCfgLoaded: state.campaignEditor.recipeFieldsConfig.loaded,
  campaignActions: state.campaignEditor.actions.campaignActions,
  infosValidity: checkCmpInfosValidity(state),
  campaignValidity: checkCampaignValidity(state),
  wizVilTypes: state.app.wizVilleRequestModels,
  wizVilleNewAboTypes: state.app.wizVilleNewAboRequestModels,
  wizVilleCancelAboTypes: state.app.wizVilleCancelAboRequestModels,
  wizVilleEntryActivated: state.app.config.wizVilleEntryActivated,
  wizVilleNewAboActivated: state.app.config.wizVilleNewAboActivated,
  wizVilleCancelAboActivated: state.app.config.wizVilleCancelAboActivated,
  periods: state.campaignEditor.schedule.editedCmpPeriods,
});

const actionCreators = {
  updateEditedCampaign: changes => ({ type: 'UPDATE_EDITED_CAMPAIGN', value: changes }),
  selectOptinType: optinType => ({
    type: 'SELECT_OPTIN_TYPE',
    value: optinType,
  }),
  doSaveCampaign: saveCampaign,
  selectCampaignType: requestModel => ({
    type: 'SELECT_CAMPAIGN_TYPE',
    value: requestModel,
  }),
};

const StepHandler = ({ currentStep, goToStep, showBtns, ...otherProps }) => {
  const [, navigate] = useLocation();
  useEffect(() => {
    switch (currentStep) {
      case SMARTJ_CMP_CONTENT:
        showBtns(false);
        break;
      default:
        showBtns(true);
    }
  }, [currentStep, showBtns]);

  switch (currentStep) {
    case SMARTJ_CMP_INFOS:
      return (
        <CampaignInfos
          {...otherProps}
          goToEditorStep={goToStep}
          onBack={() => {
            navigate(`/list`);
          }}
        />
      );

    case SMARTJ_CMP_TARGET:
      return <CampaignTarget {...otherProps} goToEditorStep={goToStep} />;

    case SMARTJ_CMP_CONTENT:
      return <CampaignMessage {...otherProps} goToEditorStep={goToStep} />;
    case SMARTJ_CMP_SCHEDULE:
      return <CampaignSchedule {...otherProps} goToEditorStep={goToStep} />;
    default:
      return (
        <CampaignInfos
          {...otherProps}
          goToEditorStep={goToStep}
          onBack={() => {
            navigate(`/list`);
          }}
        />
      );
  }
};

StepHandler.propTypes = {
  goToStep: PropTypes.func.isRequired,
  showBtns: PropTypes.func.isRequired,
  currentStep: PropTypes.string.isRequired,
};

function CampaignEditor({
  goToStep,
  doSaveCampaign,
  requestModelByRMTypeId,
  smartjourney,
  editedCampaign,
  ...props
}) {
  const [visibleBtns, showBtns] = useState(true);
  const [hLocation, hNavigate] = useHashLocation();
  const [location] = useLocation();
  if (!!editedCampaign?.id && location === '/create' && hLocation === '/') {
    hNavigate(`${editedCampaign.id}`);
  }

  if (!editedCampaign) return <Loader />;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        overflow: 'hidden',
        textAlign: 'left',
        height: '100%',
      }}
    >
      <EditorAppBar
        {...props}
        requestModelByRMTypeId={requestModelByRMTypeId}
        saveCampaign={doSaveCampaign}
        goToEditorStep={goToStep}
        visibleBtns={visibleBtns}
        smartjourney={smartjourney}
        editedCampaign={editedCampaign}
      />
      <StepHandler
        {...{
          ...props,
          doSaveCampaign,
          goToStep,
          showBtns,
          requestModelByRMTypeId,
          smartjourney,
          editedCampaign,
        }}
      />
    </div>
  );
}

CampaignEditor.propTypes = {
  goToStep: PropTypes.func.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  smartjourney: PropTypes.object.isRequired,
  editedCampaign: PropTypes.object,
};

CampaignEditor.defaultProps = {
  editedCampaign: null,
};

const Connected = connect(mapStateToProps, actionCreators)(CampaignEditor);

export default withSteps(Connected);
