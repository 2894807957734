import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editCampaign } from 'src/reducers/campaignEditor';
import { useAlert } from 'src/hooks';

function useCampaignEditor(campaignToEdit) {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  useEffect(() => {
    async function asyncFn() {
      await dispatch(
        editCampaign(campaignToEdit, conf => {
          showAlert(conf);
        })
      );
      setLoaded(true);
    }
    asyncFn();
  }, [campaignToEdit, dispatch, setLoaded, showAlert]);

  return loaded;
}

export default useCampaignEditor;
