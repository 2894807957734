import getApiCaller from 'src/utils/apiClientCaller';

import { loaderActions } from '../loader';

import ActionType from 'src/enums/actionType';
import customTemplateSelectors from 'src/selectors/customTemplates';
import customTemplateApi from 'src/utils/api/customTemplateApi';
import apiClient from 'src/utils/apiClient';
import utils from 'src/utils/utils';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils';
import paramsSelector from 'src/selectors/parameters/parameters';
import rmSelectors from 'src/selectors/requestModel';

export const saveTemplate = (callback, showAlert) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  setLoader(true);

  const template = customTemplateSelectors.getEditedTemplate(getState());

  const upToDateTemplate = await callApi(customTemplateApi.saveTemplate, [template]).finally(() =>
    setLoader(false)
  );

  dispatch({
    type: 'UPDATE_SAVED_TEMPLATE',
    value: { ...upToDateTemplate, isWritable: template.isWritable },
  });
  if (callback) callback(upToDateTemplate);

  showAlert({
    msg: utils.getLang('smartmessaging.customTemplateList.saveTemplate'),
  });
};

export const saveContent = (template, showAlert) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const htmlToFreemarker =
    template.actionTypeId === ActionType.SMS
      ? contentEditorUtils.htmlToFreemarkerSms
      : contentEditorUtils.htmlToFreemarker;

  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const editor = getState().customTemplates.mailEditor;
  const { objectEditor } = getState().customTemplates;
  let object = '';

  if (objectEditor) object = htmlToFreemarker(objectEditor.getData(), null, false);

  let storedFileId;
  const asyncFn = async () => {
    setLoader(true);
    // GET THE MODIFIED CURRENT MODIFIED CONTENT AND OBJECT
    const htmlToFreemarkerArgs =
      template.actionTypeId === ActionType.SMS ? [editor.getData()] : [null, editor, true];
    const content = htmlToFreemarker(...htmlToFreemarkerArgs);
    storedFileId = await callApi(apiClient.upload, [content, 'text/html']);
  };
  await asyncFn()
    .then(() => {
      dispatch({ type: 'SHOW_CUSTOM_CONTENT_EDITOR', value: false });
      dispatch({ type: 'CLEAR_LOADED_EDITORS' });
      dispatch({
        type: 'UPDATE_EDITED_TEMPLATE',
        value: { storedfileId: storedFileId, subject: object },
      });
      dispatch(
        saveTemplate(upToDateTemplate => {
          dispatch({
            type: 'UPDATE_EDITED_TEMPLATE',
            value: { ...upToDateTemplate, isWritable: template.isWritable },
          });
        }, showAlert)
      );
    })
    .finally(() => setLoader(false));
};

export const editTemplate = (uniqueId, showAlert) => async (dispatch, getState) => {
  const template = uniqueId
    ? { ...customTemplateSelectors.getMappedCustomTemplate(getState(), uniqueId) }
    : {
        id: null,
        name: null,
        description: null,
        subject: null,
        actionTypeId: null,
        requestModelTypeId: null,
        generic: true,
        clientToken: getState().auth.clientToken,
        storedfileId: null,
        userId: null,
        createdAt: null,
        isWritable: true,
      };
  if (uniqueId)
    template.isWritable = await getApiCaller(showAlert)(
      dispatch,
      getState
    )(customTemplateApi.isWritable, [template.id]);

  dispatch({
    type: 'EDIT_CUSTOM_TEMPLATE',
    value: template,
  });
};

export const loadEditorData = (
  { rmTypeId, actionTypeId, storedFileId, uploadedData = '' },
  showAlert
) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  setLoader(true);

  const smParams = paramsSelector.getCurrentParams(getState());
  const editorData = {
    fields: {},
    content: { body: '' },
    params: {
      headerFileId: smParams.headerStoredfileId || null,
      footerFileId: smParams.footerStoredfileId || null,
    },
  };

  const asyncFn = async () => {
    let fields;
    if (rmTypeId)
      fields = await callApi(apiClient.getContentFieldsForRequestModel, [
        rmSelectors.getRmIdByRmTypeId(getState(), rmTypeId),
        actionTypeId,
      ]);
    else fields = await callApi(apiClient.getContentFieldsForRequestModel, [null, actionTypeId]);

    const processedFields = contentEditorUtils.processContentFields(fields, actionTypeId, {
      headerFileId: smParams.headerStoredfileId || null,
      footerFileId: smParams.footerStoredfileId || null,
    });

    editorData.fields = processedFields;

    if (storedFileId)
      editorData.content.body = await callApi(apiClient.get, [utils.link(storedFileId)]);
    else editorData.content.body = uploadedData;
  };

  await asyncFn().finally(setLoader(false));

  return editorData;
};

const editCustomTemplate = (state, { value }) => ({
  ...state,
  editedTemplate: value,
});

const updateEditedTemplate = (state, action) => ({
  ...state,
  editedTemplate: { ...state.editedTemplate, ...action.value },
});

const showCustomContentEditor = (state, { value }) => ({
  ...state,
  showEditor: !!value,
});

const updateSavedTemplate = (state, { value }) => ({
  ...state,
  editedTemplate: { ...state.editedTemplate, ...value },
  customTemplateMap: { ...state.customTemplateMap, [value.id]: { ...value } },
});

const onMailEditorLoaded = (state, { value }) => ({
  ...state,
  mailEditor: value,
});

const onObjectEditorLoaded = (state, { value }) => ({
  ...state,
  objectEditor: value,
});

const clearEditors = state => ({
  ...onMailEditorLoaded(state, { value: null }),
  ...onObjectEditorLoaded(state, { value: null }),
});

export const editorInitialState = {
  editedTemplate: null,
  showEditor: false,
  mailEditor: null,
  objectEditor: null,
};

export const editorActions = {
  EDIT_CUSTOM_TEMPLATE: editCustomTemplate,
  UPDATE_EDITED_TEMPLATE: updateEditedTemplate,
  SHOW_CUSTOM_CONTENT_EDITOR: showCustomContentEditor,
  UPDATE_SAVED_TEMPLATE: updateSavedTemplate,
  ON_CUSTOM_MAIL_EDITOR_LOADED: onMailEditorLoaded,
  ON_CUSTOM_OBJECT_EDITOR_LOADED: onObjectEditorLoaded,
  CLEAR_LOADED_EDITORS: clearEditors,
};
