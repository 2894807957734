import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NotFound from '../common/NotFound';
import SmartjourneyList from './list/SmartjourneyList';
import GroupCreator, { CREATE_GROUP_MODE } from './creator/GroupCreator';
import SmartjourneyEditor from './editor/SmartjourneyEditor';
import withDataResolver from '../common/withDataResolver';
import { initData, createSMJ } from 'src/reducers/smartjourney';
import { mapRequestModelByRMTypeId } from 'src/reducers/requestModelList';
import { Redirect, Route, Switch, useLocation } from 'wouter';
import { createSelector } from '@reduxjs/toolkit';
import { useAlert } from 'src/hooks';

function SmartjourneyContent({ doClearData, generateSMJ, ...otherProps }) {
  const [, navigate] = useLocation();

  const { showAlert } = useAlert();

  const generateSMJwithCallback = useCallback(
    ({ smj, cmpList, language }) => {
      generateSMJ(
        { smj, cmpList, language },
        newGroup => navigate(`/smartjourney/${newGroup.id}/campaigns/list`),
        null,
        showAlert
      );
    },
    [generateSMJ, navigate, showAlert]
  );

  return (
    <Switch>
      <Route path="/smartjourney/list">
        <SmartjourneyList
          {...otherProps}
          smjModelById={otherProps.smjModelList.reduce(
            (m, model) => ({ ...m, [model.id]: model.key }),
            {}
          )}
        />
      </Route>
      <Route path="/smartjourney/create">
        <GroupCreator
          {...otherProps}
          generateSMJ={generateSMJwithCallback}
          initialStep={CREATE_GROUP_MODE}
        />
      </Route>
      <Route path="/smartjourney/:id/campaigns" nest>
        <SmartjourneyEditor {...otherProps} />
      </Route>
      <Route path="/smartjourney/*?">
        <Redirect to="/smartjourney/list" />
      </Route>
      <Route>
        <NotFound />;
      </Route>
    </Switch>
  );
}

SmartjourneyContent.propTypes = {
  doClearData: PropTypes.func.isRequired,
  smjModelList: PropTypes.array.isRequired,
  generateSMJ: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string,
  receiveRecipes: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

SmartjourneyContent.defaultProps = {
  currentLanguage: null,
};

const resolvers = {
  resolve: props => async (callApi, showAlert) => {
    await props.doLoadData(showAlert);
  },
  loader: true,
  onResolved: () => async () => {},
};

const mapStateToProps = state => ({
  smjModelList: createSelector(
    [mState => mState.smartjourney.smjModelList],
    modelList => modelList
  )(state),
  requestModelByRMTypeId: mapRequestModelByRMTypeId(state),
  currentLanguage: state.app.currentLanguage.shortName,
});

const actionCreators = {
  doLoadData: initData,
  generateSMJ: createSMJ,
  receiveRecipes: recipeList => ({ type: 'RECEIVE_RECIPES', value: recipeList }),
};

export default connect(
  mapStateToProps,
  actionCreators
)(withDataResolver(resolvers)(SmartjourneyContent));
