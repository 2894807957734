import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Fade, styled } from '@mui/material';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { loadSmParams } from 'src/reducers/parameters';
import { loadImageMaxSize, loadClubInfos } from 'src/reducers/app';
import MainMenu from './MainMenu';
import MainContent from './MainContent';
import Notifier from '../common/Notifier';
import withDataResolver from '../common/withDataResolver';
import NotFound from '../common/NotFound';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as locales from 'date-fns/locale';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { frFR, esES, enUS } from '@mui/x-date-pickers/locales';

import { LOGGIN_STATUS } from 'src/reducers/auth';
import Logger from '../auth/Logger';

const SuccessMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
}));

const ErrorMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
}));

const WarningMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  backgroundColor: theme.palette.warning.main,
}));

const InfoMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
}));

const useStyles = makeStyles()(() => ({
  root: {
    textAlign: 'center',
    overflow: 'auto',
    display: 'flex',
    height: '100vh',
  },
}));

function Main({ appHasContent, currentAppLn }) {
  const [key, setKey] = useState(0);
  const { classes } = useStyles();

  const rerender = useCallback(() => {
    setKey(prev => (prev ? 0 : 1));
  }, []);
  function getPickerProviderLocale() {
    switch (currentAppLn.shortName) {
      case 'FR':
        return locales.fr;
      case 'EN':
        return locales.enUS;
      case 'ES':
        return locales.es;
      default:
        return locales.fr;
    }
  }
  function getLocaleText() {
    switch (currentAppLn.shortName) {
      case 'FR':
        return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
      case 'EN':
        return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
      case 'ES':
        return esES.components.MuiLocalizationProvider.defaultProps.localeText;
      default:
        return frFR.components.MuiLocalizationProvider.defaultProps.localeText;
    }
  }
  if (appHasContent) {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={getPickerProviderLocale()}
        dateFormats={{ fullDate: 'd MMM yyyy' }}
        localeText={getLocaleText()}
      >
        <Fade in timeout={1000}>
          <div key={key} className={classes.root}>
            <MainMenu rerender={rerender} />
            <MainContent />
            <SnackbarProvider
              autoHideDuration={2000}
              maxSnack={3}
              Components={{
                success: SuccessMaterialDesignContent,
                error: ErrorMaterialDesignContent,
                warning: WarningMaterialDesignContent,
                info: InfoMaterialDesignContent,
              }}
            >
              <Notifier />
            </SnackbarProvider>
          </div>
        </Fade>
      </LocalizationProvider>
    );
  }
  return <NotFound text="Accès non-autorisé" />;
}

Main.propTypes = {
  appHasContent: PropTypes.bool.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};

const actionCreators = {
  doLoadSmParams: loadSmParams,
  doLoadImageMaxSize: loadImageMaxSize,
  doLoadClubInfos: loadClubInfos,
};

const resolvers = {
  resolve: props => async (callApi, showAlert) => {
    await props.doLoadClubInfos(showAlert);
    await props.doLoadSmParams(null, showAlert);
    await props.doLoadImageMaxSize(showAlert);
  },
  onResolved: () => async () => {},
};

const WithData = withDataResolver(resolvers)(Main);

const Connected = connect(
  state => ({
    appHasContent: state.app.appHasContent,
    currentAppLn: state.app.currentLanguage,
  }),
  actionCreators
)(WithData);

const Protected = ({ loggedIn, ...others }) => {
  if (loggedIn === LOGGIN_STATUS.loggedIn) return <Connected {...others} />;
  return <Logger />;
};

Protected.propTypes = {
  loggedIn: PropTypes.string.isRequired,
};

export default connect(
  state => ({
    loggedIn: state.auth.logginStatus,
  }),
  null
)(Protected);
