import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Typography, Divider, Fade } from '@mui/material';
import { format } from 'date-fns';
import withApiCaller from '../common/hocs/withApiCaller';
import Config from './Config';
import SentMessagesList from './SentMessagesList';
import apiClient from 'src/utils/apiClient';
import utils from 'src/utils/utils';
import LoaderContainer from '../common/LoaderContainer';
import ActionType from 'src/enums/actionType';

const loadHistory = {
  [ActionType.SMS.id]: apiClient.getSmsHistory,
  [ActionType.EMAIL.id]: apiClient.getMailHistory,
  [ActionType.MEMBER_NOTIFICATION.id]: apiClient.getNotifyHistory,
};

function SentMessages({ callApi, appConfig }) {
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [cfg, setCfg] = useState({ from: null, to: null, type: null });
  const [sortConfig, setSortConfig] = useState({
    order: 'asc',
    orderBy: 'unset',
  });

  async function doLoadSentMessages() {
    setLoading(true);

    const sentMessages = await callApi(loadHistory[cfg.type], [
      format(cfg.from, 'dd-MM-yyyy'),
      format(cfg.to, 'dd-MM-yyyy'),
      [
        (appConfig['smartm-private:filter'] && 'privatefilter') || false,
        (appConfig['smartm-planned:filter'] && 'plannedfilter') || false,
      ],
    ]).catch(() => setLoading(false));
    setSortConfig(last => ({
      ...last,
      orderBy: (sentMessages && sentMessages.length && Object.keys(sentMessages[0])[0]) || 'unset',
    }));
    const count = {
      openedCount: 0,
      custOpenedCount: 0,
      coachOpenedCount: 0,
      sponsorOpenedCount: 0,
      invitedOpenedCount: 0,
      coachCount: 0,
      customerCount: 0,
      sponsorCount: 0,
      invitedCount: 0,
      total: 0,
    };
    if (cfg.type === ActionType.SMS.id)
      sentMessages.forEach(sm => {
        count.total += sm.size;
        if (sm.opened) count.openedCount += 1;
      });
    if (cfg.type === ActionType.EMAIL.id) {
      count.total = sentMessages.length;
      sentMessages.forEach(sm => {
        if (sm.opened) {
          count.openedCount += 1;
          switch (sm.actionTypeId) {
            case ActionType.EMAIL.id:
              count.custOpenedCount += 1;
              break;
            case ActionType.EMAIL_COACH.id:
              count.coachOpenedCount += 1;
              break;
            case ActionType.EMAIL_SPONSORSHIP.id:
              count.sponsorOpenedCount += 1;
              break;
            case ActionType.EMAIL_INVITATION.id:
              count.invitedOpenedCount += 1;
              break;
            default:
              break;
          }
        }
        switch (sm.actionTypeId) {
          case ActionType.EMAIL.id:
            count.customerCount += 1;
            break;
          case ActionType.EMAIL_COACH.id:
            count.coachCount += 1;
            break;
          case ActionType.EMAIL_SPONSORSHIP.id:
            count.sponsorCount += 1;
            break;
          case ActionType.EMAIL_INVITATION.id:
            count.invitedCount += 1;
            break;
          default:
            break;
        }
      });
    }

    setData({ cfg, sentMessages, count });
    setLoading(false);
  }

  const loadSentMessages = useCallback(doLoadSentMessages, [cfg, callApi, appConfig]);

  async function doGetExport() {
    setLoading(true);
    const exportFileId = await callApi(apiClient.getSentMessagesExport, [
      format(cfg.from, 'dd-MM-yyyy'),
      format(cfg.to, 'dd-MM-yyyy'),
      data.cfg.type,
    ]).catch(() => setLoading(false));

    if (exportFileId && exportFileId !== -1)
      await utils.downloadPrivateStoredFile(exportFileId, callApi);

    setLoading(false);
  }

  const getExport = useCallback(doGetExport, [data, cfg, callApi]);

  return (
    <>
      <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
        <Typography
          variant="h5"
          color="textSecondary"
          style={{
            margin: 'auto 16px',
            flex: 1,
            textAlign: 'left',
          }}
        >
          {utils.getLang('smartmessaging.sentMessages.mainTitle')}
        </Typography>
      </div>
      <Divider />
      <Fade in>
        <div
          style={{
            margin: '12px',
            display: 'flex',
            flex: 1,
            padding: '0',
            overflow: 'hidden',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          <Config apply={loadSentMessages} {...{ cfg, setCfg }} />
          <LoaderContainer isLoading={isLoading}>
            {!!data && (
              <SentMessagesList
                {...{ sortConfig, setSortConfig }}
                getExport={getExport}
                type={data.cfg.type}
                from={data.cfg.from}
                to={data.cfg.to}
                sentMessages={data.sentMessages}
                count={data.count}
                openedCount={data.openedCount}
              />
            )}
          </LoaderContainer>
        </div>
      </Fade>
    </>
  );
}

SentMessages.propTypes = {
  callApi: PropTypes.func.isRequired,
  appConfig: PropTypes.object.isRequired,
};

export default withApiCaller(SentMessages);
