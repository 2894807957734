import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import utils from 'src/utils/utils';
import { useLocation } from 'wouter';
import { useAlert } from 'src/hooks';

const useStyles = makeStyles()(theme => ({
  button: {
    margin: theme.spacing(0.5),
    border: theme.border.primary,
    '&:hover': {
      opacity: 0.9,
    },
  },
  buttonMargin: { margin: theme.spacing(0.5) },
  borderTop: { borderTop: theme.border.primary },
  cancelBtn: { color: theme.palette.text.primary },
}));

const CustomTemplateButtons = ({ saveTemplate, closeTemplateEditor, template }) => {
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  const { showAlert } = useAlert();
  return (
    <div className={classes.borderTop} style={{ textAlign: 'right' }} key={3}>
      <Button
        className={`${classes.buttonMargin} ${classes.cancelBtn}`}
        onClick={e => {
          e.preventDefault();
          closeTemplateEditor();
          navigate('/templates');
        }}
      >
        {utils.getLang('smartmessaging.button.contentEditor.cancel')}
      </Button>
      <Button
        className={`${classes.buttonMargin} ${classes.button}`}
        disabled={!template.isWritable || !template.name}
        onClick={e => {
          e.preventDefault();
          saveTemplate(null, showAlert);
        }}
        variant="contained"
        color="primary"
        style={{ textAlign: 'right' }}
      >
        {utils.getLang('smartmessaging.button.contentEditor.save')}
      </Button>
    </div>
  );
};

CustomTemplateButtons.propTypes = {
  closeTemplateEditor: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
};

export default CustomTemplateButtons;
