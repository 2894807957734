import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import utils from 'src/utils/utils';

import MenuButton from './MenuButton';

import { useLocation, useRoute } from 'wouter';

const useStyles = makeStyles()(theme => ({
  popover: { padding: 0, margin: 0 },
  menuItem: {
    ...theme.typography.body1,
    fontSize: '0.9rem',
    outline: 'none',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'left',
    transition: 'background 0.5s ease, background 0.5s ease',
    padding: '12px 16px 12px 24px',
    '&.selected': {
      background: theme.palette.grey[300],
      color: theme.palette.text.primary,
      fontWeight: 450,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    '&:hover': {
      color: theme.palette.text.primary,
      background: theme.palette.grey[200],
    },
    '&:hover.selected': {
      background: theme.palette.grey[300],
    },
  },
}));

const MenuSelect = ({
  actionCoActivated,
  parametersActivated,
  automatedActivated,
  customTemplateActivated,
}) => {
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  const [autoCmpRouteMatch] = useRoute('/campaigns/*?');
  const [smartmJourneyRouteMatch] = useRoute('/smartjourney/*?');
  const [oneOffCmpRouteMatch] = useRoute('/oneoff/*?');
  const [templatesRouteMatch] = useRoute('/templates/*?');
  const [paramsRouteMatch] = useRoute('/params/*?');
  const [historyRouteMatch] = useRoute('/history/*?');

  return (
    <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      {automatedActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={autoCmpRouteMatch || smartmJourneyRouteMatch}
          title={utils.getLang('smartmessaging.mainMenu.campaigns')}
          onSelect={() => {
            navigate('/campaigns/list');
          }}
        />
      )}

      {actionCoActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={oneOffCmpRouteMatch}
          title={utils.getLang('smartmessaging.mainMenu.massAction')}
          onSelect={() => {
            navigate('/oneoff/list');
          }}
        />
      )}
      {customTemplateActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={templatesRouteMatch}
          title={utils.getLang('smartmessaging.mainMenu.customTemplates')}
          onSelect={() => {
            navigate('/templates');
          }}
        />
      )}
      {parametersActivated && (
        <MenuButton
          className={classes.menuItem}
          selected={paramsRouteMatch}
          title={utils.getLang('smartmessaging.mainMenu.config')}
          onSelect={() => {
            navigate('/params/list');
          }}
        />
      )}
      <MenuButton
        className={classes.menuItem}
        selected={historyRouteMatch}
        title={utils.getLang('smartmessaging.mainMenu.sentMessages')}
        onSelect={() => {
          navigate('/history');
        }}
      />
    </div>
  );
};

MenuSelect.propTypes = {
  actionCoActivated: PropTypes.bool.isRequired,
  parametersActivated: PropTypes.bool.isRequired,
  automatedActivated: PropTypes.bool.isRequired,
  customTemplateActivated: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  actionCoActivated: state.app.config['smartm-punctual:allow'],
  parametersActivated: state.app.config['smartm-param:allow'],
  automatedActivated:
    state.app.config['smartm-automation:allow'] || state.app.config['smartm-smartjourney:allow'],
  customTemplateActivated: state.app.config['smartm-template:allow'],
});

export default connect(mapStateToProps)(MenuSelect);
