import { createReducer } from '@reduxjs/toolkit';
import apiClient from '../utils/apiClient';
import { getCampaignCategoryById } from '../enums/campaignCategory';
import getApiCaller from '../utils/apiClientCaller';

const initialState = {
  requestModelListById: {},
  requestModelTypeIdByRequestModelId: {},
  requestModelsByCategoryId: {},
  requestModelIdList: [],
  requestModelIdByRequestModelTypeId: {},
};

const mapRequestModelsByCategoryId = rmList =>
  rmList.reduce((mapAcc, requestModel) => {
    const rez = {
      ...mapAcc,
    };

    if (!rez[requestModel.requestModelTypeCategoryId]) {
      rez[requestModel.requestModelTypeCategoryId] = {
        name: getCampaignCategoryById(requestModel.requestModelTypeCategoryId).name,
        requestModels: [],
      };
    }

    rez[requestModel.requestModelTypeCategoryId] = {
      ...rez[requestModel.requestModelTypeCategoryId],
      requestModels: [...rez[requestModel.requestModelTypeCategoryId].requestModels, requestModel],
    };
    return rez;
  }, {});

const mapRequestModelTypeIdByRequestModelId = rmList =>
  rmList.reduce((acc, rm) => ({ ...acc, [rm.id]: rm.requestModelTypeId }), {});

const mapRequestModelIdByRequestModelTypeId = rmList =>
  rmList.reduce(
    (mapAcc, rm) => ({
      ...mapAcc,
      [rm.requestModelTypeId]: rm.id,
    }),
    {}
  );

export const loadRequestModels = showAlert => async (dispatch, getState) => {
  const { config } = getState().app;
  const rmList = await getApiCaller(showAlert)(dispatch, getState)(apiClient.loadRequestModels, [
    [
      (config['smartm-private:filter'] && 'privatefilter') || false,
      (config['smartm-planned:filter'] && 'plannedfilter') || false,
    ],
  ]);
  dispatch({ type: 'RECEIVE_REQUESTMODELS', value: rmList });
};

export const getRequestModelIdByRequestModelTypeId = state =>
  state.requestModelList.requestModelIdByRequestModelTypeId;

export const mapRequestModelByRMTypeId = state =>
  Object.keys(getRequestModelIdByRequestModelTypeId(state)).reduce(
    (acc, rmTypeId) => ({
      ...acc,
      [rmTypeId]: {
        ...state.requestModelList.requestModelListById[
          getRequestModelIdByRequestModelTypeId(state)[rmTypeId]
        ],
      },
    }),
    {}
  );

const receiveRequestModels = (state, { value }) => ({
  ...state,
  requestModelIdList: value.map(rm => rm.id),
  requestModelTypeIdByRequestModelId: mapRequestModelTypeIdByRequestModelId(value),
  requestModelIdByRequestModelTypeId: mapRequestModelIdByRequestModelTypeId(value),
  requestModelsByCategoryId: mapRequestModelsByCategoryId(value),
  requestModelListById: value.reduce(
    (acc, requestModel) => ({ ...acc, [requestModel.id]: { ...requestModel } }),
    {}
  ),
});

export default createReducer(initialState, {
  RECEIVE_REQUESTMODELS: receiveRequestModels,
});
