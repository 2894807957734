import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

const NotFound = ({ text, children }) => (
  <div
    style={{
      height: '100vh',
      flex: 1,
      display: 'flex',
      background: '#27bbc5',
      backgroundImage: 'url("/resources/img/logo_resa.png")',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100px 160px',
      justifyContent: 'center',
    }}
  >
    <div
      style={{
        margin: 0,
        position: 'absolute',
        bottom: '25%',
        textAlign: 'center',
        color: '#ffffff',
        width: '100%',
      }}
    >
      <Typography variant="h1" style={{ fontSize: '2rem' }}>
        {text || 'Oops...Something went wrong'}
      </Typography>
      {children || ''}
    </div>
  </div>
);

NotFound.propTypes = { text: PropTypes.string, children: PropTypes.elementType };

NotFound.defaultProps = { text: '', children: null };

export default NotFound;
