import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EmailContentLabel from 'src/components/common/contentEditor/EmailContentLabel';

EmailContentLabel.propTypes = {
  contentValidity: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  contentValidity: state.massAction.contentValidity,
});

export default connect(mapStateToProps)(EmailContentLabel);
