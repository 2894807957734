import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useParams, useRoute } from 'wouter';
import { useDispatch, useSelector } from 'react-redux';
import {
  useDidMount,
  useWillUnmount,
  useCampaignEditor,
  useCampaignCreator,
  useAlert,
} from 'src/hooks';

import CampaignList from './campaignList/CampaignList';
import CampaignEditor from './campaignEditor';
import History from './history/History';
import Loader from '../common/Loader';

import { getCampaignById, loadInitialDatas } from 'src/reducers/campaignList';
import utils from 'src/utils/utils';
import { INFOS } from './campaignEditor/CampaignEditor';

function WECampaignEditor({ doClearData, ...others }) {
  useEffect(
    () => () => {
      doClearData();
    },
    [doClearData]
  );
  const params = useParams();
  const campaignToEdit = useSelector(state => getCampaignById(state, parseInt(params.id, 10)));
  const reduxDataLoaded = useCampaignEditor(campaignToEdit);

  return <CampaignEditor {...others} reduxDataLoaded={reduxDataLoaded} initialStep={INFOS} />;
}

WECampaignEditor.propTypes = {
  doClearData: PropTypes.func.isRequired,
};

function WECampaignCreator({ doClearData, ...others }) {
  useEffect(
    () => () => {
      doClearData();
    },
    [doClearData]
  );
  const reduxDataLoaded = useCampaignCreator();
  return <CampaignEditor {...others} reduxDataLoaded={reduxDataLoaded} initialStep={INFOS} />;
}

WECampaignCreator.propTypes = {
  doClearData: PropTypes.func.isRequired,
};

function Content(props) {
  const [isLoading, setLoading] = useState(true);
  const isMounted = useRef(true);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();

  useDidMount(async () => {
    await dispatch(loadInitialDatas(showAlert));
    if (isMounted.current) setLoading(false);
  });

  useWillUnmount(() => {
    isMounted.current = false;
  });

  const [partialRoute, partialRouteParams] = useRoute('/campaigns/:id');
  if (partialRoute && /^\d+$/.test(partialRouteParams.id)) {
    return <Redirect to={`/campaigns/${partialRouteParams.id}/edit`} />;
  }

  if (isLoading)
    return (
      <div>
        <Loader
          wrapperClass="ghostWrapperClass"
          text={utils.getLang('smartmessaging.campaigns.mainDataLoading')}
        />
      </div>
    );

  return (
    <Switch>
      <Route path="/campaigns/list">
        <CampaignList {...props} />
      </Route>
      <Route path="/campaigns/create">
        <WECampaignCreator {...props} />
      </Route>
      <Route path="/campaigns/:id/edit">
        <WECampaignEditor {...props} />
      </Route>

      <Route path="/campaigns/:id/history">
        <History {...props} />
      </Route>
      <Route path="/campaigns/*?">
        <Redirect to="/campaigns/list" />
      </Route>
    </Switch>
  );
}

Content.propTypes = {
  doClearData: PropTypes.func.isRequired,
};

export default Content;
