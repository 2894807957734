import jwtDecode from 'jwt-decode';
import utils from '../utils/utils';
import { refreshToken, setRefreshTokenTimeOut } from '../reducers/auth';

const generateErrorMessage = error => {
  let msg = utils.getLang('Une erreur est survenue');
  if (error)
    if (error.response) {
      msg += '</br>';
      msg += error.response.status || '';
      msg += '</br>';
      msg += (error.response.data && error.response.data.error) || '';
      msg += '</br>';
      msg += (error.response.data && error.response.data.message) || '';
      msg += '</br>';
      msg += (error.response.data && error.response.data.path) || '';
    } else if (error.request && error.config) {
      msg = utils.getLang('Erreur serveur (pas de réponse)');
      msg += '</br>';
      msg += error.config && error.config.url;
    }
  return msg;
};

const handleError = async (error, failedCall, dispatch, showAlert) => {
  if (
    error &&
    error.response &&
    error.response.status === 401 &&
    error.response.data &&
    error.response.data.message &&
    error.response.data.message.includes('JWT expired')
  ) {
    await refreshToken(
      {
        success(bearer) {
          dispatch({ type: 'RECEIVE_TOKEN', value: jwtDecode(bearer.access_token) });
        },
        failure() {
          dispatch({ type: 'LOGIN_FAILURE' });
        },
      },
      JSON.parse(sessionStorage['sm-store']).client,
      setRefreshTokenTimeOut
    );

    const newTryResp = await failedCall().then(
      resp => Promise.resolve(resp),
      err => {
        showAlert({
          type: 'warning',
          title: utils.getLang('Erreur'),
          msg: generateErrorMessage(err),
        });
        return Promise.reject(err);
      }
    );
    return Promise.resolve(newTryResp);
  }

  showAlert({
    type: 'warning',
    title: utils.getLang('Erreur'),
    msg: generateErrorMessage(error),
  });

  return Promise.reject(error);
};

const callApi = async (apiCallFn, argList, specificErrorHandler, dispatch) => {
  let doCall;
  if (argList && argList.length) {
    doCall = async () => {
      const data = await apiCallFn(...argList);
      return data;
    };
  } else {
    doCall = async () => {
      const data = await apiCallFn();
      return data;
    };
  }

  const rez = await doCall().then(
    resp => resp,
    async error => {
      if (specificErrorHandler) {
        return specificErrorHandler(
          error,
          doCall,
          () => handleError(error, doCall, dispatch),
          dispatch
        );
      }
      return handleError(error, doCall, dispatch);
    }
  );
  return rez;
};

/** returns a function to call the api */
export default showAlert => (dispatch, getState) => async (
  apiCallFn,
  argList,
  specificErrorHandler
) => {
  const rez = await callApi(
    apiCallFn,
    argList || false,
    specificErrorHandler || false,
    dispatch,
    getState,
    showAlert
  );
  return rez;
};
