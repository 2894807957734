import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  IconButton,
  Collapse,
} from '@mui/material';
import FilterIcon from '@mui/icons-material/FilterList';

import FilterInput from 'src/components/common/filters/FilterInput';

import massActionListFilterConfigs from './filter/utils';

import utils from 'src/utils/utils';

const columnLabels = {
  name: () => utils.getLang('smartmessaging.campaignList.column.name'),
  creationDate: () => utils.getLang('smartmessaging.campaignList.column.createDate'),
  creationUserName: () => utils.getLang('smartmessaging.campaignList.column.cretionUserName'),
  networkNodeName: () => utils.getLang('smartmessaging.campaignList.column.networkNodeName'),
};

function WithFilterSortLabel({ classes, column, addFilter, visibleInputs, showInput, ...props }) {
  return (
    <>
      <div style={{ display: 'flex' }}>
        <TableSortLabel {...props} classes={classes} />
        <Tooltip title={utils.getLang('smartmessaging.tooltip.filters')}>
          <IconButton aria-label="filter" onClick={() => showInput(!visibleInputs)} size="large">
            <FilterIcon />
          </IconButton>
        </Tooltip>
      </div>
      <Collapse in={visibleInputs}>
        <FilterInput column={column} addFilter={addFilter} />
      </Collapse>
    </>
  );
}

WithFilterSortLabel.propTypes = {
  column: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  addFilter: PropTypes.func.isRequired,
  visibleInputs: PropTypes.bool.isRequired,
  showInput: PropTypes.func.isRequired,
};

const useStyles = makeStyles()((theme, _params, classes) => ({
  active: { color: theme.palette.primary.main },
  root: {
    [`&.${classes.active}`]: {
      color: theme.palette.primary.main,
      '& svg path': { color: theme.palette.primary.main },
    },
  },
}));

function Headers({ columnNames, sortConfig, doSort, addFilter }) {
  const [iVisible, showInputs] = useState(false);
  const { classes } = useStyles();
  const theme = useTheme();
  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{
            position: 'sticky',
            top: 0,
            background: theme.palette.grey[50],
            zIndex: 1,
          }}
        />
        {columnNames.map(column => (
          <TableCell
            key={column}
            style={{
              position: 'sticky',
              top: 0,
              background: theme.palette.grey[50],
              padding: '4px',
            }}
          >
            {!massActionListFilterConfigs[column] && (
              <TableSortLabel
                key={column}
                active={sortConfig.orderBy === column}
                direction={sortConfig.order}
                onClick={() => {
                  doSort(column);
                }}
              >
                {columnLabels[column]()}
              </TableSortLabel>
            )}
            {massActionListFilterConfigs[column] && (
              <WithFilterSortLabel
                classes={{ root: classes.root, active: classes.active }}
                visibleInputs={iVisible}
                key={column}
                active={sortConfig.orderBy === column}
                direction={sortConfig.order}
                onClick={() => {
                  doSort(column);
                }}
                column={{ filterConfig: massActionListFilterConfigs[column] }}
                addFilter={addFilter}
                showInput={showInputs}
              >
                {columnLabels[column]()}
              </WithFilterSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Headers.propTypes = {
  columnNames: PropTypes.array.isRequired,
  addFilter: PropTypes.func.isRequired,
  doSort: PropTypes.func.isRequired,
  sortConfig: PropTypes.object.isRequired,
};

export default Headers;
