import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

function WithSteps({ Cmp, initialStep, ...others }) {
  const [state, setState] = useState({ currentStep: initialStep || 0, extraProps: {} });

  const goToStep = useCallback((step, newExtraProps) => {
    setState({ currentStep: step, extraProps: newExtraProps || {} });
  }, []);

  return (
    <Cmp currentStep={state.currentStep} goToStep={goToStep} {...others} {...state.extraProps} />
  );
}

WithSteps.propTypes = {
  initialStep: PropTypes.number || PropTypes.string,
  Cmp: PropTypes.any.isRequired,
};

WithSteps.defaultProps = {
  initialStep: null,
};

export default Cmp => props => <WithSteps {...props} Cmp={Cmp} />;
