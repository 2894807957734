import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch, useLocation, useParams, useRoute } from 'wouter';
import { useSelector } from 'react-redux';
import smartjourneyApi from 'src/utils/api/smartjourneyApi';
import withDataResolver from 'src/components/common/withDataResolver';
import withAlertAndLoader from 'src/components/common/hocs/withAlertAndLoader';
import SMJCampaignList from './campaignlist/CampaignList';
import SMJCampaignEditor, { SMARTJ_CMP_INFOS } from './campaigneditor/CampaignEditor';
import History from '../../campaign/history/History';
import utils from 'src/utils/utils';
import { useCampaignCreator, useCampaignEditor } from 'src/hooks';
import apiClient from 'src/utils/apiClient';
import Loader from 'src/components/common/Loader';

function WESMJCampaignEditor({ doClearData, smartjourney, campaign, reloadData, ...others }) {
  const [, navigate] = useLocation();
  useEffect(
    () => () => {
      doClearData();
      reloadData();
    },
    [doClearData, smartjourney, navigate, reloadData]
  );
  const reduxDataLoaded = useCampaignEditor(campaign);

  if (reduxDataLoaded)
    return (
      <SMJCampaignEditor
        {...others}
        {...{
          doClearData,
          smartjourney,
        }}
        initialStep={SMARTJ_CMP_INFOS}
      />
    );

  return <Loader wrapperClass="ghostWrapperClass" text={utils.getLang('smartmessaging.loading')} />;
}

WESMJCampaignEditor.propTypes = {
  doClearData: PropTypes.func.isRequired,
  smartjourney: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
};

function WESMJCampaignCreator({ doClearData, smartjourney, reloadData, ...others }) {
  const [, navigate] = useLocation();

  useEffect(
    () => () => {
      doClearData();
      reloadData();
    },
    [doClearData, navigate, reloadData]
  );

  const reduxDataLoaded = useCampaignCreator(smartjourney.id);

  if (reduxDataLoaded)
    return (
      <SMJCampaignEditor
        {...others}
        {...{
          doClearData,
          smartjourney,
        }}
        initialStep={SMARTJ_CMP_INFOS}
      />
    );

  return <Loader wrapperClass="ghostWrapperClass" text={utils.getLang('smartmessaging.loading')} />;
}

WESMJCampaignCreator.propTypes = {
  doClearData: PropTypes.func.isRequired,
  smartjourney: PropTypes.object.isRequired,
  reloadData: PropTypes.func.isRequired,
};

function SmartjourneyEditor({
  smartjourney,
  doClearData,
  groupIsWritable,
  campaigns,
  callApi,
  setLoader,
  notify,
  updateCampaign,
  removeCampaign,
  reloadData,
  ...otherProps
}) {
  const [smj, updateSmj] = useState(smartjourney);

  const saveSmj = useCallback(
    pSmj => {
      const asyncFn = async () => {
        setLoader(true);
        await callApi(smartjourneyApi.insertOrUpdateSMJ, [pSmj]).finally(setLoader(false));
        notify({
          message: utils.getLang('smartmessaging.campaigns.grouped.updatedGroup'),
          options: { variant: 'success' },
        });
      };
      asyncFn();
    },
    [callApi, notify, setLoader]
  );

  const updateSmjName = useCallback(
    (name, callback) => {
      const renamed = { ...smj, name };
      const asyncFn = async () => {
        await saveSmj(renamed);
        updateSmj(renamed);
        if (callback) callback(renamed);
      };
      asyncFn();
    },
    [smj, saveSmj]
  );

  const [partialRoute, partialRouteParams] = useRoute('/:id');
  if (partialRoute && /^\d+$/.test(partialRouteParams.id)) {
    return <Redirect to={`/${partialRouteParams.id}/edit`} />;
  }

  return (
    <Switch>
      <Route path="/list">
        <SMJCampaignList
          {...otherProps}
          groupIsWritable={groupIsWritable}
          campaigns={campaigns}
          smartjourney={smj}
          updateCampaign={updateCampaign}
          removeCampaign={removeCampaign}
          updateSmjName={updateSmjName}
        />
      </Route>
      <Route path="/:cmpId/edit">
        {cmpparams => (
          <WESMJCampaignEditor
            {...otherProps}
            reloadData={reloadData}
            campaign={campaigns.filter(c => c.id === parseInt(cmpparams.cmpId, 10))[0]}
            smartjourney={smj}
            updateCampaign={updateCampaign}
            doClearData={doClearData}
          />
        )}
      </Route>
      <Route path="/create">
        <WESMJCampaignCreator
          {...otherProps}
          reloadData={reloadData}
          smartjourney={smj}
          updateCampaign={updateCampaign}
          doClearData={doClearData}
        />
      </Route>
      <Route path="/:id/history">
        {cmpparams => (
          <History
            {...otherProps}
            pCampaign={campaigns.filter(c => c.id === parseInt(cmpparams.id, 10))[0]}
          />
        )}
      </Route>
    </Switch>
  );
}

SmartjourneyEditor.propTypes = {
  updateCampaign: PropTypes.func.isRequired,
  removeCampaign: PropTypes.func.isRequired,
  smartjourney: PropTypes.object.isRequired,
  doClearData: PropTypes.func.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  campaigns: PropTypes.array.isRequired,
  callApi: PropTypes.func.isRequired,
  setLoader: PropTypes.func.isRequired,
  notify: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

const SmartjourneyEditorDataResolver = withDataResolver({
  resolve: props => async callApi => {
    const groupIsWritable = await callApi(smartjourneyApi.canWriteOnGroup, [props.smartjourney.id]);
    const campaigns = await callApi(smartjourneyApi.loadSMJCampaigns, [props.smartjourney.id]);

    const recipes = await callApi(apiClient.loadRecipes);
    props.receiveRecipes(recipes);

    const mergedCampaigns = campaigns.map(cmp => {
      const r = recipes.filter(pr => pr.id === cmp.recipeId)[0];
      const rmTypeId = r.requestModelTypeId;
      return { ...cmp, duplicates: JSON.parse(cmp.duplicates), requestModelTypeId: rmTypeId };
    });
    return { groupIsWritable, campaigns: mergedCampaigns };
  },
  loader: (
    <Loader
      wrapperClass="ghostWrapperClass"
      text={utils.getLang('smartmessaging.campaigns.mainDataLoading')}
    />
  ),
  onResolved: () => async () => {},
})(({ data, setData, update, ...props }) => (
  <SmartjourneyEditor
    {...props}
    groupIsWritable={data.groupIsWritable}
    campaigns={data.campaigns}
    updateCampaign={upToDateCmp => {
      setData({
        ...data,
        campaigns: data.campaigns.map(cmp =>
          cmp.id === upToDateCmp.id ? { ...cmp, ...upToDateCmp } : cmp
        ),
      });
    }}
    removeCampaign={cmpToRemove => {
      setData(data.campaigns.filter(cmp => cmp.id !== cmpToRemove.id));
    }}
    reloadData={update}
  />
));

const Container = props => {
  const params = useParams();

  const smartjourney = useSelector(
    state => state.smartjourney.smjListsByModel.filter(smj => smj.id === Number(params.id))[0]
  );
  return (
    Boolean(smartjourney) && (
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'hidden',
          textAlign: 'left',
          height: '100%',
        }}
      >
        <SmartjourneyEditorDataResolver {...props} smartjourney={smartjourney} />
      </div>
    )
  );
};

export default withAlertAndLoader(Container);
