import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@mui/material';
import ClubParameterList from './ClubParameterList';
import SmartMessagingConfig from './SmartMessagingConfig';

import ParametersAppBar from './ParametersAppBar';
import utils from 'src/utils/utils';
import { Redirect, Route, Switch } from 'wouter';

const ParametersContent = ({ paramsMap }) => (
  <div
    style={{
      height: '100vh',
      flexDirection: 'column',
      display: 'flex',
    }}
  >
    <div style={{ display: 'flex', padding: '10px', height: '50px' }}>
      <Typography variant="h5" color="textSecondary" style={{ margin: 'auto 16px' }}>
        {utils.getLang('smartmessaging.config.mainTitle')}
      </Typography>
    </div>
    <Divider />

    <div
      style={{
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Switch>
        <Route path="/params/list">
          {() => {
            if (Object.keys(paramsMap).length > 1)
              return (
                <>
                  <ParametersAppBar />
                  <ClubParameterList paramsMap={paramsMap} />
                </>
              );
            return <Redirect to={`/params/edit/${Object.values(paramsMap)[0].clubId}`} />;
          }}
        </Route>
        <Route path="/params/edit/:id">
          {params => (
            <>
              <ParametersAppBar currentClubId={params.id} />
              <SmartMessagingConfig smartmParams={paramsMap[params.id]} />
            </>
          )}
        </Route>
      </Switch>
    </div>
  </div>
);
ParametersContent.propTypes = {
  paramsMap: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  paramsMap: state.parameters.clubParams,
});

export default connect(mapStateToProps)(ParametersContent);
