import { createReducer } from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../notify';
import getApiCaller from 'src/utils/apiClientCaller';
import massActionApi from 'src/utils/api/massActionApi';
import apiClient from 'src/utils/apiClient';
import massActionSelectors from 'src/selectors/massAction';
import massActionUtils from './utils';
import contentEditorUtils from 'src/components/common/contentEditor/utils/contentEditorUtils'; // /utils/contentEditorUtils';

import paramSelectors from 'src/selectors/parameters';
import utils from 'src/utils/utils';

import { loaderActions } from '../loader';
import ActionType from 'src/enums/actionType';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAlert } from 'src/hooks';
import { massActionSelector } from 'src/selectors/massAction/massAction';

const initialState = {
  currentStep: 0,
  massActions: [],
  availableCriterias: {},
  selectedCriterias: {},
  availableResultfields: {},
  selectedResultfields: {},
  mandatoryCriterias: [],
  currentSetupId: null,
  currentRecipeId: null,
  currentMassActionId: null,
  currentCreationDate: null,
  currentMessageId: null,
  requestModel: null,
  currentName: '',
  currentCampaignAction: null,
  currentMassActionInitialData: null,
  defaultContentFields: {},
  forbiddenContentFields: [],
  selectedDiffusionType: null,
  editor: null,
  objectEditor: null,
  contentHasChanges: false,
  dataLoadingChangeEventHandled: 0,
  forceIsNewContent: false,
  isWritable: true,
};

const onStepChange = (state, { value }) => ({ ...state, currentStep: value });

export const initData = showAlert => async (dispatch, getState) => {
  const apiCaller = getApiCaller(showAlert)(dispatch, getState);
  const massActionRM = await apiCaller(massActionApi.getMassActionRequestModel);

  dispatch({ type: 'RECEIVE_MASSACTION_REQUESTMODEL', value: massActionRM });

  const criterias = await apiCaller(apiClient.loadRecipeParameters, [massActionRM.id]);
  dispatch({ type: 'RECEIVE_AVAILABLE_CRITERIA', value: criterias });

  const defaultContentFields = await apiCaller(massActionApi.getContentDefaultFields);
  dispatch({ type: 'RECEIVE_DEFAULT_CONTENT_FIELDS', value: defaultContentFields });

  const forbiddenContentFields = await apiCaller(massActionApi.getContentForbiddenFields);
  dispatch({ type: 'RECEIVE_FORBIDDEN_CONTENT_FIELDS', value: forbiddenContentFields });
};

export const editMassAction = (massAction, showAlert) => async (dispatch, getState) => {
  // load datas in the redux state
  const apiCaller = getApiCaller(showAlert)(dispatch, getState);
  const isWritable = await apiCaller(apiClient.campaignIsWritable, [massAction.id]);
  dispatch({ type: 'SET_WRITABLE', value: isWritable });
  dispatch({ type: 'UPDATE_MASSACTION_ID', value: massAction.id });
  dispatch({ type: 'UPDATE_ACTION_NAME', value: massAction.name || '' });
  dispatch({ type: 'UPDATE_CURRENT_MASSACTION_INITIALDATA', value: massAction });
  dispatch({ type: 'INIT_REPLAY_AND_BYPASS', value: massAction });

  dispatch({ type: 'UPDATE_ACTION_CREATION_DATE', value: massAction.creationDate || '' });

  const recipe = await apiCaller(apiClient.getRecipe, [massAction.recipeId]);
  dispatch({ type: 'UPDATE_MASSACTION_RECIPE_ID', value: recipe.id });

  const setup = await apiCaller(apiClient.getSetup, [recipe.setupId]);
  dispatch({ type: 'UPDATE_MASSACTION_SETUP_ID', value: setup.id });

  const { criteriaByKey } = getState().massAction;
  dispatch({
    type: 'RECEIVE_SETUP_VALUE',
    value: massActionUtils.getSelectedCriteriasFromValue(setup.value, criteriaByKey),
  });

  const { availableResultfields } = getState().massAction;
  dispatch({
    type: 'RECEIVE_SETUP_COLUMNS',
    value: massActionUtils.getSelectedColumnsFromList(
      JSON.parse(setup.columnList),
      availableResultfields
    ),
  });
};

export function useCampaignEditor(campaignToEditId) {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();
  const { showAlert } = useAlert();
  const campaignToEdit = useSelector(massActionSelector(campaignToEditId));
  useEffect(() => {
    dispatch(editMassAction(campaignToEdit, conf => showAlert(conf)));
    setLoaded(true);
  }, [campaignToEdit, dispatch, setLoaded, showAlert]);

  return loaded;
}

export const enableAction = (massAction, showAlert) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const isWritable = await callApi(apiClient.campaignIsWritable, [massAction.id]);
  const asyncFn = async () => {
    setLoader(true);
    const activationResponse = await callApi(apiClient.activateCampaign, [
      massAction.id,
      !massAction.enabled,
    ]);

    if (activationResponse.isSuccess) {
      dispatch(
        enqueueSnackbar({
          message: `${massAction.name}: ${
            !massAction.enabled
              ? utils.getLang('smartmessaging.notifications.enabledCampaign')
              : utils.getLang('smartmessaging.notifications.disabledCampaign')
          }`,

          options: {
            variant: massAction.enabled ? 'warning' : 'success',
          },
        })
      );
      const upToDateList = [...getState().massAction.massActions].map(ma => {
        if (ma.id === massAction.id) {
          return { ...massAction, enabled: !massAction.enabled };
        }
        return ma;
      });

      dispatch({ type: 'RECEIVE_MASSACTIONS', value: upToDateList });
    } else {
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.errorMessages.massAction.notActivable.title'),
        msg: utils.getLang(
          (activationResponse.error &&
            `smartmessaging.errorMessages.massAction.notActivable.${activationResponse.error}`) ||
            'smartmessaging.errorMessages.massAction.notActivable.message'
        ),
      });
    }
  };
  if (isWritable)
    await asyncFn().finally(() => {
      setLoader(false);
    });
  else
    showAlert({
      type: 'warning',
      title: utils.getLang('smartmessaging.campaigns.missingRightAlertTitle'),
      msg: utils.getLang('smartmessaging.campaigns.changeNotAllowed'),
    });
};

export const saveMassAction = showAlert => async (dispatch, getState) => {
  const apiCaller = getApiCaller(showAlert)(dispatch, getState);

  const getCurrentMassActionState = () => getState().massAction;

  const { currentMassActionId, currentSetupId, currentRecipeId } = getCurrentMassActionState();

  const setup = massActionUtils.getSetup(getCurrentMassActionState());
  const setupId = await apiCaller(massActionApi.saveMassActionSetup, [setup]);
  if (!currentSetupId) dispatch({ type: 'UPDATE_MASSACTION_SETUP_ID', value: setupId }); // updates the currentSetupId (currently being edited) in  massAction state

  const recipe = massActionUtils.getRecipe(getCurrentMassActionState());
  const recipeId = await apiCaller(massActionApi.saveMassActionRecipe, [recipe]);
  if (!currentRecipeId) dispatch({ type: 'UPDATE_MASSACTION_RECIPE_ID', value: recipeId }); // updates the currentRecipeId in  massAction state

  let massAction;
  if (currentMassActionId) {
    const massActionState = getCurrentMassActionState();
    const existingMassAction = massActionState.massActions.filter(
      ma => ma.id === currentMassActionId
    )[0];

    massAction = {
      ...existingMassAction,
      ...massActionUtils.getMassActionCampaign(massActionState),
    };
  } else {
    massAction = massActionUtils.getMassActionCampaign(getCurrentMassActionState());
  }

  // this only returns { id, recipeId,  name, creationDate, enabled }
  const upToDateMassAction = await apiCaller(massActionApi.saveMassAction, [
    { ...massAction, eventDriven: false },
  ]);

  if (!currentMassActionId)
    dispatch({ type: 'UPDATE_MASSACTION_ID', value: upToDateMassAction.id });

  const matches = getCurrentMassActionState().massActions.filter(
    ma => ma.id === upToDateMassAction.id
  );

  // TODO BUG remove duplicate warning on back to list after edit
  if (!matches.length)
    dispatch({
      type: 'RECEIVE_MASSACTIONS',
      value: [...getCurrentMassActionState().massActions].concat({
        ...upToDateMassAction,
        duplicates: JSON.parse(upToDateMassAction.duplicates),
      }),
    });
  else
    dispatch({
      type: 'RECEIVE_MASSACTIONS',
      value: getCurrentMassActionState().massActions.map(ma =>
        ma.id === upToDateMassAction.id
          ? {
              ...upToDateMassAction,
              duplicates: JSON.parse(upToDateMassAction.duplicates),
            }
          : ma
      ),
    });
  dispatch({
    type: 'UPDATE_CURRENT_MASSACTION_INITIALDATA',
    value: upToDateMassAction,
  });
};

export const checkEmptyFieldSelection = (callback, save) => async (dispatch, getState) => {
  const isEmpty = massActionSelectors.getUngroupedSelectedColumns(getState()).length === 0;
  if (isEmpty) {
    dispatch({
      type: 'UPDATE_RESULTFIELD_SELECTION',
      value: { contact: [massActionSelectors.getContactIdResultfield(getState())] },
    });
    if (save) await dispatch(saveMassAction);
  }
  callback();
};

// controle de la présence de champs dans le contenu qui ne figurent pas dans les colonnes sélectionnées
// update la campagne sur confirmation si des champs présents dans le contenu ne sont pas sélectionnés
export const checkContentFieldsAndSelectedColumns = (
  content,
  actionTypeId,
  callback,
  showAlert
) => async (dispatch, getState) => {
  const selectedFields = getState().massAction.selectedResultfields;
  const params = paramSelectors.getCurrentParams(getState());
  const isArray = Array.isArray(content);
  const freemarkerToHtml =
    actionTypeId === ActionType.SMS.id
      ? contentEditorUtils.smsFreemarkerToHtml
      : contentEditorUtils.freemarkerToHtml;
  const fields = contentEditorUtils.processContentFields(
    massActionSelectors.getContentFields(getState()),
    actionTypeId,
    {
      headerFileId: params.headerStoredfileId || null,
      footerFileId: params.footerStoredfileId || null,
    }
  );
  const validity = massActionUtils.getContentValidity({
    actionType: actionTypeId,
    object: isArray ? freemarkerToHtml(content[0], fields.fieldsModels, params) || '' : null, //  .objectEditor && state.objectEditor.getData(),
    content: isArray
      ? freemarkerToHtml(content[1], fields.fieldsModels, params) || ''
      : freemarkerToHtml(content, fields.fieldsModels, params),

    min:
      actionTypeId === ActionType.SMS.id &&
      getState().massAction.selectedDiffusionType === 'advertising'
        ? getState().app.stopSmsText.length
        : 0,
  });
  dispatch({
    type: 'UPDATE_CONTENT_VALIDITY',
    value: validity,
  });

  const fieldsInContent = []
    .concat(content) // --> so content can be an array or a single string in arguments
    .reduce((feildList, ctn) => feildList.concat(contentEditorUtils.getContentUsedField(ctn)), []);

  if (fieldsInContent.length) {
    const missingFields = utils.arrayDiff(
      fieldsInContent,
      massActionSelectors.getUngroupedSelectedColumns(getState())
    );

    if (missingFields.length) {
      // the reducefn that will be used to merge new fields to add and already selectedFields
      const fusionReducer = (fullSelection, [groupName, groupFields]) => {
        if (fullSelection[groupName]) {
          return {
            ...fullSelection,
            [groupName]: [...fullSelection[groupName]].concat(groupFields),
          };
        }
        return {
          ...fullSelection,
          [groupName]: groupFields,
        };
      };
      // the alert confirm callback
      const doConfirm = async () => {
        // prepare fields to be added : add to selection from field string list, like if it where from the setup
        const fieldsToAdd = {
          ...massActionUtils.getSelectedColumnsFromList(
            missingFields,
            massActionSelectors.getAvailableResultfields(getState())
          ),
        };

        dispatch({
          type: 'UPDATE_RESULTFIELD_SELECTION',
          value: Object.entries(fieldsToAdd).reduce(fusionReducer, selectedFields),
        });

        showAlert(null);

        await dispatch(saveMassAction(showAlert)); // all new data is set into state so we trigger this action to update remote campaign data
        callback(massActionSelectors.getContentFields(getState()));
      };

      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.errorMessages.warning.title'),
        msg: `${utils.getLang(
          'smartmessaging.campaignEditor.confirmAutoAddColumns'
        )} : ${missingFields
          .map(mf => utils.getLang(`smartmessaging.resultfield.${mf}`))
          .join(', ')}`,
        onConfirm: doConfirm,
        onDismiss() {
          showAlert(null);
        },
      });
    } else {
      callback(massActionSelectors.getContentFields(getState()));
    }
  } else {
    callback(massActionSelectors.getContentFields(getState()));
  }
};

const receiveAvailableCriterias = (state, { value }) => ({
  ...state,
  availableCriterias: massActionUtils.mapAvailableCriterias(value),
  mandatoryCriterias: massActionUtils.mapMandatoryCriterias(value),
  criteriaByKey: massActionUtils.mapCriteriasByKey(value),
});

const addCriteriaToSelection = (state, { value }) => ({
  ...state,
  selectedCriterias: massActionUtils.addSelectedCriteria(value, state),
});

const onCriteriaValueChange = (state, { value }) => ({
  ...state,
  selectedCriterias: massActionUtils.changeCriteriaValue(
    value,
    massActionSelectors.getSelectedCriterias(state)
  ),
});

const onCriteriaOperatorValueChange = (state, { value }) => ({
  ...state,
  selectedCriterias: massActionUtils.changeOperatorValue(
    value,
    massActionSelectors.getSelectedCriterias(state)
  ),
});

const removeSelectedCriteria = (state, { value }) => ({
  ...state,
  selectedCriterias: massActionUtils.removeCriteria(
    value,
    massActionSelectors.getSelectedCriterias(state)
  ),
});

export const loadAvailableResultfields = showAlert => async (dispatch, getState) => {
  const apiCaller = getApiCaller(showAlert)(dispatch, getState);
  const resultFields = await apiCaller(massActionApi.getAvailableResultfields);
  dispatch({ type: 'RECEIVE_AVAILABLE_RESULTFIELDS', value: resultFields });
};

export const loadMassActions = showAlert => async (dispatch, getState) => {
  const apiCaller = getApiCaller(showAlert)(dispatch, getState);
  const massActions = await apiCaller(massActionApi.getMassActions);

  dispatch({
    type: 'RECEIVE_MASSACTIONS',
    value: massActions.map(c => ({ ...c, duplicates: JSON.parse(c.duplicates) })),
  });
};

export const sendContent = (actionTypeId, callback, showAlert) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  const {
    currentMassActionId,
    selectedDiffusionType,
    currentRecipeId,
    contentValidity,
  } = getState().massAction;

  const asyncFn = async targets => {
    setLoader(true);
    const success = await callApi(massActionApi.sendContent, [
      currentMassActionId,
      selectedDiffusionType,
      actionTypeId,
      targets,
    ]);
    return success;
  };

  const doSend = async targets => {
    await asyncFn(targets)
      .then(response => {
        setLoader(false);
        if (response === true)
          showAlert({
            title: utils.getLang('smartmessaging.diffusionSuccess.title'),
            msg: utils.getLang('smartmessaging.diffusionSuccess.message'),
          });
        else
          showAlert({
            title: utils.getLang('smartmessaging.diffusionFailure.title'),
            msg: utils.getLang('smartmessaging.diffusionFailure.message'),
          });
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const doProceed = async () => {
    setLoader(true);
    const targets = await callApi(massActionApi.actionCoTargetNumber, [
      currentRecipeId,
      selectedDiffusionType,
      actionTypeId,
    ]).finally(() => setLoader(false));

    let confirmMsg = `${utils.getLang('smartmessaging.diffusionConfirm.message')} : ${targets}`;
    if (actionTypeId === ActionType.SMS.id) {
      const estimatedLength = contentValidity.gsm7BitValidation.numOfPart || 1;
      confirmMsg += `<br/>${utils.getLang(
        'smartmessaging.diffusionConfirm.smsPerTarget'
      )} : ${estimatedLength}`;
      confirmMsg += utils.stringFormat(
        `<br/>${utils.getLang('smartmessaging.diffusionConfirm.totalSmsToSend')}`,
        [estimatedLength * targets]
      );
    }

    showAlert({
      title: utils.getLang('smartmessaging.diffusionConfirm.title'),
      msg: confirmMsg,
      onConfirm: async () => {
        await doSend(targets);
        callback();
      },
      onDismiss() {
        showAlert(null);
      },
    });
  };

  const businessContentAlertCfg = { alert: false, messages: [] };
  if (!contentValidity.hasOptoutLink && actionTypeId === ActionType.EMAIL.id) {
    businessContentAlertCfg.alert = true;
    businessContentAlertCfg.messages.push(
      utils.getLang('smartmessaging.contentEditor.mail.optoutLinkMissing')
    );
  }
  if (['legal', 'news'].indexOf(selectedDiffusionType) !== -1) {
    businessContentAlertCfg.alert = true;
    businessContentAlertCfg.messages.push(
      utils.stringFormat(utils.getLang('smartmessaging.contentEditor.marketingWarningContent'), [
        utils.getLang(`smartmessaging.diffusionType.${selectedDiffusionType}`),
      ])
    );
  }

  if (businessContentAlertCfg.alert) {
    showAlert({
      title: utils.getLang('smartmessaging.diffusionConfirm.title'),
      msg: `<ul>${businessContentAlertCfg.messages.map(msg => `<li>${msg}</li>`).join()}</ul>`,
      onConfirm: () => doProceed(),
      onDismiss() {
        showAlert(null);
      },
    });
  } else doProceed();
};

export const saveContent = (actionType, showAlert) => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const { massAction } = getState();

  const { currentCampaignAction, editor, objectEditor } = massAction;
  async function saveEmail(isMemberNotification) {
    const storedFileId = await callApi(apiClient.upload, [
      contentEditorUtils.htmlToFreemarker(null, editor, !isMemberNotification),
      'text/html',
    ]);

    const message = await callApi(apiClient.insertOrUpdateMessage, [
      {
        id: (currentCampaignAction && currentCampaignAction.messageId) || null,
        contentStoredFileId: storedFileId,
        object: contentEditorUtils.htmlToFreemarker(objectEditor.getData()),
      },
    ]);

    const upToDateCampaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
      {
        id: (currentCampaignAction && currentCampaignAction.id) || null,
        actionTypeId: isMemberNotification
          ? ActionType.MEMBER_NOTIFICATION.id
          : ActionType.EMAIL.id,
        campaignId: massAction.currentMassActionId,
        messageId: message.id,
        language: currentCampaignAction.language,
      },
    ]);
    return upToDateCampaignAction;
  }

  async function saveSMS() {
    const storedFileId = await callApi(apiClient.upload, [
      contentEditorUtils.htmlToFreemarkerSms(editor.getData()),
      'text/html',
    ]);

    const message = await callApi(apiClient.insertOrUpdateMessage, [
      {
        id: (currentCampaignAction && currentCampaignAction.messageId) || null,
        contentStoredFileId: storedFileId,
      },
    ]);

    const upToDateCampaignAction = await callApi(apiClient.insertOrUpdateCampaignAction, [
      {
        id: (currentCampaignAction && currentCampaignAction.id) || null,
        actionTypeId: ActionType.SMS.id,
        campaignId: massAction.currentMassActionId,
        messageId: message.id,
        language: currentCampaignAction.language,
      },
    ]);
    return upToDateCampaignAction;
  }

  let upToDateCmpAction = null;
  switch (actionType) {
    case ActionType.SMS.id:
      upToDateCmpAction = await saveSMS();
      break;
    case ActionType.EMAIL.id:
    case ActionType.MEMBER_NOTIFICATION.id:
      upToDateCmpAction = await saveEmail(ActionType.MEMBER_NOTIFICATION.id === actionType);
      break;
    default:
      break;
  }
  dispatch({ type: 'UPDATE_CURRENT_CMP_ACTION', value: upToDateCmpAction });
  dispatch({ type: 'RESET_CONTENT_HAS_CHANGES' });
  dispatch({ type: 'SET_FORCE_IS_NEW_CONTENT', value: false });
};

export const massActionCsvExport = showAlert => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const { currentRecipeId } = getState().massAction;
  setLoader(true);
  const exportFileId = await callApi(massActionApi.massActionCsvExport, [currentRecipeId]);

  if (exportFileId && exportFileId !== -1) {
    await utils.downloadPrivateStoredFile(exportFileId, callApi);
    setLoader(false);
  } else {
    setLoader(false);
    showAlert({
      msg: utils.getLang('smartmessaging.noData'),
    });
  }
};

export const validateMassActionName = (callBack, showAlert) => async (dispatch, getState) => {
  const massActionState = getState().massAction;
  let nameIsValid = massActionSelectors.nameIsValid(getState());

  if (nameIsValid) {
    let nameIsDuplicate = false;
    const testDuplicateName =
      !massActionState.currentMassActionInitialData ||
      !(massActionState.currentMassActionInitialData.name === massActionState.currentName);
    const callApi = getApiCaller(showAlert)(dispatch, getState);

    if (testDuplicateName)
      nameIsDuplicate = await callApi(apiClient.checkDuplicateName, [massActionState.currentName]);

    if (nameIsDuplicate) {
      nameIsValid = false;
      showAlert({
        type: 'warning',
        title: utils.getLang('smartmessaging.errorMessages.warning.title'),
        msg: utils.getLang('smartmessaging.errorMessages.campaign.duplicateName.message'),
      });
    }
    if (nameIsValid && callBack) callBack();
  }
};

const updateResultfieldSelection = (state, { value }) => ({
  ...state,
  selectedResultfields: value,
});

const receiveAvailableResultfields = (state, { value }) => ({
  ...state,
  availableResultfields: massActionUtils.mapAvailableResultfields(value),
});

const receiveMassActions = (state, { value }) => ({
  ...state,
  massActions: value,
});

const receiveSetupValue = (state, { value }) => ({
  ...state,
  selectedCriterias: value,
});

const receiveMassActionRequestModel = (state, { value }) => ({
  ...state,
  requestModel: value,
});

const updateSetupId = (state, { value }) => ({
  ...state,
  currentSetupId: value,
});

const updateRecipeId = (state, { value }) => ({
  ...state,
  currentRecipeId: value,
});

const updateMassActionId = (state, { value }) => ({
  ...state,
  currentMassActionId: value,
});

const updateContentValidity = (state, { value }) => ({
  ...state,
  contentValidity: value,
});

const receiveSetupColumns = (state, { value }) => ({
  ...state,
  selectedResultfields: value,
});

const receiveDefaultContentFields = (state, { value }) => ({
  ...state,
  defaultContentFields: value,
});

const receiveForbiddenContentFields = (state, { value }) => ({
  ...state,
  forbiddenContentFields: value,
});

const clearEditedData = state => ({
  ...state,
  selectedCriterias: massActionUtils.addAllMandatories(state),
  selectedResultfields: {},
  currentMassActionInitialData: null,
  currentMassActionId: null,
  currentCreationDate: null,
  currentRecipeId: null,
  currentSetupId: null,
  currentName: '',
  currentCampaignAction: null,
  selectedDiffusionType: null,
  editor: null,
  objectEditor: null,
  contentHasChanges: false,
  dataLoadingChangeEventHandled: 0,
  isWritable: true,
  currentReplay: null,
  currentByPass: null,
});

const updateMassActionName = (state, { value }) => ({
  ...state,
  currentName: value,
});

const updateCurrentMassActionInitialData = (state, { value }) => ({
  ...state,
  currentMassActionInitialData: value,
});

const updateMassActionCreationDate = (state, { value }) => ({
  ...state,
  currentCreationDate: value,
});

const updateCurrentCmpAction = (state, { value }) => ({
  ...state,
  currentCampaignAction: value,
});

const onContentLangChange = (state, { value }) => ({
  ...state,
  currentCampaignAction: { ...state.currentCampaignAction, language: value },
  contentHasChanges: true,
});

const setDiffusionType = (state, { value }) => ({
  ...state,
  selectedDiffusionType: value.diffusionType,
  contentValidity: massActionUtils.getContentValidity({
    actionType: value.actionType,
    object: state.objectEditor && state.objectEditor.getData(),
    content: state.editor.getData(),
    min: value.diffusionType === 'advertising' ? value.stopSmsText.length : 0,
  }),
});

const selectDefaultCriterias = state => ({
  ...state,
  selectedCriterias: massActionUtils.addAllMandatories(state),
});

const onContentChange = (state, { value }) => ({
  ...state,
  contentValidity: massActionUtils.getContentValidity({
    actionType: value.actionType,
    object: state.objectEditor && state.objectEditor.getData(),
    content: state.editor.getData(),
    min: state.selectedDiffusionType === 'advertising' ? value.stopSmsText.length : 0,
  }),
  contentHasChanges: true,
});

const handleDataLoadingChange = state => ({
  ...state,
  dataLoadingChangeEventHandled: state.dataLoadingChangeEventHandled + 1,
});

export const handleContentChange = actionType => (dispatch, getState) => {
  if (!massActionSelectors.getDataLoadingChangeEventHandled(getState())) {
    // trick to avoid change event on data setting
    dispatch({ type: 'HANDLE_DATA_LOADING_CHANGE' });
  } else {
    dispatch({
      type: 'ON_MASS_ACTION_CONTENT_CHANGE',
      value: { actionType, stopSmsText: getState().app.config.stopSmsText },
    });
  }
};

export const resetContentEditorFlowData = () => dispatch => {
  dispatch({ type: 'RESET_CONTENT_HAS_CHANGES' });
  dispatch({ type: 'RESET_CHANGE_EVENT_HANDLED' });
};

export const deleteCmpAction = (cmpActionId, callback, showAlert) => (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };

  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const doDelete = async id => {
    setLoader(true);
    await callApi(apiClient.deleteCampaignAction, [id]).finally(() => {
      setLoader(false);
    });
    if (callback) callback();
    dispatch(
      enqueueSnackbar({
        message: utils.getLang('smartmessaging.notifications.deletedContent'),
        options: {
          variant: 'warning',
        },
      })
    );
  };

  showAlert({
    type: 'warning',
    title: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaignAction.title'),
    msg: utils.getLang('smartmessaging.campaignEditor.confirmDeleteCampaignAction'),
    onConfirm() {
      showAlert(null);
      doDelete(cmpActionId).finally(() => setLoader(false));
    },
    onDismiss() {
      showAlert(null);
    },
  });
};

const onObjectEditorLoaded = (state, { value }) => ({
  ...state,
  objectEditor: value,
});

const onEditorLoaded = (state, { value }) => ({
  ...state,
  editor: value,
});

const resetContentHaschanges = state => ({
  ...state,
  contentHasChanges: false,
});

const resetChangeEventHandled = state => ({
  ...state,
  dataLoadingChangeEventHandled: 0,
});

const setForceIsNewContent = (state, { value }) => ({
  ...state,
  forceIsNewContent: !!value,
});

const addSelectedResultFields = (state, { value }) => ({
  ...state,
  selectedResultfields: {
    ...state.selectedResultfields,
    [value.group]: state.selectedResultfields[value.group]
      ? [...state.selectedResultfields[value.group], ...value.fields]
      : value.fields,
  },
});
const removeSelectedResultFields = (state, { value }) => ({
  ...state,
  selectedResultfields: {
    ...state.selectedResultfields,
    [value.group]: state.selectedResultfields[value.group].filter(
      selectedField => value.fields.map(f => f.value).indexOf(selectedField.value) === -1
    ),
  },
});

const setGroupSelection = (state, { value }) => ({
  ...state,
  selectedResultfields: {
    ...state.selectedResultfields,
    [value.group]: value.fields,
  },
});

const setIsWritable = (state, { value }) => ({
  ...state,
  isWritable: value,
});
const updateReplay = (state, { value }) => ({
  ...state,
  currentReplay: value.replay,
  currentByPass: value.replay && value.byPass ? false : value.byPass,
});

const updateBypass = (state, { value }) => ({
  ...state,
  currentByPass: value.byPass,
  currentReplay: value.byPass && value.replay ? false : value.replay,
});
const initReplayAndBypass = (state, { value }) => ({
  ...state,
  currentReplay: !!value.replay,
  currentByPass: !!value.byPass,
});

export default createReducer(initialState, {
  ADD_CRITERIA_TO_SELECTION: addCriteriaToSelection,
  CLEAR_EDITED_DATA: clearEditedData,
  ON_MASS_ACTION_STEP_CHANGE: onStepChange,
  ON_CRITERIA_VALUE_CHANGE: onCriteriaValueChange,
  ON_CRITERIA_OPERATOR_VALUE_CHANGE: onCriteriaOperatorValueChange,
  RECEIVE_AVAILABLE_CRITERIA: receiveAvailableCriterias,
  RECEIVE_SETUP_COLUMNS: receiveSetupColumns,
  RECEIVE_MASSACTIONS: receiveMassActions,
  RECEIVE_MASSACTION_REQUESTMODEL: receiveMassActionRequestModel,
  RECEIVE_SETUP_VALUE: receiveSetupValue,
  RECEIVE_AVAILABLE_RESULTFIELDS: receiveAvailableResultfields,
  RECEIVE_DEFAULT_CONTENT_FIELDS: receiveDefaultContentFields,
  RECEIVE_FORBIDDEN_CONTENT_FIELDS: receiveForbiddenContentFields,
  REMOVE_CRITERIA_FROM_SELECTION: removeSelectedCriteria,
  UPDATE_RESULTFIELD_SELECTION: updateResultfieldSelection,
  UPDATE_MASSACTION_SETUP_ID: updateSetupId,
  UPDATE_MASSACTION_RECIPE_ID: updateRecipeId,
  UPDATE_MASSACTION_ID: updateMassActionId,
  UPDATE_ACTION_NAME: updateMassActionName,
  UPDATE_ACTION_CREATION_DATE: updateMassActionCreationDate,
  UPDATE_CURRENT_CMP_ACTION: updateCurrentCmpAction,
  UPDATE_CURRENT_MASSACTION_INITIALDATA: updateCurrentMassActionInitialData,
  UPDATE_CONTENT_VALIDITY: updateContentValidity,
  SET_DIFFUSION_TYPE: setDiffusionType,
  SELECT_DEFAULT_CRITERIAS: selectDefaultCriterias,
  RESET_CONTENT_HAS_CHANGES: resetContentHaschanges,
  RESET_CHANGE_EVENT_HANDLED: resetChangeEventHandled,
  ON_MASS_ACTION_CONTENT_CHANGE: onContentChange,
  HANDLE_DATA_LOADING_CHANGE: handleDataLoadingChange,
  ON_MASS_ACTION_MAIL_OBJECT_EDITOR_LOADED: onObjectEditorLoaded,
  ON_MASS_ACTION_MAIL_CONTENT_EDITOR_LOADED: onEditorLoaded,
  SET_FORCE_IS_NEW_CONTENT: setForceIsNewContent,
  ADD_RESULTFIELD_SELECTION: addSelectedResultFields,
  REMOVE_RESULTFIELD_SELECTION: removeSelectedResultFields,
  SET_GROUP_RESULTFIELD_SELECTION: setGroupSelection,
  SET_WRITABLE: setIsWritable,
  UPDATE_BYPASS: updateBypass,
  UPDATE_REPLAY: updateReplay,
  INIT_REPLAY_AND_BYPASS: initReplayAndBypass,
  ON_MASSACTION_CONTENT_LANG_CHANGE: onContentLangChange,
});
