import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import History from './History';
import Details from './Details';

import { loaderActions } from 'src/reducers/loader';
import getApiCaller from 'src/utils/apiClientCaller';
import statsApi from 'src/utils/api/stats';
import utils from 'src/utils/utils';
import { useAlert } from 'src/hooks';

export const [CMP_HISTORY_MAIN, CMP_HISTORY_DETAIL] = ['auto_history', 'auto_histoy_detail'];

function HistoryPage({ setLoader, doGetApiCaller, components, lang, ...otherProps }) {
  const [currentStep, goToHistoryStep] = useState(CMP_HISTORY_MAIN);
  const [extraProps, setExtraProps] = useState({});
  const { showAlert } = useAlert();
  const enhancedGoToHistoryStep = useCallback((step, newExtraProps) => {
    setExtraProps(newExtraProps || {});
    goToHistoryStep(step);
  }, []);

  const exportHistory = useCallback(
    async campaignId => {
      const callApi = doGetApiCaller(showAlert);
      setLoader(true);
      const exportFileId = await callApi(statsApi.exportHistory, [campaignId, lang]).finally(() =>
        setLoader(false)
      );

      if (exportFileId && exportFileId !== -1) {
        await utils.downloadPrivateStoredFile(exportFileId, callApi);
        setLoader(false);
      } else {
        setLoader(false);
        showAlert({ msg: utils.getLang('smartmessaging.noData') });
      }
    },
    [doGetApiCaller, lang, setLoader, showAlert]
  );

  const exportExecutionResult = useCallback(
    async (execId, atId) => {
      const callApi = doGetApiCaller(showAlert);
      setLoader(true);
      const exportFileId = await callApi(statsApi.exportExecutionResult, [
        execId,
        atId,
      ]).finally(() => setLoader(false));

      if (exportFileId && exportFileId !== -1) {
        await utils.downloadPrivateStoredFile(exportFileId, callApi);
        setLoader(false);
      } else {
        setLoader(false);
        showAlert({ msg: utils.getLang('smartmessaging.noData') });
      }
    },
    [doGetApiCaller, setLoader, showAlert]
  );
  switch (currentStep) {
    case CMP_HISTORY_MAIN:
      return (
        <History
          currentStep={currentStep}
          goToHistoryStep={enhancedGoToHistoryStep}
          exportExecutionResult={exportExecutionResult}
          exportHistory={exportHistory}
          {...otherProps}
          {...extraProps}
        />
      );
    case CMP_HISTORY_DETAIL:
      return (
        <Details
          currentStep={currentStep}
          goToHistoryStep={enhancedGoToHistoryStep}
          exportExecutionResult={exportExecutionResult}
          exportHistory={exportHistory}
          UsedFilter={components && components.UsedFilter}
          {...otherProps}
          {...extraProps}
        />
      );
    default:
      return null;
  }
}

HistoryPage.propTypes = {
  campaign: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  doGetApiCaller: PropTypes.func.isRequired,
  receiveHistory: PropTypes.func,
  excludedProperties: PropTypes.array,
  rowSpanCols: PropTypes.array,
  sortable: PropTypes.array,
  summaryExcludedProperties: PropTypes.array,
  components: PropTypes.any,
  lang: PropTypes.string.isRequired,
};

HistoryPage.defaultProps = {
  excludedProperties: [],
  receiveHistory: null,
  components: null,
  rowSpanCols: [],
  sortable: [],
  summaryExcludedProperties: [],
};

const actionCreators = {
  setLoader: visible => loaderActions.setLoader(visible),
  doGetApiCaller: getApiCaller,
};

export default connect(
  state => ({ lang: state.app.currentLanguage.shortName }),
  actionCreators
)(HistoryPage);
