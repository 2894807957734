import { createReducer } from '@reduxjs/toolkit';
import apiClient from '../utils/apiClient';
import getApiCaller from '../utils/apiClientCaller';
import globalVars from '../consts/globalVars';
import { loaderActions } from './loader';

const initialState = {
  status: {},
  imageMaxSize: 'undefined',
  wizVilleRequestModels: [],
  wizVilleCancelAboRequestModels: [],
  wizVilleNewAboRequestModels: [],
  appHasContent: true,
  config: {
    'smartm-automation:allow': false,
    'smartm-smartjourney:allow': false,
    'smartm-punctual:allow': false,
    'smartm-param:allow': false,
    'smartm-template:allow': false,
    'smartm-private:filter': false,
    'smartm-planned:filter': false,
    stopSmsText: '',
    defaultSmsHours: '',
  },
  currentLanguage: {},
  availableLanguages: [],
};

const RECEIVE_APP_STATUS = 'RECEIVE_APP_STATUS';
const RECEIVE_IMG_MAX_SIZE = 'RECEIVE_IMG_MAX_SIZE';
const RECEIVE_CLUBINFOS = 'RECEIVE_CLUBINFOS';
const RECEIVE_WIZV_RMS = 'RECEIVE_WIZV_RMS';
const RECEIVE_CLUB_ID = 'RECEIVE_CLUB_ID';
const APP_HAS_CONTENT = 'APP_HAS_CONTENT';
const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

const RECEIVE_AVAILABLE_LANGUAGES = 'RECEIVE_AVAILABLE_LANGUAGES';

const actions = {
  receiveAppStatus: statusData => ({ type: RECEIVE_APP_STATUS, value: statusData }),
  receiveImageMaxSize: imageMaxSize => ({ type: RECEIVE_IMG_MAX_SIZE, value: imageMaxSize }),

  receiveClubInfos: infos => ({
    type: RECEIVE_CLUBINFOS,
    value: infos,
  }),
  receiveWizVillRmTypeList: wizVilleRequestModels => ({
    type: RECEIVE_WIZV_RMS,
    value: [...wizVilleRequestModels],
  }),
  receiveWizvilleNewAboRmTypeList: requestModels => ({
    type: 'RECEIVE_WIZVNEWABO_RMS',
    value: [...requestModels],
  }),
  receiveWizvilleCancelAboRmTypeList: requestModels => ({
    type: 'RECEIVE_WIZVCANCELABO_RMS',
    value: [...requestModels],
  }),
  receiveClubId: clubId => ({
    type: RECEIVE_CLUB_ID,
    value: clubId,
  }),
};

export const loadAppStatus = showAlert => async (dispatch, getState) => {
  const statusData = await getApiCaller(showAlert)(dispatch, getState)(apiClient.getAppStatus);
  dispatch(actions.receiveAppStatus(statusData));
};

export const loadClubInfos = showAlert => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);

  const {
    auth: { clientToken },
    app: { clubId },
  } = getState();

  const clubInfos = await callApi(apiClient.getClubInfos, [clientToken], () => ({}));

  const { addressCountryISO, locale } = await callApi(apiClient.getClub, [clubId], () => {});

  dispatch({ type: 'RECEIVE_CLUB_LOCALIZATION', value: { addressCountryISO, locale } });
  sessionStorage.setItem('club.addressCountryISO', addressCountryISO);
  sessionStorage.setItem('club.locale', locale);

  if (clubInfos.wizVilleEntryActivated) {
    const wizVilleRequestModelTypes = await callApi(
      apiClient.getWizVillRequestModelTypes,
      [clientToken],
      () => {}
    );
    dispatch(actions.receiveWizVillRmTypeList(wizVilleRequestModelTypes));
  }

  if (clubInfos.wizVilleNewAboActivated) {
    const wizVilleNewAboRequestModelTypes = await callApi(
      apiClient.getWizVilleNewAboRequestModelTypes,
      [],
      () => {}
    );
    dispatch(actions.receiveWizvilleNewAboRmTypeList(wizVilleNewAboRequestModelTypes));
  }

  if (clubInfos.wizVilleCancelAboActivated) {
    const wizVilleCAncelAboRequestModelTypes = await callApi(
      apiClient.getWizVilleCancelAboRequestModelTypes,
      [clientToken],
      () => {}
    );
    dispatch(actions.receiveWizvilleCancelAboRmTypeList(wizVilleCAncelAboRequestModelTypes));
  }

  if (clubInfos['smartm-automation:allow'] || clubInfos['smartm-smartjourney:allow']) {
    dispatch({ type: 'APP_HAS_CONTENT', value: true });
  } else if (clubInfos['smartm-punctual:allow']) {
    dispatch({ type: 'APP_HAS_CONTENT', value: true });
  } else if (clubInfos['smartm-template:allow']) {
    dispatch({ type: 'APP_HAS_CONTENT', value: true });
  } else if (clubInfos['smartm-param:allow']) {
    dispatch({ type: 'APP_HAS_CONTENT', value: true });
  } else if (clubInfos['smartm-smartjourney:allow']) {
    dispatch({ type: 'APP_HAS_CONTENT', value: true });
  } else {
    dispatch({ type: 'APP_HAS_CONTENT', value: false });
  }
  dispatch(actions.receiveClubInfos(clubInfos));
};

const receiveAppStatus = (state, { value }) => ({
  ...state,
  status: value,
});

const receiveClubInfos = (state, { value }) => ({
  ...state,
  config: { ...state.config, ...value },
});

const receiveWizVillRmTypeList = (state, { value }) => ({
  ...state,
  wizVilleRequestModels: value,
});

const receiveClubLocalization = (state, { value }) => ({
  ...state,
  clubLocalization: { ...state.config, ...value },
});

const receiveWizvilleNewAboRmTypeList = (state, { value }) => ({
  ...state,
  wizVilleNewAboRequestModels: value,
});

const receiveWizvilleCancelAboRmTypeList = (state, { value }) => ({
  ...state,
  wizVilleCancelAboRequestModels: value,
});

const receiveClubId = (state, { value }) => ({
  ...state,
  clubId: value,
});

export const loadImageMaxSize = showAlert => async (dispatch, getState) => {
  const imageMaxSize = await getApiCaller(showAlert)(dispatch, getState)(
    apiClient.getMaxImageWeight
  );
  dispatch(actions.receiveImageMaxSize(imageMaxSize));
};

const getLanguage = () => {
  const cookiesLanguage = '';
  const sessionLanguage = sessionStorage.getItem('locale');
  const browserLanguage = navigator.language || navigator.languages[0];
  return sessionLanguage || cookiesLanguage || browserLanguage || 'default';
};

export const loadLanguage = (code, callback, showAlert) => async (dispatch, getState) => {
  const setLoader = visible => {
    dispatch(loaderActions.setLoader(visible));
  };
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  setLoader(true);
  const contexts = await callApi(apiClient.getContexts, [code]).catch(() => setLoader(false));
  sessionStorage.setItem('locale', contexts.language.locale);
  globalVars.contexts = contexts.contextMap;
  setTimeout(() => {
    dispatch({ type: UPDATE_LANGUAGE, value: contexts.language });
    if (callback) callback();
    setLoader(false);
  }, 300);
};

export const initLang = showAlert => async (dispatch, getState) => {
  const callApi = getApiCaller(showAlert)(dispatch, getState);
  const avLanguages = await callApi(apiClient.getAvailableLanguages);
  dispatch({ type: RECEIVE_AVAILABLE_LANGUAGES, value: avLanguages });
  await dispatch(loadLanguage(getLanguage(), null, showAlert));
};

const receiveImageMaxSize = (state, { value }) => ({
  ...state,
  imageMaxSize: value,
});

const setAppHasContent = (state, { value }) => ({
  ...state,
  appHasContent: value,
});

const updateLanguage = (state, { value }) => ({
  ...state,
  currentLanguage: value,
});

const receiveAvailabeLanguages = (state, { value }) => ({
  ...state,
  availableLanguages: value,
});

export const clearData = () => async dispatch => {
  dispatch({ type: 'CLEAR_CURRENT_ACTION' });
  dispatch({ type: 'CLEAR_EDITOR_DATA' }); // auto cmp
  dispatch({ type: 'CLEAR_EDITED_DATA' }); // mass action
};

export default createReducer(initialState, {
  [RECEIVE_APP_STATUS]: receiveAppStatus,
  [RECEIVE_IMG_MAX_SIZE]: receiveImageMaxSize,
  [RECEIVE_CLUBINFOS]: receiveClubInfos,
  [RECEIVE_WIZV_RMS]: receiveWizVillRmTypeList,
  [RECEIVE_CLUB_ID]: receiveClubId,
  [APP_HAS_CONTENT]: setAppHasContent,
  [UPDATE_LANGUAGE]: updateLanguage,
  [RECEIVE_AVAILABLE_LANGUAGES]: receiveAvailabeLanguages,
  RECEIVE_WIZVNEWABO_RMS: receiveWizvilleNewAboRmTypeList,
  RECEIVE_WIZVCANCELABO_RMS: receiveWizvilleCancelAboRmTypeList,
  RECEIVE_CLUB_LOCALIZATION: receiveClubLocalization,
});

export const getImageMaxSize = state => state.app.imageMaxSize;
