import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import uuid from 'uuid';
import { makeStyles } from 'tss-react/mui';
import ClickedLinkHistoryItem from './ClickedLinkHistoryItem';
import utils from '../../../utils/utils';

const useStyles = makeStyles()(theme => ({
  table: {
    tableLayout: 'fixed',
  },
  headcell: { color: theme.palette.primary.main, padding: '4px' },
}));

function ClickedLinkHistory({ clickedLinks }) {
  const { classes } = useStyles();
  return (
    <>
      {!!clickedLinks.length && (
        <Table aria-labelledby="tableTitle" className={classes.table}>
          <TableHead>
            <TableRow style={{ width: '70px' }}>
              {['linktype', 'url', 'count'].map(column => (
                <TableCell key={column} className={classes.headcell}>
                  {utils.getLang(`smartmessaging.clickHistory.column.${column}`)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {clickedLinks.map(clickedLink => (
              <Fragment key={uuid()}>
                <ClickedLinkHistoryItem
                  item={clickedLink[0]}
                  rowSpanCols={['linktype']}
                  rowSpan={clickedLink.length || 1}
                  columns={['linktype', 'url', 'count']}
                />
                {clickedLink.slice(1).map(i => (
                  <ClickedLinkHistoryItem
                    key={uuid()}
                    item={i}
                    columns={['url', 'count']}
                    rowSpanCols={[]}
                    rowSpan={clickedLink.length || 1}
                  />
                ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}

ClickedLinkHistory.propTypes = {
  clickedLinks: PropTypes.array.isRequired,
};

export default ClickedLinkHistory;
