import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

function useCampaignCreator(groupId) {
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    async function asyncFn() {
      await dispatch({
        type: 'CREATE_CAMPAIGN',
        value: groupId ? { groupId: Number(groupId) } : null,
      });
      setLoaded(true);
    }
    asyncFn();
  }, [dispatch, groupId, setLoaded]);
  return loaded;
}

export default useCampaignCreator;
