import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useRoute } from 'wouter';
import { useAlert } from 'src/hooks';
import { connect } from 'react-redux';

import { Typography, Divider } from '@mui/material';

import CustomTemplateList from './CustomTemplateList';
import CustomTemplateEditor from './customTemplateEditor/CustomTemplateEditor';

import utils from 'src/utils/utils';
import { editTemplate, loadCustomTemplates } from 'src/reducers/customTemplates';
import withDataResolver from '../common/withDataResolver';
import { loadRequestModels } from 'src/reducers/requestModelList';

function CustomTemplateContent({ doEditTemplate }) {
  const [editRouteMatch, editRouteParams] = useRoute('/templates/edit/:id');
  const [createRouteMatch] = useRoute('/templates/create');
  const { showAlert } = useAlert();

  useEffect(() => {
    if (editRouteMatch) {
      doEditTemplate(Number(editRouteParams.id), showAlert);
    }

    if (createRouteMatch) {
      doEditTemplate(null, showAlert);
    }
  }, [createRouteMatch, doEditTemplate, editRouteMatch, editRouteParams, showAlert]);

  return (
    <>
      <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
        <Typography variant="h5" color="textSecondary" style={{ margin: 'auto 16px' }}>
          {utils.getLang('smartmessaging.customTemplates.mainTitle')}
        </Typography>
      </div>
      <Divider />
      <CustomTemplateList />
      <CustomTemplateEditor />
    </>
  );
}

const actionCreators = {
  doEditTemplate: editTemplate,
  doLoadCustomTemplates: loadCustomTemplates,
  doLoadRequestModels: loadRequestModels,
};

CustomTemplateContent.propTypes = { doEditTemplate: PropTypes.func.isRequired };

const resolvers = {
  resolve: props => async (callApi, showAlert) => {
    await props.doLoadCustomTemplates(showAlert);
    await props.doLoadRequestModels(showAlert);
  },

  onResolved: () => async () => {},
  getLoaderText: () => utils.getLang('smartmessaging.customTemplates.loadingTemplates'),
};

export default connect(null, actionCreators)(withDataResolver(resolvers)(CustomTemplateContent));
