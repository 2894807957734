import React from 'react';
import { format } from 'date-fns';
import ActionType, { ActionTypeById } from 'src/enums/actionType';
import utils from 'src/utils/utils';

const sortByOperator = values => values.sort((a, b) => a.operatorId - b.operatorId);

const mapByKey = value =>
  value.reduce(
    (acc, v) =>
      !acc[v.key]
        ? { ...acc, [v.key]: [v] }
        : {
            ...acc,
            [v.key]: [...acc[v.key]].concat(v),
          },
    {}
  );

const proceedSetup = value =>
  Object.entries(mapByKey(value)).reduce((acc, [k, v]) => ({ ...acc, [k]: sortByOperator(v) }), {});

const defaultExcludedProperties = ['setupValue', 'setupColumns', 'executionLogByClubId'];

const getColumns = (history, excludedProperties) =>
  Object.keys(history).filter(
    key => defaultExcludedProperties.concat(excludedProperties).indexOf(key) === -1
  );

const columnRenderers = {
  openedMessageNumber: item =>
    item.actionType !== ActionType.MEMBER_NOTIFICATION.id ? item.openedMessageNumber : '--',
  openPercentage: item =>
    item.actionType !== ActionType.MEMBER_NOTIFICATION.id ? item.openPercentage : '--',
  date: item => format(new Date(item.date), 'dd/MM/yyyy HH:mm'),
  actionType: item =>
    item.isTest
      ? `Test ${utils.getLang(`smartmessaging.contentType.${ActionTypeById[item.actionType]}`)}`
      : utils.getLang(`smartmessaging.contentType.${ActionTypeById[item.actionType]}`),
  optinType: item =>
    utils.getLang(
      `smartmessaging.optinType.${(item.optinType !== 'null' && item.optinType) || 'NO_OPTIN'}`
    ),
  status: item => (
    <>
      <div>{item.status ? utils.getLang(`smartmessaging.campaignStatus.${item.status}`) : '-'}</div>
      <div>
        {utils.stringFormat(utils.getLang(`smartmessaging.campaignStatus.targetTotal`), [
          item.targetTotal || '-',
        ])}
      </div>
      <div>
        {utils.stringFormat(utils.getLang(`smartmessaging.campaignStatus.targetWithOptin`), [
          item.targetWithOptin || '-',
        ])}
      </div>
    </>
  ),
};

const renderCell = (colName, item) => {
  if (columnRenderers[colName]) return columnRenderers[colName](item);
  return item[colName];
};

const columnsSorters = {
  default: key => ({
    asc: (a, b) => (a.items[0][key] < b.items[0][key] ? -1 : 1),
    desc: (a, b) => (a.items[0][key] < b.items[0][key] ? 1 : -1),
  }),
  date: {
    asc: (a, b) => new Date(a.items[0].date).getTime() - new Date(b.items[0].date).getTime(),
    desc: (a, b) => new Date(b.items[0].date).getTime() - new Date(a.items[0].date).getTime(),
  },
  actionType: {
    asc: (a, b) => (a.actionType < b.actionType ? -1 : 1),
    desc: (a, b) => (a.actionType < b.actionType ? 1 : -1),
  },
  targetNumber: {
    asc: (a, b) => (a.items[0].targetNumber < b.items[0].targetNumber ? -1 : 1),
    desc: (a, b) => (a.items[0].targetNumber < b.items[0].targetNumber ? 1 : -1),
  },
  sentMessageNumber: {
    asc: (a, b) =>
      a.items.reduce((t, aa) => t + aa.sentMessageNumber, 0) -
      b.items.reduce((t, bb) => t + bb.sentMessageNumber, 0),
    desc: (a, b) =>
      b.items.reduce((t, bb) => t + bb.sentMessageNumber, 0) -
      a.items.reduce((t, aa) => t + aa.sentMessageNumber, 0),
  },
  openedMessageNumber: {
    asc: (a, b) => (a.openedMessageNumber < b.openedMessageNumber ? -1 : 1),
    desc: (a, b) => (a.openedMessageNumber < b.openedMessageNumber ? 1 : -1),
  },
  openPercentage: {
    asc: (a, b) => (a.openPercentage < b.openPercentage ? -1 : 1),
    desc: (a, b) => (a.openPercentage < b.openPercentage ? 1 : -1),
  },
  clubName: {
    asc: (a, b) => (a.items[0].clubName < b.items[0].clubName ? -1 : 1),
    desc: (a, b) => (a.items[0].clubName < b.items[0].clubName ? 1 : -1),
  },
};

const getSorter = (key, direction) =>
  columnsSorters[key] ? columnsSorters[key][direction] : columnsSorters.default(key)[direction];

export default { proceedSetup, getColumns, columnRenderers, renderCell, columnsSorters, getSorter };
