import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Tooltip,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import WarningIcon from '@mui/icons-material/Warning';

import { connect } from 'react-redux';

import { editTemplate } from 'src/reducers/customTemplates';

import utils from 'src/utils/utils';
import rmSelectors from 'src/selectors/requestModel';
import { useLocation } from 'wouter';

const useStyles = makeStyles()(() => ({
  menuItem: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  header: {
    padding: '4px',
    top: 0,
  },
}));

function CustomTemplateListItemMenu({ doEditTemplate }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { classes } = useStyles();

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <>
      <IconButton
        style={{ padding: '4px' }}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClick(e);
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        PaperProps={{
          style: {
            minWidth: 150,
            borderRadius: 0,
          },
        }}
        MenuListProps={{ style: { padding: 0 } }}
        value
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={e => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
      >
        <MenuItem
          className={classes.menuItem}
          dense
          onClick={e => {
            e.preventDefault();
            handleClose();
            doEditTemplate();
          }}
        >
          {utils.getLang(`smartmessaging.customTemplateList.editTemplate`)}
        </MenuItem>
      </Menu>
    </>
  );
}

CustomTemplateListItemMenu.propTypes = {
  doEditTemplate: PropTypes.func.isRequired,
};

function CustomTemplateListRender({ customTemplatesMap, getRmByRmTypeId }) {
  const [selected, setSelected] = useState(null);
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  return (
    <>
      <div>
        <Fab
          onClick={e => {
            e.preventDefault();
            navigate('/templates/create');
          }}
          aria-label="add"
          color="primary"
          style={{
            position: 'absolute',
            right: '10px',
            top: '40px',
            zIndex: 1,
          }}
        >
          <AddIcon />
        </Fab>
      </div>
      <div
        style={{
          position: 'relative',
          overflow: 'auto',
          margin: '8px',
          marginTop: '24px',
          flex: 1,
        }}
      >
        <div
          style={{
            padding: '2px 4px',
          }}
        >
          <Table style={{ tableLayout: 'fixed' }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.header}>
                  {utils.getLang(`smartmessaging.customTemplateList.header.name`)}
                </TableCell>
                <TableCell className={classes.header}>
                  {utils.getLang(`smartmessaging.customTemplateList.header.description`)}
                </TableCell>
                <TableCell className={classes.header}>
                  {utils.getLang(`smartmessaging.customTemplateList.header.actionType`)}
                </TableCell>
                <TableCell className={classes.header}>
                  {utils.getLang(`smartmessaging.customTemplateList.header.model`)}
                </TableCell>
                <TableCell
                  style={{
                    top: 0,
                    background: '#ffffff',
                  }}
                />
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.entries(customTemplatesMap).map(([uniqueId, customTemplate]) => (
                <TableRow
                  key={uniqueId}
                  hover
                  onClick={e => {
                    e.preventDefault();
                    setSelected(uniqueId);
                  }}
                  onDoubleClick={e => {
                    e.preventDefault();
                    setSelected(uniqueId);
                    navigate(`/templates/edit/${uniqueId}`);
                  }}
                  selected={selected === uniqueId}
                >
                  <TableCell style={{ padding: '4px' }}>
                    <div style={{ display: 'flex' }}>
                      {(!customTemplate.storedfileId || !customTemplate.actionTypeId) && (
                        <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                          <Tooltip
                            title={utils.getLang('smartmessaging.customTemplate.toBeCompleted')}
                          >
                            <WarningIcon color="error" />
                          </Tooltip>
                        </div>
                      )}
                      <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
                        {customTemplate.name}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell style={{ padding: '4px' }}>{customTemplate.description}</TableCell>
                  <TableCell style={{ padding: '4px' }}>
                    {(customTemplate.actionTypeId === 1 &&
                      utils.getLang('smartmessaging.contentEditor.smsActionName')) ||
                      (customTemplate.actionTypeId === 2 &&
                        utils.getLang('smartmessaging.contentEditor.emailActionName'))}
                  </TableCell>
                  <TableCell style={{ padding: '4px' }}>
                    {!!customTemplate.requestModelTypeId &&
                      utils.getLang(
                        `smartmessaging.requestmodel.label.${
                          getRmByRmTypeId(customTemplate.requestModelTypeId).name
                        }`
                      )}
                  </TableCell>

                  <TableCell>
                    <CustomTemplateListItemMenu
                      doEditTemplate={() => {
                        setSelected(uniqueId);
                        navigate(`/templates/edit/${uniqueId}`);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}

CustomTemplateListRender.propTypes = {
  customTemplatesMap: PropTypes.object.isRequired,
  getRmByRmTypeId: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  customTemplatesMap: state.customTemplates.customTemplateMap,
  getRmByRmTypeId: rmTypeId => rmSelectors.getRmByRmTypeId(state, rmTypeId),
});

const actionCreators = {
  doEditTemplate: editTemplate,
};

export default connect(mapStateToProps, actionCreators)(CustomTemplateListRender);
