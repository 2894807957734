import React from 'react';
import DataResolver from './DataResolver';
import { useAlert } from 'src/hooks';

const withDataResolver = (resolvers, styles) => Component => props => {
  const { showAlert } = useAlert();
  return (
    <DataResolver
      showAlert={showAlert}
      resolve={resolvers.resolve(props)}
      onResolved={resolvers.onResolved(props)}
      loaderWrapperClass={styles && styles.loaderWrapper && styles.loaderWrapper.class}
      loaderWrapperStyle={styles && styles.loaderWrapper && styles.loaderWrapper.style}
      loader={resolvers.loader}
      loaderText={resolvers.loaderText || ''}
      getLoaderText={resolvers.getLoaderText || null}
    >
      {newProps => <Component {...{ ...props, ...newProps }} />}
    </DataResolver>
  );
};

export default withDataResolver;
