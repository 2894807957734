import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider, Button } from '@mui/material';

import CampaignContent from '../campaign/CampaignContent';
import SmartjourneyContent from '../smartjourney/SmartjourneyContent';
import utils from 'src/utils/utils';
import { Redirect, Route, Switch, useLocation, useRoute } from 'wouter';
import { useSelector } from 'react-redux';

const Tabs = ({ items }) => (
  <ul className="nav nav-tabs">
    {items.map(item => (
      <li
        key={item.key}
        className={item.active ? 'active' : ''}
        onClick={() => {
          item.action();
        }}
      >
        <a>{item.label}</a>
      </li>
    ))}
  </ul>
);

Tabs.propTypes = { items: PropTypes.array.isRequired };

function BackBtn({ backToListPath }) {
  const [, navigate] = useLocation();

  return (
    <Button
      variant="text"
      style={{
        textTransform: 'none',
      }}
      onClick={e => {
        e.preventDefault();
        navigate(backToListPath);
      }}
    >
      <Typography variant="body2" color="textSecondary">
        {utils.getLang('smartmessaging.buttonLabel.backToList')}
      </Typography>
    </Button>
  );
}

BackBtn.propTypes = { backToListPath: PropTypes.string.isRequired };

function RoutedBackBtn() {
  return (
    <Switch>
      <Route path="/campaigns" nest>
        <Route path="/:id/edit" nest>
          <BackBtn backToListPath="/list" />
        </Route>
        <Route path="/:id/history">
          <BackBtn backToListPath="/list" />
        </Route>
        <Route path="/create">
          <BackBtn backToListPath="/list" />
        </Route>
      </Route>
      <Route path="/smartjourney" nest>
        <Route path="/create">
          <BackBtn backToListPath="/list" />
        </Route>
        <Route path="/:id/campaigns/list">
          <BackBtn backToListPath="/list" />
        </Route>
        <Route path="/:id/campaigns" nest>
          <Route path="/:cmpId/edit">
            <BackBtn backToListPath="/list" />
          </Route>
          <Route path="/create">
            <BackBtn backToListPath="/list" />
          </Route>
          <Route path="/:id/history">
            <BackBtn backToListPath="/list" />
          </Route>
        </Route>
      </Route>
    </Switch>
  );
}

function AutoCampaigns({ doClearData, ...otherProps }) {
  const [, navigate] = useLocation();
  const automatedActivated = useSelector(state => state.app.config['smartm-automation:allow']);
  const smjActivated = useSelector(state => state.app.config['smartm-smartjourney:allow']);

  const [autoCmpRouteMatch] = useRoute('/campaigns/*?');
  const [smartmJourneyRouteMatch] = useRoute('/smartjourney/*?');

  return (
    <>
      <div>
        <div style={{ display: 'flex', padding: '10px', minHeight: '50px' }}>
          <Typography
            variant="h5"
            color="textSecondary"
            style={{
              margin: 'auto 16px',
              flex: 1,
              textAlign: 'left',
            }}
          >
            {utils.getLang('smartmessaging.campaigns.mainTitle')}
          </Typography>
          <RoutedBackBtn />
        </div>
        {automatedActivated && smjActivated && (
          <Tabs
            items={[
              {
                key: 'campaigns',
                active: autoCmpRouteMatch,
                label: utils.getLang('smartmessaging.campaigns.single.tabLabel'),
                action: () => {
                  navigate('/campaigns/list');
                },
              },
              {
                key: 'smartjourney',
                active: smartmJourneyRouteMatch,
                label: utils.getLang('smartmessaging.campaigns.grouped.tabLabel'),
                action: () => {
                  navigate('/smartjourney/list');
                },
              },
            ]}
          />
        )}
      </div>
      <Divider />
      {automatedActivated || smjActivated ? (
        <Switch>
          <Route path="/campaigns/*?">
            {automatedActivated ? (
              <CampaignContent doClearData={doClearData} {...otherProps} />
            ) : (
              <Redirect to="/smartjourney/list" />
            )}
          </Route>
          <Route path="/smartjourney/*?">
            {smjActivated ? (
              <SmartjourneyContent doClearData={doClearData} {...otherProps} />
            ) : (
              <Redirect to="/campaigns/list" />
            )}
          </Route>
        </Switch>
      ) : (
        <Redirect to="~/error" />
      )}
    </>
  );
}

AutoCampaigns.propTypes = {
  doClearData: PropTypes.func.isRequired,
};

export default AutoCampaigns;
