import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { Typography, IconButton, Paper, Dialog, AppBar, Toolbar, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/EditOutlined';
import {
  selectMailMessage,
  deleteCmpAction,
  selectSMSMessage,
  selectNotifyMessage,
} from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import { loaderActions } from 'src/reducers/loader';

import CustCard from './ActionCard';
import TestForm from 'src/components/common/TestForm';

import ActionType from 'src/enums/actionType';
import { MESSAGE_EDITOR } from './CampaignMessage';
import { useAlert } from 'src/hooks';

const mapStateToProps = state => ({
  rmTypeId: state.campaignEditor.editedCampaign.requestModelTypeId,
  currentAppLn: state.app.currentLanguage,
});

const actionCreators = {
  doDeleteCmpAction: deleteCmpAction,
  doSelectMailMessage: selectMailMessage,
  doSelectSMSMessage: selectSMSMessage,
  doSelectNotifyMessage: selectNotifyMessage,
  setLoader: visible => loaderActions.setLoader(visible),
};

const useStyles = makeStyles()(theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.primary.main,
  },
  testBtn: { margin: '2px' },
  editBtn: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.primary.contrastText,
    margin: '2px',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
      opacity: 0.9,
    },
  },
  deleteBtn: {
    margin: '2px',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      opacity: 0.9,
    },
  },
}));

const ActionEditor = ({
  loadFieldsAndContent,
  action,
  handleMessageSelect,
  doDeleteCmpAction,
  setLoader,
  goToCmpMsgStep,
  testFormIsVisible,
  showTestForm,
  actionTypeId,
  rmId,
  isWritable,
  lang,
  currentAppLn,
}) => {
  const { classes } = useStyles();
  const { showAlert } = useAlert();
  return (
    <>
      <Dialog open={testFormIsVisible} fullWidth>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h5" color="inherit" style={{ flex: 1 }}>
              {utils.getLang('smartmessaging.campaignEditor.testAction.popupTitle')}
            </Typography>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                showTestForm(false);
              }}
              size="large"
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '300px',
          }}
        >
          <TestForm
            rmId={rmId}
            includeWizVille={false}
            campaignActionId={action && action.id}
            actionTypeId={actionTypeId}
            currentAppLn={currentAppLn}
          />
        </div>
      </Dialog>
      <CustCard title={utils.getLang(lang.title[actionTypeId])}>
        <Paper
          style={{ height: '200px', width: '160px', display: 'flex', flexDirection: 'column' }}
        >
          <Typography
            color="primary"
            style={{
              wordWrap: 'normal',
              padding: '8px',
            }}
          >
            {utils.getLang(lang.createText[actionTypeId])}
          </Typography>
          <div style={{ flex: 1 }}>
            <IconButton
              onClick={event => {
                event.preventDefault();
                setLoader(true);
                loadFieldsAndContent(async processedFields => {
                  // the load content fn to be passed as a callback
                  await handleMessageSelect(processedFields.fieldsModels).finally(() =>
                    setLoader(false)
                  );
                  goToCmpMsgStep(MESSAGE_EDITOR, {
                    fields: processedFields,
                    actionTypeId: action.actionTypeId,
                  });
                });
              }}
              className={classes.editBtn}
              size="small"
            >
              <EditIcon />
            </IconButton>
            {isWritable && (
              <IconButton
                onClick={event => {
                  event.preventDefault();
                  doDeleteCmpAction(action.id, showAlert);
                }}
                className={classes.deleteBtn}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </div>
          {[ActionType.MEMBER_NOTIFICATION.id].indexOf(actionTypeId) === -1 && (
            <div style={{ padding: '12px', display: 'grid' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={event => {
                  event.preventDefault();
                  showTestForm(true);
                }}
              >
                {utils.getLang('smartmessaging.contentEditor.testContent')}
              </Button>
            </div>
          )}
        </Paper>
      </CustCard>
    </>
  );
};

ActionEditor.propTypes = {
  loadFieldsAndContent: PropTypes.func.isRequired,
  handleMessageSelect: PropTypes.func.isRequired,
  doDeleteCmpAction: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  setLoader: PropTypes.func.isRequired,
  goToCmpMsgStep: PropTypes.func.isRequired,
  testFormIsVisible: PropTypes.bool.isRequired,
  showTestForm: PropTypes.func.isRequired,
  actionTypeId: PropTypes.number.isRequired,
  rmId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  lang: PropTypes.object.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};

function ActionEditorHandler(props) {
  const { showAlert } = useAlert();
  async function handleMessageSelect(fieldsModels) {
    const { action, doSelectSMSMessage, doSelectMailMessage, doSelectNotifyMessage } = props;
    switch (action.actionTypeId) {
      case ActionType.SMS.id:
        await doSelectSMSMessage(action, fieldsModels, showAlert);
        break;

      case ActionType.EMAIL.id:
      case ActionType.EMAIL_COACH.id:
      case ActionType.EMAIL_SPONSORSHIP.id:
      case ActionType.EMAIL_INVITATION.id:
        await doSelectMailMessage(action, fieldsModels, action.actionTypeId, showAlert);
        break;
      case ActionType.MEMBER_NOTIFICATION.id:
        await doSelectNotifyMessage(action, fieldsModels, action.actionTypeId, showAlert);
        break;
      default:
        break;
    }
  }

  const [testFormIsVisible, showTestForm] = useState(false);

  return <ActionEditor {...props} {...{ testFormIsVisible, showTestForm, handleMessageSelect }} />;
}

ActionEditorHandler.propTypes = {
  doSelectSMSMessage: PropTypes.func.isRequired,
  doSelectMailMessage: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  doSelectNotifyMessage: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actionCreators)(ActionEditorHandler);
