import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  button: {
    margin: theme.spacing(0.5),
  },
}));

const FileUploaderBtn = ({
  onFileChange,
  sendFileLabel,
  cancelLabel,
  selectFileLabel,
  onSend,
  accept,
  displayCmp,
  flex,
}) => {
  const [loaded, setLoaded] = useState(false);
  const inputRef = useRef();

  const onChange = useCallback(
    (e, file) => {
      const loadSuccess = onFileChange(e, file);
      if (!loadSuccess) inputRef.current.value = '';
      setLoaded(loadSuccess);
    },
    [onFileChange]
  );
  function getFileObject() {
    return inputRef.current.files[0];
  }

  const { classes } = useStyles();

  return (
    <div style={flex ? { display: 'flex', alignItems: 'center' } : {}}>
      <input
        style={{ display: 'none' }}
        type="file"
        accept={accept}
        onChange={onChange}
        ref={inputRef}
      />

      {loaded && displayCmp(inputRef.current.files[0])}
      <Button
        className={(!loaded && classes.button) || ''}
        color="primary"
        variant={loaded ? 'text' : 'contained'}
        onClick={e => {
          e.preventDefault();
          if (loaded) {
            inputRef.current.value = '';
            onChange();
          } else inputRef.current.click();
        }}
        style={{ cursor: 'pointer' }}
      >
        {(loaded && cancelLabel) || selectFileLabel}
      </Button>
      {loaded && (
        <Button
          className={classes.button}
          variant="contained"
          onClick={() => {
            onSend(getFileObject());
          }}
        >
          {sendFileLabel}
        </Button>
      )}
    </div>
  );
};

FileUploaderBtn.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  sendFileLabel: PropTypes.string.isRequired,
  displayCmp: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  selectFileLabel: PropTypes.string.isRequired,
  accept: PropTypes.string,
  flex: PropTypes.bool,
};
FileUploaderBtn.defaultProps = { accept: '', flex: true };

export default FileUploaderBtn;
