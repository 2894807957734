import React from 'react';
import { connect } from 'react-redux';
import { loaderActions } from 'src/reducers/loader';

import { notify } from 'src/reducers/notify';
import { useAlert } from 'src/hooks';

const WithAlert = ({ setLoader, doNotify, children }) => children({ setLoader, doNotify });

const actionCreators = {
  setLoader: visible => loaderActions.setLoader(visible),
  doNotify: notify,
};
const Connected = connect(null, actionCreators)(WithAlert);

export default Cmp => props => {
  const { showAlert } = useAlert();
  return (
    <Connected>
      {({ setLoader, doNotify }) => (
        <Cmp
          {...props}
          showAlert={showAlert}
          closeAlert={() => {
            showAlert(false);
          }}
          setLoader={setLoader}
          notify={doNotify}
        />
      )}
    </Connected>
  );
};
