import React from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';

import utils from 'src/utils/utils';

const SMJSelectedCmpList = ({ selection, requestModelByRMTypeId }) => {
  const theme = useTheme();
  return (
    <>
      {Object.entries(selection).map(([rmId, list]) => (
        <div
          key={rmId}
          style={{
            display: 'inline-block',
            margin: '4px',
            borderBottom: theme.border.primary,
            width: '40%',
            maxWidth: '400px',
            minWidth: '150px',
          }}
        >
          <Typography color="textPrimary" style={{ fontWeight: 200 }}>
            {utils.getLang(
              `smartmessaging.requestmodel.label.${requestModelByRMTypeId[rmId].name}`
            )}
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {list.map(cmp => (
              <Typography
                key={cmp.key}
                variant="body2"
                color="textSecondary"
                style={{ fontWeight: 200 }}
              >
                {utils.getLang(`smartmessaging.campaigns.grouped.campaignModel.name.${cmp.key}`)}
              </Typography>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

SMJSelectedCmpList.propTypes = {
  selection: PropTypes.object.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
};

export default SMJSelectedCmpList;
