import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from 'tss-react/mui';
import { Fade } from '@mui/material';
import NotFound from '../common/NotFound';

import CustomTemplateContent from '../customTemplate/CustomTemplateContent';
import MassActionContent from '../massAction/MassActionContent';
import ParametersContent from '../parameters/ParametersContent';
import SentMessages from '../sentMessages/SentMessages';
import { clearData } from 'src/reducers/app';
import AutoCampaigns from '../autoCampaigns/AutoCampaigns';
import { Link, Redirect, Route, Switch, useLocation } from 'wouter';

const useStyles = makeStyles()(() => ({
  content: {
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
}));

const FadedContainer = ({ children }) => {
  const { classes } = useStyles();
  return (
    <Fade in timeout={500}>
      <div className={classes.content}>{children}</div>
    </Fade>
  );
};

FadedContainer.propTypes = { children: PropTypes.any.isRequired };

function MainContent({
  appConfig,
  doClearData,
  automatedActivated,
  smjActivated,
  actionCoActivated,
  parametersActivated,
  customTemplateActivated,
}) {
  const [location] = useLocation();

  useEffect(() => {
    doClearData();
  }, [doClearData]);

  const defaultRoute = useMemo(() => {
    if (automatedActivated || smjActivated) return '/campaigns/list';
    if (actionCoActivated) return '/oneoff/list';
    if (parametersActivated) return '/params/list';
    if (customTemplateActivated) return '/templates';
    return '/error';
  }, [
    actionCoActivated,
    automatedActivated,
    customTemplateActivated,
    parametersActivated,
    smjActivated,
  ]);

  const routeIsAllowed = useMemo(() => {
    const path = location.replace(/^\//, '').split('/')[0];

    switch (path) {
      case 'campaigns':
      case 'smartjourney':
        return automatedActivated || smjActivated;
      case 'oneoff':
        return actionCoActivated;
      case 'params':
        return parametersActivated;
      case 'templates':
        return customTemplateActivated;
      case 'history':
        return true;
      case '':
        return true;
      default:
        return false;
    }
  }, [
    actionCoActivated,
    automatedActivated,
    customTemplateActivated,
    location,
    parametersActivated,
    smjActivated,
  ]);

  if (/^$|^\/$/.test(location)) return <Redirect to={defaultRoute} />;

  if (routeIsAllowed)
    return (
      <Switch>
        <Route path="/campaigns/*?">
          <FadedContainer>
            <AutoCampaigns doClearData={doClearData} />
          </FadedContainer>
        </Route>
        <Route path="/smartjourney/*?">
          <FadedContainer>
            <AutoCampaigns doClearData={doClearData} />
          </FadedContainer>
        </Route>
        <Route path="/params/*?">
          <FadedContainer>
            <ParametersContent />
          </FadedContainer>
        </Route>
        <Route path="/oneoff/*?">
          <FadedContainer>
            <MassActionContent doClearData={doClearData} />
          </FadedContainer>
        </Route>
        <Route path="/templates/*?">
          <FadedContainer>
            <CustomTemplateContent />
          </FadedContainer>
        </Route>
        <Route path="/history">
          <FadedContainer>
            <SentMessages appConfig={appConfig} />
          </FadedContainer>
        </Route>
        <Route path="/*">
          <Redirect to={defaultRoute} />
        </Route>
      </Switch>
    );

  return (
    <NotFound text={`Unauthorized : ${location}`}>
      {Boolean(defaultRoute) && <Link to={defaultRoute}>{defaultRoute}</Link>}
    </NotFound>
  );
}

MainContent.propTypes = {
  appConfig: PropTypes.object.isRequired,
  doClearData: PropTypes.func.isRequired,
  automatedActivated: PropTypes.bool.isRequired,
  smjActivated: PropTypes.bool.isRequired,
  actionCoActivated: PropTypes.bool.isRequired,
  parametersActivated: PropTypes.bool.isRequired,
  customTemplateActivated: PropTypes.bool.isRequired,
};

const actionCreators = { doClearData: clearData };

const mapStateToProps = state => ({
  appConfig: state.app.config,
  actionCoActivated: state.app.config['smartm-punctual:allow'],
  parametersActivated: state.app.config['smartm-param:allow'],
  automatedActivated: state.app.config['smartm-automation:allow'],
  smjActivated: state.app.config['smartm-smartjourney:allow'],
  customTemplateActivated: state.app.config['smartm-template:allow'],
});

export default connect(mapStateToProps, actionCreators)(MainContent);
