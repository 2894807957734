import React from 'react';
import PropTypes from 'prop-types';

import {
  Paper,
  Fade,
  FormLabel,
  TextField,
  Typography,
  FormControlLabel,
  MenuItem,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import Checkbox from 'src/components/common/Checkbox';

import IntegerField from 'src/components/common/IntegerField';
import CampaignType from '../CampaignType';
import utils from 'src/utils/utils';
import { useHashLocation } from 'wouter/use-hash-location';
import { Redirect, useRoute } from 'wouter';
import { useAlert } from 'src/hooks';
import { TARGET } from '../../CampaignEditor';

const OPTINS = ['legal', 'advertising', 'news'];

const useStyles = makeStyles()(theme => ({
  formLabel: theme.typography.body2,
  warning: { margin: theme.spacing(0.5) },
  marginGap: { marginBottom: theme.spacing(1), border: 'none' },
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    margin: `${theme.spacing(3)} 15% ${theme.spacing(1)}`,
  },
}));

const CampaignInfosRender = ({
  editedCampaign,
  selectedType,
  requestModelByRMTypeId,
  recipesById,
  updateEditedCampaign,
  doSaveCampaign,
  goToEditorStep,
  infosValidity,
  requestModelsByCategoryId,
  sortedCategoryList,
  selectCampaignType,
  selectOptinType,
  getDefaultOptin,
  showWizvilleInfos,
}) => {
  const { classes } = useStyles();
  const theme = useTheme();
  const [hlocation] = useHashLocation();
  const [afterCreateRouteMatch] = useRoute('/campaigns/create');
  const { showAlert } = useAlert();

  if (afterCreateRouteMatch && hlocation.length > 1) {
    return <Redirect to={`/campaigns/${hlocation.replace('/', '')}/edit`} />;
  }

  return (
    <Fade in timeout={500}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <div className={classes.wrapper}>
          <Paper
            elevation={0}
            style={{
              overflow: 'hidden',
              display: 'flex',
            }}
          >
            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
              <FormLabel required className={classes.formLabel} htmlFor="cmp-name-ipt">
                {utils.getLang('smartmessaging.campaignInfos.label.name')}
              </FormLabel>
              <TextField
                disabled={!editedCampaign.isWritable}
                InputProps={{
                  id: 'cmp-name-ipt',
                }}
                value={editedCampaign.name}
                onChange={event => {
                  updateEditedCampaign({ name: event.target.value });
                }}
              />
              {showWizvilleInfos && (
                <>
                  <hr className={classes.marginGap} />
                  <FormLabel required htmlFor="wizId" className={classes.formLabel}>
                    {utils.getLang('smartmessaging.wizVille.idLabel')}
                  </FormLabel>
                  <IntegerField
                    disabled={!editedCampaign.isWritable}
                    InputProps={{
                      id: 'wizId',
                    }}
                    value={editedCampaign.wizVilleId || ''}
                    onChange={value => {
                      updateEditedCampaign({ wizVilleId: value });
                    }}
                  />
                  <hr className={classes.marginGap} />
                  <FormLabel required htmlFor="wizBrand" className={classes.formLabel}>
                    {utils.getLang('smartmessaging.wizVille.brandLabel')}
                  </FormLabel>
                  <TextField
                    disabled={!editedCampaign.isWritable}
                    style={{ marginBottom: theme.spacing(2) }}
                    InputProps={{
                      id: 'wizBrand',
                    }}
                    value={editedCampaign.wizVilleBrand || ''}
                    onChange={e => {
                      updateEditedCampaign({ wizVilleBrand: e.target.value });
                    }}
                  />
                </>
              )}

              <hr className={classes.marginGap} />
              <CampaignType
                {...{
                  editedCampaign,
                  selectedType,
                  requestModelByRMTypeId,
                  recipesById,
                  requestModelsByCategoryId,
                  sortedCategoryList,
                  selectCampaignType,
                }}
              />
              <hr className={classes.marginGap} />

              {(editedCampaign.recipeId || selectedType) && (
                <>
                  <FormLabel required className={classes.formLabel} htmlFor="cmp-optin-ipt">
                    {utils.getLang('smartmessaging.campaignInfos.label.optin')}
                  </FormLabel>
                  <TextField
                    disabled={!editedCampaign.isWritable}
                    onChange={e => {
                      selectOptinType(e.target.value);
                    }}
                    select
                    style={{ flex: 1 }}
                    InputProps={{
                      id: 'cmp-optin-ipt',
                    }}
                    SelectProps={{
                      MenuProps: {
                        MenuListProps: { style: { padding: 0, margin: 0 } },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },

                        marginThreshold: 0,
                      },
                    }}
                    value={editedCampaign.optinType || ''}
                  >
                    {OPTINS.map(o => (
                      <MenuItem key={o} value={o}>
                        {utils.getLang(`smartmessaging.diffusionType.${o}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                  {(getDefaultOptin() !== editedCampaign.optinType ||
                    [['legal', 'news']].indexOf(editedCampaign.optinType) !== -1) && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <WarningIcon className={classes.warning} color="error" />
                      <ul>
                        {getDefaultOptin() !== editedCampaign.optinType && (
                          <li>
                            <Typography color="textSecondary" paragraph variant="body2">
                              {`${utils.getLang(
                                'smartmessaging.campaignInfos.defaultOptinWarning'
                              )} : ${utils.getLang(
                                `smartmessaging.diffusionType.${getDefaultOptin()}`
                              )}`}
                            </Typography>
                          </li>
                        )}
                        {['legal', 'news'].indexOf(editedCampaign.optinType) !== -1 && (
                          <li>
                            <Typography color="textSecondary" variant="body2" paragraph>
                              {utils.stringFormat(
                                utils.getLang(
                                  'smartmessaging.contentEditor.marketingWarningContent'
                                ),
                                [
                                  utils.getLang(
                                    `smartmessaging.diffusionType.${editedCampaign.optinType ||
                                      getDefaultOptin()}`
                                  ),
                                ]
                              )}
                            </Typography>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                  <hr className={classes.marginGap} />
                </>
              )}
              <FormLabel className={classes.formLabel}>
                {utils.getLang('smartmessaging.campaignInfos.label.outOfHoursSms')}
              </FormLabel>
              <FormControlLabel
                control={<Checkbox style={{ pointerEvents: 'auto' }} />}
                style={{ pointerEvents: 'none', margin: '8px 0px 0px 0px' }}
                disabled={!editedCampaign.isWritable}
                checked={!!editedCampaign.replay}
                onChange={() => {
                  if (!editedCampaign.replay && editedCampaign.byPass) {
                    updateEditedCampaign({ replay: !editedCampaign.replay, byPass: false });
                  } else {
                    updateEditedCampaign({ replay: !editedCampaign.replay });
                  }
                }}
                label={
                  <Typography color="textSecondary" variant="body2">
                    {utils.getLang('smartmessaging.campaignInfos.replaySms')}
                  </Typography>
                }
              />
              <FormControlLabel
                control={<Checkbox style={{ pointerEvents: 'auto' }} />}
                disabled={!editedCampaign.isWritable}
                style={{ pointerEvents: 'none', margin: '0' }}
                checked={!!editedCampaign.byPass}
                onChange={() => {
                  if (editedCampaign.byPass)
                    updateEditedCampaign({ byPass: !editedCampaign.byPass });
                  else {
                    updateEditedCampaign({ byPass: !editedCampaign.byPass, replay: false });
                  }
                }}
                label={
                  <Typography color="textSecondary" variant="body2">
                    {utils.getLang('smartmessaging.campaignInfos.bypassSmsHours')}
                  </Typography>
                }
              />
              <hr className={classes.marginGap} />
            </div>
          </Paper>
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={e => {
                e.preventDefault();
                if (editedCampaign.isWritable) doSaveCampaign(true, showAlert);
                goToEditorStep(TARGET);
              }}
              disabled={
                !infosValidity.isValid || (!editedCampaign.requestModelTypeId && !selectedType)
              }
            >
              {utils.getLang('smartmessaging.buttonLabel.next')}
            </Button>
          </div>
        </div>
      </div>
    </Fade>
  );
};

CampaignInfosRender.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  updateEditedCampaign: PropTypes.func.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  wizVilTypes: PropTypes.array.isRequired,
  doSaveCampaign: PropTypes.func.isRequired,
  goToEditorStep: PropTypes.func.isRequired,
  infosValidity: PropTypes.object.isRequired,
  requestModelsByCategoryId: PropTypes.object.isRequired,
  sortedCategoryList: PropTypes.array.isRequired,
  selectCampaignType: PropTypes.func.isRequired,
  selectOptinType: PropTypes.func.isRequired,
  getDefaultOptin: PropTypes.func.isRequired,
  showWizvilleInfos: PropTypes.bool.isRequired,
};

CampaignInfosRender.defaultProps = {
  selectedType: null,
};

function CampaignInfos(props) {
  const {
    editedCampaign,
    selectedType,
    requestModelByRMTypeId,
    recipesById,
    wizVilleEntryActivated,
    wizVilleNewAboActivated,
    wizVilleCancelAboActivated,
    wizVilTypes,
    wizVilleNewAboTypes,
    wizVilleCancelAboTypes,
  } = props;

  function getDefaultOptin() {
    let selectedRM = null;

    if (editedCampaign.recipeId)
      selectedRM = requestModelByRMTypeId[recipesById[editedCampaign.recipeId].requestModelTypeId];
    else selectedRM = selectedType;
    return selectedRM.optinType;
  }

  function showWizvilleInfos() {
    if (
      wizVilleEntryActivated &&
      ((selectedType && wizVilTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
        (editedCampaign &&
          editedCampaign.recipeId &&
          wizVilTypes.indexOf(recipesById[editedCampaign.recipeId].requestModelTypeId) !== -1))
    )
      return true;
    if (
      wizVilleNewAboActivated &&
      ((selectedType && wizVilleNewAboTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
        (editedCampaign &&
          editedCampaign.recipeId &&
          wizVilleNewAboTypes.indexOf(recipesById[editedCampaign.recipeId].requestModelTypeId) !==
            -1))
    )
      return true;
    if (
      wizVilleCancelAboActivated &&
      ((selectedType && wizVilleCancelAboTypes.indexOf(selectedType.requestModelTypeId) !== -1) ||
        (editedCampaign &&
          editedCampaign.recipeId &&
          wizVilleCancelAboTypes.indexOf(
            recipesById[editedCampaign.recipeId].requestModelTypeId
          ) !== -1))
    )
      return true;
    return false;
  }

  return (
    <CampaignInfosRender
      {...props}
      {...{ getDefaultOptin }}
      showWizvilleInfos={showWizvilleInfos()}
    />
  );
}
CampaignInfos.propTypes = {
  editedCampaign: PropTypes.object.isRequired,
  selectedType: PropTypes.object,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  wizVilleEntryActivated: PropTypes.bool.isRequired,
  wizVilleNewAboActivated: PropTypes.bool.isRequired,
  wizVilleCancelAboActivated: PropTypes.bool.isRequired,
  wizVilTypes: PropTypes.array.isRequired,
  wizVilleNewAboTypes: PropTypes.array.isRequired,
  wizVilleCancelAboTypes: PropTypes.array.isRequired,
};

CampaignInfos.defaultProps = {
  selectedType: null,
};

export default CampaignInfos;
