import format from 'date-fns/format';
import utils from 'src/utils/utils';
import ParameterType from 'src/enums/parameterType';
import {
  CREATE_GROUP_MODE,
  CREATE_GROUP_CMP_SELECTOR,
  CREATE_GROUP_CMP_CONFIG,
} from './GroupCreator';

const getAppBarTitle = smjCreationStep => {
  switch (smjCreationStep) {
    case CREATE_GROUP_MODE:
      return utils.getLang('smartmessaging.campaigns.grouped.createMode.title');
    case CREATE_GROUP_CMP_SELECTOR:
      return utils.getLang('smartmessaging.campaigns.grouped.campaignsSelector.title');
    case CREATE_GROUP_CMP_CONFIG:
      return utils.getLang('smartmessaging.campaigns.grouped.campaignsConfig.title');
    default:
      return 'XXXXXXXXXXXXXx';
  }
};

const getDescriptors = createCmps => {
  const descsByKey = createCmps.reduce((r, c) => {
    const map = { ...r };
    c.descriptorList.forEach(ck => {
      if (!map[ck.key]) {
        map[ck.key] = ck;
      }
    });
    return map;
  }, {});
  return descsByKey;
};

const fillFieldSetupValue = (setupFieldValue, values, descriptor) => {
  const filledSetup = { ...setupFieldValue };
  switch (descriptor.type) {
    case 'CLUBS': {
      const getSubDescByKey = k => descriptor.descriptors.filter(d => d.key === k)[0];
      const keysToAdd = values.value.reduce(
        (m, v) => (m.indexOf(v.key) === -1 ? [...m, v.key] : m),
        []
      );
      filledSetup.values = keysToAdd.map(k => ({
        key: k,
        values: values.value.filter(v => v.key === k).map(vk => vk.id),

        java: !!getSubDescByKey(k).java,
        typeId: ParameterType[getSubDescByKey(k).type],
        operatorId: values.operatorValue,
      }));
      filledSetup.operatorId = values.operatorValue;
      return filledSetup;
    }
    default:
      filledSetup.value.push(values.value);
      filledSetup.operatorId = values.operatorValue;
      return filledSetup;
  }
};

const fillSetups = (cmpSelection, fieldsValues, period, language) => {
  const result = [];
  cmpSelection.forEach(cmp => {
    let setupValue = JSON.parse(cmp.setupJson);
    cmp.descriptorList.forEach(d => {
      const values = fieldsValues[d.key];
      setupValue = setupValue.map(setupFieldValue => {
        if (setupFieldValue.key === d.key) {
          return fillFieldSetupValue(setupFieldValue, values, d);
        }
        return setupFieldValue;
      });
    });
    result.push({
      ...cmp,
      descriptorList: [],
      setupJson: JSON.stringify(setupValue),
      periodStart: format(period.start, 'dd/MM/yyyy'),
      periodEnd: period.end ? format(period.end, 'dd/MM/yyyy') : null,
      periodTime: period.time,
      name: utils.getLang(`smartmessaging.campaigns.grouped.campaignName.${cmp.key}`),
      language,
    });
  });
  return result;
};

const mapSMJCmpModelMByType = campaigns => {
  const result = campaigns.reduce((m, cmp) => {
    if (!m[cmp.type]) return { ...m, [cmp.type]: [cmp] };
    m[cmp.type].push(cmp);
    return m;
  }, {});
  return result;
};

const getFieldValidity = (descriptor, value) => {
  const validity = { isValid: true, messages: [] };
  switch (descriptor.type) {
    case 'CLUBS': {
      // op is not null
      // concatened values length > O = value.values => concat eachItem.values
      const valueExists = !!value;
      const opIsValid = valueExists && !!value.operatorValue;
      if (!opIsValid)
        validity.messages.push(utils.getLang('smartmessaging.smartjourney.operatorIsEmpty'));
      const valIsValid =
        valueExists &&
        !!value.value &&
        !![].concat(...value.value.map(subvalue => subvalue.values)).length;
      if (!valIsValid)
        validity.messages.push(utils.getLang('smartmessaging.smartjourney.fieldIsEmpty'));
      validity.isValid = opIsValid && valIsValid;
      break;
    }
    default:
      break;
  }
  return validity;
};

export default {
  getAppBarTitle,
  getDescriptors,
  fillSetups,
  mapSMJCmpModelMByType,
  getFieldValidity,
};
