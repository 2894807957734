import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { Typography, AppBar, Toolbar, IconButton, Dialog, Tooltip } from '@mui/material';

import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import TestIcon from '@mui/icons-material/PlayCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import utils from 'src/utils/utils';
import massActionSelectors from 'src/selectors/massAction';
import { saveContent, sendContent, resetContentEditorFlowData } from 'src/reducers/massAction';
import { loaderActions } from 'src/reducers/loader';
import TestForm from 'src/components/common/TestForm';
import ActionType from 'src/enums/actionType';
import { useLocation } from 'wouter';
import { useAlert } from 'src/hooks';

const NewItems = ({ actions, classes, flowData, actionType }) => (
  <>
    <div
      style={{
        boxShadow: 'inset -0.3px 0px 0px 0px #ffffffd5',
        padding: '0 8px',
      }}
    />
    <div
      style={{
        boxShadow: 'inset -0.3px 0px 0px 0px #ffffffd5',
        padding: '0 8px',
      }}
    >
      <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.saveActionMessage')}>
        <span>
          <IconButton
            aria-label="clear"
            disabled={
              !flowData.contentValidity.isValid ||
              (!flowData.isNewContent && !flowData.hasChanges) ||
              !flowData.hasChanges
            }
            classes={{ disabled: classes.disabledBtn }}
            onClick={e => {
              e.preventDefault();
              actions.onSaveContent();
            }}
            size="large"
          >
            <SaveIcon color="secondary" />
          </IconButton>
        </span>
      </Tooltip>
    </div>
    {[ActionType.MEMBER_NOTIFICATION.id].indexOf(actionType) === -1 && (
      <div
        style={{
          boxShadow: 'inset -0.3px 0px 0px 0px #ffffffd5',
          padding: '0 8px',
        }}
      >
        <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.testContent')}>
          <span>
            <IconButton
              aria-label="clear"
              classes={{ disabled: classes.disabledBtn }}
              disabled={flowData.isNewContent || flowData.hasChanges}
              onClick={e => {
                e.preventDefault();
                actions.showTestForm(true);
              }}
              size="large"
            >
              <TestIcon color="secondary" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    )}
    <div
      style={{
        padding: '0 8px',
      }}
    >
      <Tooltip title={utils.getLang('smartmessaging.massAction.navButton.send')}>
        <span>
          <IconButton
            aria-label="clear"
            classes={{ disabled: classes.disabledBtn }}
            disabled={
              (!(actionType === ActionType.MEMBER_NOTIFICATION.id) &&
                flowData.diffusionTypeIsEmpty) ||
              flowData.isNewContent ||
              flowData.hasChanges
            }
            onClick={e => {
              e.preventDefault();
              actions.onSendContent();
            }}
            size="large"
          >
            <SendIcon color="secondary" />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  </>
);
NewItems.propTypes = {
  actions: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  flowData: PropTypes.object.isRequired,
  actionType: PropTypes.number.isRequired,
};

const useStyles = makeStyles()(theme => ({
  disabledBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.light,
    opacity: 0.7,
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
}));
function MessageActionAppBar({
  actionType,
  doSaveContent,
  doSendContent,
  contentValidity,
  hasChanges,
  isNewContent,
  diffusionTypeIsEmpty,
  currentName,
  currentMassActionId,
  setLoader,
  doResetContentEditorFlowData,
  forceIsNewContent,
  clearEditedData,
  actionId,
  contentFields,
  currentCampaignAction,
  isWritable,
  currentAppLn,
}) {
  const [testFormIsVisible, showTestForm] = useState(false);
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  const { showAlert } = useAlert();

  async function onSaveContent() {
    setLoader(true);
    await doSaveContent(actionType, showAlert)
      .then(() => {
        forceIsNewContent(false);
      })
      .finally(() => {
        setLoader(false);
      });
  }

  async function onSendContent() {
    setLoader(true);
    await doSendContent(
      actionType,
      () => {
        navigate(`/oneoff/${currentMassActionId}/history`);
        forceIsNewContent(false);
        doResetContentEditorFlowData();
        clearEditedData();
      },
      showAlert
    ).finally(() => setLoader(false));
  }

  return (
    <>
      <Dialog open={testFormIsVisible} fullWidth>
        <AppBar className={classes.appBar} color="primary">
          <Toolbar>
            <Typography variant="h5" color="inherit" style={{ flex: 1 }}>
              {utils.getLang('smartmessaging.campaignEditor.testAction.popupTitle')}
            </Typography>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                showTestForm(false);
              }}
              size="large"
            >
              <CloseIcon color="secondary" />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            minHeight: '300px',
          }}
        >
          <TestForm
            forcedFields={{ field0: 'INTEGER', ...contentFields }}
            includeWizVille
            campaignActionId={(currentCampaignAction && currentCampaignAction.id) || actionId}
            actionTypeId={actionType}
            currentAppLn={currentAppLn}
          />
        </div>
      </Dialog>

      <AppBar className={classes.appBar} color="primary">
        <Toolbar>
          <div className={classes.flex}>
            <Typography variant="h5" color="inherit">
              {utils.getLang(`smartmessaging.contentEditor.title.${actionType}`)}
            </Typography>
            <Typography variant="body2" color="inherit">
              {currentName}
              {!!currentMassActionId && ` (${currentMassActionId})`}
            </Typography>
          </div>
          <NewItems
            classes={classes}
            actionType={actionType}
            flowData={{
              contentValidity,
              isNewContent,
              hasChanges,
              diffusionTypeIsEmpty,
            }}
            actions={{ onSaveContent, onSendContent, showTestForm }}
            isWritable={isWritable}
          />
        </Toolbar>
      </AppBar>
    </>
  );
}

MessageActionAppBar.propTypes = {
  actionType: PropTypes.number.isRequired,
  doSaveContent: PropTypes.func.isRequired,
  doSendContent: PropTypes.func.isRequired,
  hasChanges: PropTypes.bool.isRequired,
  isNewContent: PropTypes.bool.isRequired,
  diffusionTypeIsEmpty: PropTypes.bool.isRequired,
  currentName: PropTypes.string.isRequired,
  setLoader: PropTypes.func.isRequired,
  doResetContentEditorFlowData: PropTypes.func.isRequired,
  forceIsNewContent: PropTypes.func.isRequired,
  clearEditedData: PropTypes.func.isRequired,
  contentFields: PropTypes.object,
  actionId: PropTypes.number,
  currentMassActionId: PropTypes.number.isRequired,
  isWritable: PropTypes.bool.isRequired,
  currentCampaignAction: PropTypes.object,
  contentValidity: PropTypes.object.isRequired,
  currentAppLn: PropTypes.object.isRequired,
};

MessageActionAppBar.defaultProps = {
  contentFields: {},
  actionId: null,
  currentCampaignAction: null,
};

const mapStateToProps = state => ({
  editor: state.massAction.editor,
  objectEditor: state.massAction.objectEditor,
  contentValidity: state.massAction.contentValidity,
  hasChanges: state.massAction.contentHasChanges,
  isNewContent: massActionSelectors.isNewContent(state),
  diffusionTypeIsEmpty: !state.massAction.selectedDiffusionType,
  currentName: state.massAction.currentName,
  currentMassActionId: state.massAction.currentMassActionId,
  massActionRm: state.massAction.requestModel,
  contentFields: massActionSelectors.getContentFields(state),
  currentCampaignAction: state.massAction.currentCampaignAction,
  currentAppLn: state.app.currentLanguage,
});

export default connect(mapStateToProps, {
  doResetContentEditorFlowData: resetContentEditorFlowData,
  doSendContent: sendContent,
  doSaveContent: saveContent,
  setLoader: visible => loaderActions.setLoader(visible),
  forceIsNewContent: force => ({
    type: 'SET_FORCE_IS_NEW_CONTENT',
    value: force,
  }),
  clearEditedData: () => ({
    type: 'CLEAR_EDITED_DATA',
  }),
})(MessageActionAppBar);
