import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import uuid from 'uuid';
import utils from 'src/utils/utils';
import hUtils from './utils';
import ParameterType from 'src/enums/parameterType';
import { OperatorTypeById } from 'src/enums/operatorType';
import UsedFilterValue from './UsedFilterValue';

const UsedFilterRender = ({ filter, filtersByKey }) => (
  <div style={{ textAlign: 'left' }}>
    {filtersByKey[filter.key].groupType ? (
      <>
        <Typography
          component="span"
          color="textSecondary"
          variant="body2"
          style={{
            display: 'inline',
          }}
        >
          {utils.getLang(
            `smartmessaging.massAction.groupFilterBy.${filtersByKey[filter.key].groupType}`
          )}
        </Typography>
        <Typography
          component="span"
          color="textSecondary"
          variant="body2"
          style={{
            display: 'inline',
          }}
        >
          {utils.getLang(`smartmessaging.massAction.filterLabel.${filter.key}`)}
        </Typography>
      </>
    ) : (
      <Typography
        component="span"
        color="textSecondary"
        variant="body2"
        style={{
          display: 'inline',
        }}
      >
        {utils.getLang(`smartmessaging.history.filterLabel.${filter.key}`)}
      </Typography>
    )}
    <Typography
      component="span"
      color="textSecondary"
      variant="body2"
      style={{
        display: 'inline',
      }}
    >
      {' '}
      {utils.getLang(
        `smartmessaging.recipeSetupOperator.${OperatorTypeById[filter.operatorId]}`
      )}{' '}
    </Typography>
    <UsedFilterValue filter={filter} filtersByKey={filtersByKey} />
  </div>
);

const GroupUsedFilter = ({ filters, filtersByKey }) =>
  Object.values(filters).map(list =>
    list.map(f => <UsedFilterRender key={uuid()} filter={f} filtersByKey={filtersByKey} />)
  );

UsedFilterRender.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

const UsedFilter = ({ filter, filtersByKey }) => {
  switch (filter.typeId) {
    case ParameterType.GROUP:
    case ParameterType.CLUBS:
    case ParameterType.LINKED_GROUP:
      return (
        <GroupUsedFilter
          filters={hUtils.proceedSetup(filter.values)}
          filtersByKey={filtersByKey[filter.key].descriptors.reduce(
            (map, f) => ({ ...map, [f.key]: f }),
            {}
          )}
        />
      );
    default:
      return <UsedFilterRender filter={filter} filtersByKey={filtersByKey} />;
  }
};
UsedFilter.propTypes = {
  filter: PropTypes.object.isRequired,
  filtersByKey: PropTypes.object.isRequired,
};

export default UsedFilter;
