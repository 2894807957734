import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  Switch,
  Paper,
  TableBody,
  TableCell,
  TableRow,
  Table,
  Fade,
  Typography,
  Tooltip,
  alpha,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import CampaignListHead from './CampaignListHead';
import CampaignItemMenu from './CampaignItemMenu';
import SMJEditorAppBar from '../SMJEditorAppBar';
import Search from 'src/components/common/filters/Search';
import { activateCampaign, getCsv, deleteCampaign } from 'src/reducers/campaignList';
import { editCampaign } from 'src/reducers/campaignEditor';
import utils from 'src/utils/utils';
import {
  filterFn,
  getColumnData,
  quickFilterFn,
} from 'src/components/common/filters/campaignFiltersUtils';

import filtrable from 'src/components/common/hocs/filtrable';
import { useLocation } from 'wouter';
import { useAlert } from 'src/hooks';

const PendingEl = () => <i>{utils.getLang('smartmessaging.campaignList.pendingElement')}</i>;

const SpanedTableCell = ({ children, style }) => (
  <TableCell style={style}>
    <span className="cellspan">{children}</span>
  </TableCell>
);
SpanedTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  style: PropTypes.object,
};

SpanedTableCell.defaultProps = {
  style: null,
};

const CampaignItemRender = ({
  campaign,
  doGetCsv,
  doActivateCampaign,
  doDeleteCampaign,
  selected,
  selectRow,
  requestModelByRMTypeId,
  recipesById,
  updateCmp,
  removeCmp,
  groupIsWritable,
  classes,
}) => {
  const [, navigate] = useLocation();
  const { showAlert } = useAlert();

  const requestModelIsMissing = useMemo(
    () =>
      !(
        Boolean(campaign.recipeId) &&
        Boolean(requestModelByRMTypeId[recipesById[campaign.recipeId]?.requestModelTypeId])
      ),
    [campaign, recipesById, requestModelByRMTypeId]
  );

  const triggerLabel = useMemo(() => {
    if (requestModelIsMissing) {
      return <PendingEl />;
    }
    return campaign.eventDriven === false
      ? utils.getLang(
          `smartmessaging.campaignTrigger.time.${
            requestModelByRMTypeId[recipesById[campaign.recipeId].requestModelTypeId].periodicity
          }`
        )
      : campaign.eventDriven === true && utils.getLang('smartmessaging.campaignTrigger.action');
  }, [campaign, recipesById, requestModelByRMTypeId, requestModelIsMissing]);

  const requestModelName = useMemo(() => {
    if (requestModelIsMissing) {
      return <PendingEl />;
    }
    return utils.getLang(
      `smartmessaging.requestmodel.label.${
        requestModelByRMTypeId[recipesById[campaign.recipeId].requestModelTypeId].name
      }`
    );
  }, [campaign, recipesById, requestModelByRMTypeId, requestModelIsMissing]);

  return (
    <TableRow
      hover
      classes={classes}
      selected={selected}
      onClick={e => {
        e.preventDefault();
        selectRow(campaign.id);
      }}
      onDoubleClick={e => {
        e.preventDefault();
        navigate(`/${campaign.id}/edit`);
      }}
    >
      <TableCell>
        <CampaignItemMenu
          options={[
            {
              name: utils.getLang('smartmessaging.campaignList.action.edit'),
              action: async () => {
                const asyncEdit = async () => {
                  navigate(`/${campaign.id}/edit`);
                };
                asyncEdit();
              },
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.csv'),
              action: () => doGetCsv(campaign, showAlert),
              disabled: !campaign.recipeId,
              hidden: campaign.eventDriven,
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.delete'),
              action: () => {
                doDeleteCampaign(campaign, { afterDelete: () => removeCmp(campaign) }, showAlert);
              },

              hidden: !groupIsWritable,
            },
            {
              name: utils.getLang('smartmessaging.campaignList.action.showHistory'),
              hidden: !campaign.requestModelTypeId,
              action: () => {
                navigate(`/${campaign.id}/history`);
              },
            },
          ]}
          selectRow={selectRow}
          campaign={campaign}
        />
      </TableCell>
      <TableCell scope="row" style={{ fontWeight: 'bold' }}>
        <div style={{ display: 'flex' }}>
          {!!campaign.duplicates.length && (
            <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
              <Tooltip title={utils.getLang('smartmessaging.campaignAction.duplicateWarning')}>
                <WarningIcon color="error" />
              </Tooltip>
            </div>
          )}
          <div style={{ display: 'flex', alignItems: 'center', margin: '2px' }}>
            {campaign.name}
          </div>
        </div>
      </TableCell>
      <SpanedTableCell>{requestModelName}</SpanedTableCell>
      <SpanedTableCell>{triggerLabel}</SpanedTableCell>
      <SpanedTableCell>
        {campaign.actionType ? campaign.actionType : <PendingEl />}
      </SpanedTableCell>{' '}
      <SpanedTableCell>{new Date(campaign.creationDate).toLocaleDateString()}</SpanedTableCell>
      {utils.isNodeMapped() && (
        <SpanedTableCell>{campaign.networkNodeName || '--'}</SpanedTableCell>
      )}
      <SpanedTableCell>{campaign.creationUserName || '--'}</SpanedTableCell>
      <SpanedTableCell>
        {campaign.isPlanned
          ? utils.getLang('smartmessaging.campaignList.item.isPlanned')
          : utils.getLang('smartmessaging.campaignList.item.isNotPlanned')}
      </SpanedTableCell>
      <SpanedTableCell padding="checkbox">
        <Switch
          value={campaign.enabled ? '1' : '0'}
          checked={campaign.enabled}
          onClick={() => {
            doActivateCampaign(campaign, upToDateCmp => updateCmp(upToDateCmp), showAlert);
          }}
        />
      </SpanedTableCell>
    </TableRow>
  );
};

CampaignItemRender.propTypes = {
  classes: PropTypes.object.isRequired,
  campaign: PropTypes.object.isRequired,
  recipesById: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  selectRow: PropTypes.func.isRequired,
  doGetCsv: PropTypes.func.isRequired,
  doActivateCampaign: PropTypes.func.isRequired,
  doDeleteCampaign: PropTypes.func.isRequired,
  requestModelByRMTypeId: PropTypes.object.isRequired,
  updateCmp: PropTypes.func.isRequired,
  removeCmp: PropTypes.func.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
};

const CampaignItem = connect(null, {
  doDeleteCampaign: deleteCampaign,
  doEditCampaign: editCampaign,
  doActivateCampaign: activateCampaign,
  doGetCsv: getCsv,
})(CampaignItemRender);

const useStyles = makeStyles()((theme, _params, classes) => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  campaignContainer: {
    overflow: 'auto',
  },
  tableRowRoot: {
    [`&.${classes.tableRowSelected}, &.${classes.tableRowSelected}:hover`]: {
      backgroundColor: alpha(theme.palette.grey[700], theme.palette.action.selectedOpacity),
    },
  },
  tableRowSelected: {
    backgroundColor: alpha(theme.palette.grey[700], theme.palette.action.selectedOpacity),
  },
}));

function CampaignList({
  filteredData,
  doFilter,
  addFilter,
  recipesById,
  removeFilter,
  clearFilters,
  selectedFilters,
  requestModelByRMTypeId,
  smartjourney,
  updateCmp,
  removeCmp,
  groupIsWritable,
}) {
  const [selected, setSelected] = useState(null);
  const [quickFilter, setQuickFilter] = useState('');
  const [sort, setSort] = useState({ order: 'asc', orderBy: 'creationDate', sortFns: false });
  const { classes } = useStyles();

  const selectRow = useCallback(id => {
    setSelected(id);
  }, []);

  const updateQuickFilter = useCallback(value => {
    setQuickFilter(value);
  }, []);

  function quickFiltered() {
    const quickFilters = [
      { key: 'name', value: quickFilter },
      {
        key: 'recipeId',
        value: quickFilter,
        type: 'TEXT',
        convertValue: recipeId =>
          (recipeId &&
            requestModelByRMTypeId[recipesById[recipeId].requestModelTypeId] &&
            utils.getLang(
              `smartmessaging.requestmodel.label.${
                requestModelByRMTypeId[recipesById[recipeId].requestModelTypeId].name
              }`
            )) ||
          utils.getLang('smartmessaging.campaignList.pendingElement'),
      },
    ];
    if (quickFilter) {
      return filteredData.filter(quickFilterFn(quickFilters));
    }
    return filteredData;
  }

  function getSortedCampaigns() {
    if (!sort.sortFns) {
      return sort.order === 'asc'
        ? quickFiltered().sort((a, b) => (a[sort.orderBy] < b[sort.orderBy] ? -1 : 1))
        : quickFiltered().sort((a, b) => (a[sort.orderBy] < b[sort.orderBy] ? 1 : -1));
    }

    return sort.order === 'asc'
      ? quickFiltered().sort(sort.sortFns.asc)
      : quickFiltered().sort(sort.sortFns.desc);
  }

  const sortCampaigns = useCallback(
    (orderBy, sortFns) => {
      setSort({
        order: orderBy === sort.orderBy && sort.order === 'desc' ? 'asc' : 'desc',
        orderBy,
        sortFns,
      });
    },
    [sort]
  );

  return (
    <>
      <Fade in timeout={1000}>
        <div style={{ textAlign: 'left', margin: '8px' }}>
          <Search
            updateQuickFilter={updateQuickFilter}
            filterProps={{
              filteredData,
              doFilter,
              addFilter,
              removeFilter,
              clearFilters,
              selectedFilters,
            }}
          />
        </div>
      </Fade>
      <Fade in timeout={1000}>
        <div className={`${classes.campaignContainer}`}>
          <Paper className={classes.root} elevation={0}>
            <div className={classes.tableWrapper}>
              <Table aria-labelledby="tableTitle" stickyHeader>
                <CampaignListHead
                  columsConfigs={getColumnData({ requestModelByRMTypeId, recipesById })}
                  order={sort.order}
                  orderBy={sort.orderBy}
                  onRequestSort={sortCampaigns}
                  addFilter={addFilter}
                />
                <TransitionGroup component={TableBody}>
                  {getSortedCampaigns().map(campaign => (
                    <CSSTransition timeout={300} classNames="fade" key={campaign.id}>
                      <CampaignItem
                        classes={{ selected: classes.tableRowSelected, root: classes.tableRowRoot }}
                        campaign={campaign}
                        selected={selected === campaign.id}
                        selectRow={selectRow}
                        recipesById={recipesById}
                        requestModelByRMTypeId={requestModelByRMTypeId}
                        smartjourney={smartjourney}
                        updateCmp={updateCmp}
                        removeCmp={removeCmp}
                        groupIsWritable={groupIsWritable}
                      />
                    </CSSTransition>
                  ))}
                </TransitionGroup>
              </Table>
            </div>
          </Paper>
        </div>
      </Fade>
    </>
  );
}

CampaignList.propTypes = {
  requestModelByRMTypeId: PropTypes.object.isRequired,
  smartjourney: PropTypes.object.isRequired,
  updateCmp: PropTypes.func.isRequired,
  removeCmp: PropTypes.func.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  filteredData: PropTypes.array.isRequired,
  doFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  recipesById: PropTypes.object.isRequired,
  removeFilter: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  selectedFilters: PropTypes.array.isRequired,
};

const FiltrableCampaignList = filtrable(props => ({
  filterFn,
  filtrableData: props.campaigns,
}))(CampaignList);

const Container = ({
  groupIsWritable,
  smartjourney,
  updateSmjName,
  updateSmjList,
  campaignGroups,
  updateCampaign,
  removeCampaign,
  campaigns,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <>
      <SMJEditorAppBar
        groupIsWritable={groupIsWritable}
        smj={smartjourney}
        updateSmjName={name => {
          updateSmjName(name, renamed => {
            updateSmjList(campaignGroups.map(cg => (cg.id === renamed.id ? renamed : cg)));
          });
        }}
      />

      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'auto',
          textAlign: 'left',
        }}
      >
        {campaigns.length ? (
          <FiltrableCampaignList
            campaigns={campaigns}
            updateCmp={updateCampaign}
            removeCmp={removeCampaign}
            groupIsWritable={groupIsWritable}
            smartjourney={smartjourney}
            {...rest}
          />
        ) : (
          <div
            style={{
              flex: 1,
              textAlign: 'center',
              paddingTop: '24px',
              backgroundColor: theme.palette.common.white,
            }}
          >
            <Typography
              style={{
                color: theme.palette.secondary.main,
              }}
            >
              {utils.getLang('smartmessaging.campaigns.grouped.list.emptyGroup')}
            </Typography>
          </div>
        )}
      </div>
    </>
  );
};

Container.propTypes = {
  groupIsWritable: PropTypes.bool.isRequired,
  smartjourney: PropTypes.object.isRequired,
  updateSmjList: PropTypes.func.isRequired,
  updateSmjName: PropTypes.func.isRequired,
  campaignGroups: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  updateCampaign: PropTypes.func.isRequired,
  removeCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recipesById: state.recipeList.recipeListById,
  campaignGroups: state.smartjourney.smjListsByModel,
});

const actionCreators = {
  updateSmjList: list => ({ type: 'RECEIVE_SMJ_LIST', value: list }),
};

export default connect(mapStateToProps, actionCreators)(Container);
