import React from 'react';
import { useSelector } from 'react-redux';
import { useRequestLogin } from 'src/reducers/auth';
import LoginScreen from './LoginScreen';
import NotFound from '../common/NotFound';

function Logger() {
  useRequestLogin();
  const authFailed = useSelector(state => state.auth.authFailed);
  return authFailed ? <NotFound text="Echec de l'authentification" /> : <LoginScreen />;
}

export default Logger;
