import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { useTheme } from '@mui/material/styles';
import {
  Toolbar,
  Typography,
  AppBar,
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';

import ClearIcon from '@mui/icons-material/Cancel';

import utils from 'src/utils/utils';
import { useLocation } from 'wouter';

const useStyles = makeStyles()(theme => ({
  createBtn: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
  },
  uppercase: { textTransform: 'uppercase' },
  appBar: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
  },
  flex: { flex: 1 },
  search: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  tableWrapper: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    overflow: 'auto',
    flex: 1,
  },
  name: { ...theme.typography.h5 },
}));

const TitleField = ({ smj, updateSmjName, groupIsWritable }) => {
  const [isNameFocused, setIsNamedFocused] = React.useState(false);
  const [name, setName] = React.useState(smj.name);
  const theme = useTheme();
  function isNameValid() {
    return !!name;
  }

  return !isNameFocused ? (
    <Typography
      color="textPrimary"
      variant="body1"
      onClick={() => {
        if (groupIsWritable) setIsNamedFocused(true);
      }}
    >
      {name}
    </Typography>
  ) : (
    <TextField
      autoFocus
      InputProps={{
        color: theme.palette.text.primary,
        style: theme.typography.body1,
        fullWidth: true,
        endAdornment: (
          <InputAdornment position="start">
            <IconButton
              disabled={!isNameValid()}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                updateSmjName(name);
                setIsNamedFocused(false);
              }}
              edge="end"
              size="large"
            >
              <DoneIcon style={{ fill: '#b6b6b6' }} />
            </IconButton>
            <IconButton
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setName(smj.name);
                setIsNamedFocused(false);
              }}
              edge="end"
              size="large"
            >
              <ClearIcon style={{ fill: '#b6b6b6' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      value={name}
      onChange={event => setName(event.target.value)}
    />
  );
};

TitleField.propTypes = {
  smj: PropTypes.object.isRequired,
  groupIsWritable: PropTypes.bool.isRequired,
  updateSmjName: PropTypes.func.isRequired,
};

const SMJEditorAppBar = ({ smj, updateSmjName, groupIsWritable }) => {
  const { classes } = useStyles();
  const [, navigate] = useLocation();
  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <div>
          <Typography color="inherit" variant="body2" className={classes.uppercase}>
            {utils.getLang('smartmessaging.smartjourney.editor.campaignList.title')}
          </Typography>
          <TitleField smj={smj} updateSmjName={updateSmjName} groupIsWritable={groupIsWritable} />
        </div>
        <div
          style={{
            flex: 1,
            marginLeft: '16px',
          }}
        />
        {groupIsWritable && (
          <Tooltip title={utils.getLang('smartmessaging.tooltip.campaigns.grouped.addCampaign')}>
            <IconButton
              aria-label="clear"
              onClick={e => {
                e.preventDefault();
                navigate('/create');
              }}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    </AppBar>
  );
};

SMJEditorAppBar.propTypes = {
  groupIsWritable: PropTypes.bool.isRequired,
  smj: PropTypes.object.isRequired,
  updateSmjName: PropTypes.func.isRequired,
};

export default SMJEditorAppBar;
