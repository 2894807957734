import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { Redirect, Route, Switch, useSearch } from 'wouter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Theme, createTheme, ThemeProvider } from '@mui/material/styles';

import rootReducer from '../reducers';
import Main from './main/Main';
import NotFound from './common/NotFound';
import ModalLoader from './common/ModalLoader';
import InvitationQRCode from './InvitationQRCodePage';
import { useBrowserLocation } from 'wouter/use-browser-location';
import { AlertProvider } from 'src/hooks/useAlert';

const palette = {
  primary: {
    main: '#27bbc5',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#ffffff',
    contrastText: '#737373',
  },
  success: { main: '#27bbc5' },
  warning: { main: '#d93025', light: '#fce8e6', contrastText: '#ffffff' },
  error: { main: '#d93025' },
  text: {
    primary: '#5F5F5F',
    secondary: '#737373',
  },
};

const myTheme: Theme = createTheme(
  {
    palette,
    typography: {
      fontFamily: '"Ubuntu", "Helvetica", "Arial", sans-serif',
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: { height: '100px' },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: { padding: '4px 56px 4px 24px', fontSize: '0.815rem' },
        },
      },
      MuiInputAdornment: { styleOverrides: { root: { height: 'unset' } } },
      MuiIconButton: { styleOverrides: { root: { '&$disabled': { opacity: 0.7 } } } },
      MuiInput: {
        styleOverrides: {
          input: {
            padding: '6px 2px',
          },
          underline: {
            borderBottomColor: palette.primary.main,
            '&:after': {
              backgroundColor: palette.primary.main,
              borderBottomColor: palette.primary.main,
            },
            '&:hover:not($disabled):before': {
              backgroundColor: palette.primary.main,
              opacity: 0.5,
              height: 1,
              borderBottomColor: palette.primary.main,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            color: palette.text.secondary,
          },
          root: {
            '&$focused': {
              '& $notchedOutline': {
                borderColor: palette.primary.main,
              },
            },
          },
        },
      },
    },
  },
  {
    border: {
      primary: '1px solid #e0e0e0',
    },
  }
);

const store = configureStore({ reducer: rootReducer });

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false } },
});

const AuthPathRedirect = () => {
  const [location] = useBrowserLocation();
  const newLocation = location.replace('auth', 'app');
  const search = `?${useSearch()}`;
  return <Redirect to={newLocation + search} />;
};

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={myTheme}>
          <AlertProvider>
            <Switch>
              <Route path="/auth/*">
                <AuthPathRedirect />
              </Route>
              <Route path="/app/:clientToken" nest>
                <Main />
              </Route>
              <Route
                path="/invitation/:clientToken/:identificatorId/:signature"
                component={InvitationQRCode}
              />
              <Route path="/error">
                <NotFound />
              </Route>
              <Route
                path="/*"
                component={({ params }) => <NotFound text={params ? params['*'] : undefined} />}
              />
            </Switch>
          </AlertProvider>
          <ModalLoader />
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
