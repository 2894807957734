import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import smjUtils from './utils';

import withSteps from 'src/components/common/hocs/withSteps';

import GroupCreateCmpSelector from './GroupCreateCmpSelector';
import SMJConfig from './SMJConfig';
import SMJCreatorAppBar from './SMJCreatorAppBar';
import NotFound from 'src/components/common/NotFound';
import GroupCreateMode from './GroupCreateMode';

export const [CREATE_GROUP_MODE, CREATE_GROUP_CMP_SELECTOR, CREATE_GROUP_CMP_CONFIG] = [
  'group-create-mode',
  'create-group-cmp-selector',
  'create-group-cmp-config',
];

function GroupCreator({
  currentStep,
  goToStep,
  generateSMJ,
  goToSMJStep,
  currentLanguage,
  ...otherProps
}) {
  const [name, setName] = useState('');
  const [groupModel, setGroupModel] = useState(null);
  const [mode, setMode] = useState('empty');
  const [campaignSelection, setCampaignSelection] = useState([]);
  const [language, setLanguage] = useState(currentLanguage);

  const getSelectedCmps = useCallback(() => {
    if (groupModel)
      return groupModel.campaigns.filter(cmp => campaignSelection.indexOf(cmp.key) !== -1);
    return [];
  }, [groupModel, campaignSelection]);

  const handleSelectCampaign = useCallback(
    (cmpkey, add) => {
      if (!add)
        setCampaignSelection(campaignSelection.filter(selecteditem => selecteditem !== cmpkey));
      else setCampaignSelection([...campaignSelection, cmpkey]);
    },
    [campaignSelection]
  );

  switch (currentStep) {
    case CREATE_GROUP_MODE:
      return (
        <GroupCreateMode
          {...otherProps}
          newGroupSetup={{ name, groupModel, mode, campaignSelection, language }}
          goToCreateStep={goToStep}
          getSelectedCmps={getSelectedCmps}
          onGroupModelChange={setGroupModel}
          onModeChange={setMode}
          onNameChange={setName}
          createEmptyGroup={() => {
            generateSMJ({
              smj: { name, smartJourneyModelId: null },
              cmpList: [],
              language,
            }); // campaigns must have good setup and name
          }}
          setupPremadeGroup={() => goToStep(CREATE_GROUP_CMP_SELECTOR)}
        />
      );

    case CREATE_GROUP_CMP_SELECTOR:
      return (
        <GroupCreateCmpSelector
          {...otherProps}
          newGroupSetup={{ name, groupModel, mode, campaignSelection, language }}
          getSelectedCmps={getSelectedCmps}
          goToSMJCreatorStep={goToStep}
          onSelect={handleSelectCampaign}
          onBack={() => goToStep(CREATE_GROUP_MODE)}
          toSMJConfig={() => {
            goToStep(CREATE_GROUP_CMP_CONFIG);
          }}
        />
      );

    case CREATE_GROUP_CMP_CONFIG:
      return (
        <SMJConfig
          {...otherProps}
          newGroupSetup={{ name, groupModel, mode, campaignSelection, language }}
          getSelectedCmps={getSelectedCmps}
          onBack={() => goToStep(CREATE_GROUP_CMP_SELECTOR)}
          goToSMJCreatorStep={goToStep}
          onLanguageChange={setLanguage}
          onCreate={(fieldsValues, period) => {
            generateSMJ({
              smj: { name, smartJourneyModelId: groupModel.id },
              cmpList: smjUtils.fillSetups(getSelectedCmps(), fieldsValues, period),
              language,
            }); // campaigns must have good setup and name
          }}
        />
      );
    default:
      return <NotFound />;
  }
}

GroupCreator.propTypes = {
  currentStep: PropTypes.string.isRequired,
  goToStep: PropTypes.func.isRequired,
  generateSMJ: PropTypes.func.isRequired,
  goToSMJStep: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

const GroupCreatorCtn = props => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflow: 'hidden',
      textAlign: 'left',
      height: '100%',
    }}
  >
    <SMJCreatorAppBar title={smjUtils.getAppBarTitle(props.currentStep)} />
    <GroupCreator {...props} />
  </div>
);

GroupCreatorCtn.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default withSteps(GroupCreatorCtn);
