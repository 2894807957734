import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import { AppBar, Button, Fade, Toolbar, Typography } from '@mui/material';

import uuid from 'uuid';

import utils from 'src/utils/utils';
import getApiCaller from 'src/utils/apiClientCaller';
import apiClient from 'src/utils/apiClient';
import withDataResolver from 'src/components/common/withDataResolver';

import ActionItem from './ActionItem';
import CsvExportButton from '../requestResultList/MassActionCsvExportButton';
import { REQUEST_RESULTS } from '../MassActionContent';

const useStyles = makeStyles()(() => ({
  appBar: {
    position: 'relative',
    textAlign: 'left',
  },
  flex: { flex: 1 },
}));

const StyledAppBar = ({ currentName, massActionId }) => {
  const { classes } = useStyles();
  return (
    <AppBar className={classes.appBar} color="primary">
      <Toolbar style={{ paddingLeft: '8px' }}>
        <div className={classes.flex}>
          <Typography variant="h5" color="inherit">
            {utils.getLang(`smartmessaging.massAction.contentSelector.title`)}
          </Typography>
          <Typography variant="body2" color="inherit">
            {currentName}
            {!!massActionId && ` (${massActionId})`}
          </Typography>
        </div>
        <div
          style={{
            boxShadow: 'inset 0.3px 0px 0px 0px #ffffffd5, inset -0.3px 0px 0px 0px #ffffffd5',
            marginLeft: '8px',
            marginRight: '8px',
            padding: '0 8px',
          }}
        >
          <CsvExportButton />
        </div>
      </Toolbar>
    </AppBar>
  );
};

StyledAppBar.propTypes = {
  currentName: PropTypes.string.isRequired,
  massActionId: PropTypes.number.isRequired,
};

const MassActionContentSelector = ({
  isWritable,
  contents,
  setContents,
  goToStep,
  currentName,
  massActionId,
}) => (
  <div style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '100%' }}>
    <StyledAppBar
      goToStep={goToStep}
      currentName={currentName}
      isWritable={isWritable}
      massActionId={massActionId}
    />
    <Fade in timeout={500}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            margin: '48px 15% 0',
          }}
        >
          <div
            style={{
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                display: 'inline-flex',
                overflow: 'auto',
              }}
            >
              {Object.entries(contents).map(([actionTypeId, contentsByType]) =>
                !contentsByType.length ? (
                  <ActionItem
                    isWritable={isWritable}
                    key={uuid()}
                    actionTypeId={parseInt(actionTypeId, 10)}
                    action={null}
                    goToStep={goToStep}
                  />
                ) : (
                  contentsByType.map(content => (
                    <ActionItem
                      isWritable={isWritable}
                      key={uuid()}
                      actionTypeId={parseInt(actionTypeId, 10)}
                      action={content}
                      deleteAction={() => {
                        setContents({
                          ...contents,
                          [actionTypeId]: contents[actionTypeId].filter(ct => content.id !== ct.id),
                        });
                      }}
                      goToStep={goToStep}
                    />
                  ))
                )
              )}
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              variant="text"
              color="primary"
              style={{ margin: '2px' }}
              onClick={e => {
                e.preventDefault();
                goToStep(REQUEST_RESULTS);
              }}
            >
              {utils.getLang('smartmessaging.buttonLabel.previous')}
            </Button>
          </div>
        </div>
      </div>
    </Fade>
  </div>
);

const resolvers = {
  resolve: props => async callApi => {
    const allContents = await callApi(apiClient.loadCampaignActions, [props.massActionId]);
    const availaibleActions = await callApi(apiClient.listAvailableActionsForRequestModel, [
      props.massActionRequestModelId,
    ]);
    const contents = {};
    availaibleActions.forEach(aa => {
      contents[aa.id] = allContents.filter(content => content.actionTypeId === aa.id);
    });

    return { contents };
  },
  onResolved: props => async resolvedData => {
    props.setContents(resolvedData.contents);
  },
};

MassActionContentSelector.propTypes = {
  isWritable: PropTypes.bool.isRequired,
  contents: PropTypes.object.isRequired,
  setContents: PropTypes.func.isRequired,
  goToStep: PropTypes.func.isRequired,
  currentName: PropTypes.string.isRequired,
  massActionId: PropTypes.number.isRequired,
};

const WithData = withDataResolver(resolvers)(MassActionContentSelector);

function Handler(props) {
  const [contents, setContents] = useState(null);
  return <WithData {...props} {...{ contents, setContents }} />;
}

const mapStateToProps = state => ({
  massActionRequestModelId: state.massAction.requestModel.id,
  massActionId: state.massAction.currentMassActionId,
  currentName: state.massAction.currentName,
});

const actionCreators = {
  doGetApiCaller: getApiCaller,
};

export default connect(mapStateToProps, actionCreators)(Handler);
